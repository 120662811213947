import React from 'react';
import ReactEcharts from 'echarts-for-react';

const nameOptions = {
  nameLocation: 'end',
  nameTextStyle: { padding: 16 },
};

export const MultiLineChart = ({
  legend,
  recommendations,
  xAxisLabel = 'x axis label',
  xAxisData = [],
  yAxisLeft = 'y axis left label',
  yAxisRight = 'y axis right label',
  yAxisDataLeft = [],
  yAxisDataRight = [],
  leftLineStyle = 'solid',
  rightLineStyle = 'solid',
  yAxisRightMax = 100,
  yAxisLeftMax,
}) => {
  const colors = ['#5D54E1', '#22C7E3'];

  const option = {
    legend: {},
    xAxis: {
      ...nameOptions,
      nameLocation: 'center',
      name: xAxisLabel,
      data: xAxisData,
      boundaryGap: false,
    },
    yAxis: [
      {
        ...nameOptions,
        name: yAxisLeft,
        max: yAxisLeftMax,
        minInterval: 1,
        axisTick: {
          alignWithLabel: true,
        },
      },
      {
        ...nameOptions,
        nameTextStyle: { padding: 0 },
        name: yAxisRight,
        max: yAxisRightMax,
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    series: [
      {
        name: yAxisLeft,
        type: 'line',
        data: yAxisDataLeft,
        smooth: true,
        lineStyle: {
          color: colors[0],
          type: leftLineStyle,
        },
        itemStyle: {
          color: colors[0],
        },
        markArea: {
          itemStyle: {
            color: '#EAF9F8',
          },
          emphasis: {
            disabled: true,
          },
          data: recommendations.map(rec => [
            {
              name: 'Recommendation',
              xAxis: rec.stop_start_hour,
            },
            {
              xAxis: rec.stop_end_hour,
            },
          ]),
        },
      },
      {
        name: yAxisRight,
        type: 'line',
        yAxisIndex: 1,
        data: yAxisDataRight,
        smooth: true,
        lineStyle: {
          color: colors[1],
          type: rightLineStyle,
        },
        itemStyle: {
          color: colors[1],
        },
        markArea: {
          itemStyle: {
            color: '#EAF9F8',
          },
          emphasis: {
            disabled: true,
          },
          data: recommendations.map(rec => [
            {
              name: 'Recommendation',
              xAxis: rec.stop_start_hour,
            },
            {
              xAxis: rec.stop_end_hour,
            },
          ]),
        },
      },
    ],
  };

  return <ReactEcharts option={option} className="line-chart" />;
};

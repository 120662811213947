import moment from 'moment';

export const COST_TYPE = [
  { value: 'line_item_unblended_cost', label: 'Unblended' },
  { value: 'line_item_amortized_cost', label: 'Amortized' },
  { value: 'line_item_blended_cost', label: 'Blended' },
];

export const COST_TYPE_LABEL = {
  line_item_unblended_cost: 'Unblended',
  line_item_amortized_cost: 'Amortized',
  line_item_blended_cost: 'Blended',
};

export const COST_TYPE_KEY_PARAMS = {
  UNBLENDED: 'line_item_unblended_cost',
  AMORTIZE: 'line_item_amortized_cost',
  BLENDED: 'line_item_blended_cost',
  line_item_unblended_cost: 'line_item_unblended_cost',
  line_item_amortized_cost: 'line_item_amortized_cost',
  line_item_blended_cost: 'line_item_blended_cost',
};

export const CLOUD_RESOURCE_COST_KEYS = {
  ACCOUNTS: 'line_item_usage_account_id',
  ITEM_TYPE: 'line_item_line_item_type',
  PURCHASE_TYPE: 'nops_purchase_option',
  PRODUCT_SERVICES: 'product_product_name',
  PRODUCT_REGIONS: 'product_region',
  REGION_NAME: 'nops_region_name',
  OPERATIONS: 'line_item_operation',
  USAGE_TYPES: 'line_item_usage_type',
  RESOURCES_ID: 'line_item_resource_id',
  COMPUTE_TYPES: 'nops_compute_types',
  RESOURCES_NAME: 'resource_name',
  NON_RESOURCES: 'line_item_line_item_description',
  TAGS: 'tag_keys',
};

export const CLOUD_RESOURCE_COST_TYPE = {
  [CLOUD_RESOURCE_COST_KEYS.ACCOUNTS]: 'Accounts',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_REGIONS]: 'Regions',
  [CLOUD_RESOURCE_COST_KEYS.PURCHASE_TYPE]: 'Purchase Type',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES]: 'Product Services',
  [CLOUD_RESOURCE_COST_KEYS.RESOURCES_ID]: 'Resource ID',
  [CLOUD_RESOURCE_COST_KEYS.COMPUTE_TYPES]: 'Compute Types',
  [CLOUD_RESOURCE_COST_KEYS.RESOURCES_NAME]: 'Resource Names',
  [CLOUD_RESOURCE_COST_KEYS.NON_RESOURCES]: 'Non Resources',
  [CLOUD_RESOURCE_COST_KEYS.USAGE_TYPES]: 'Usage Type',
  [CLOUD_RESOURCE_COST_KEYS.OPERATIONS]: 'Operation',
  [CLOUD_RESOURCE_COST_KEYS.TAGS]: 'Tags',
  [CLOUD_RESOURCE_COST_KEYS.ITEM_TYPE]: 'Line Item Type',
  [CLOUD_RESOURCE_COST_KEYS.REGION_NAME]: 'Region Name',
};

export const REVERSE_CLOUD_RESOURCE_COST_TYPE = {
  Accounts: CLOUD_RESOURCE_COST_KEYS.ACCOUNTS,
  Regions: CLOUD_RESOURCE_COST_KEYS.PRODUCT_REGIONS,
  'Product Services': CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES,
  'Resource ID': CLOUD_RESOURCE_COST_KEYS.RESOURCES_ID,
  'Resource Names': CLOUD_RESOURCE_COST_KEYS.RESOURCES_NAME,
  'Non Resources': CLOUD_RESOURCE_COST_KEYS.NON_RESOURCES,
  'Usage Type': CLOUD_RESOURCE_COST_KEYS.USAGE_TYPES,
  Operation: CLOUD_RESOURCE_COST_KEYS.OPERATIONS,
  Tags: CLOUD_RESOURCE_COST_KEYS.TAGS,
  'Line Item Type': CLOUD_RESOURCE_COST_KEYS.ITEM_TYPE,
  'Compute Types': CLOUD_RESOURCE_COST_KEYS.COMPUTE_TYPES,
  'Purchase Type': CLOUD_RESOURCE_COST_KEYS.PURCHASE_TYPE,
  'Region Name': CLOUD_RESOURCE_COST_KEYS.REGION_NAME,
};

export const CLOUD_RESOURCE_FILTER_DIMENSION = {
  [CLOUD_RESOURCE_COST_KEYS.ACCOUNTS]: 'account_id',
  [CLOUD_RESOURCE_COST_KEYS.ITEM_TYPE]: 'item_type',
  [CLOUD_RESOURCE_COST_KEYS.PURCHASE_TYPE]: 'nops_purchase_option',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES]: 'product',
  [CLOUD_RESOURCE_COST_KEYS.PRODUCT_REGIONS]: 'region',
  [CLOUD_RESOURCE_COST_KEYS.REGION_NAME]: 'nops_region_name',
  [CLOUD_RESOURCE_COST_KEYS.OPERATIONS]: 'operation',
  [CLOUD_RESOURCE_COST_KEYS.USAGE_TYPES]: 'usagetype',
  [CLOUD_RESOURCE_COST_KEYS.TAGS]: 'tag_keys',
  [CLOUD_RESOURCE_COST_KEYS.RESOURCES_ID]: 'resource_id',
  [CLOUD_RESOURCE_COST_KEYS.COMPUTE_TYPES]: 'nops_compute_types',
  [CLOUD_RESOURCE_COST_KEYS.RESOURCES_NAME]: 'resource_name',
  [CLOUD_RESOURCE_COST_KEYS.NON_RESOURCES]: 'description',
};

export const TABS = [
  {
    id: CLOUD_RESOURCE_COST_KEYS.ACCOUNTS,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.ACCOUNTS],
    isNew: false,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.ITEM_TYPE,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.ITEM_TYPE],
    isNew: false,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.PURCHASE_TYPE,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.PURCHASE_TYPE],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES],
    isNew: false,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.PRODUCT_REGIONS,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.PRODUCT_REGIONS],
    isNew: false,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.REGION_NAME,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.REGION_NAME],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.OPERATIONS,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.OPERATIONS],
    isNew: false,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.USAGE_TYPES,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.USAGE_TYPES],
    isNew: false,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.TAGS,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.TAGS],
    isNew: false,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.RESOURCES_ID,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.RESOURCES_ID],
    isNew: false,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.COMPUTE_TYPES,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.COMPUTE_TYPES],
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.RESOURCES_NAME,
    name: CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.RESOURCES_NAME],
    isNew: false,
  },
  {
    id: CLOUD_RESOURCE_COST_KEYS.NON_RESOURCES,
    name: 'Non Resources',
    isNew: false,
  },
];

export const GRANULARITY = {
  hour: 'Hourly',
  day: 'Daily',
  week: 'Weekly',
  month: 'Monthly',
  quarter: 'Quarterly',
};

export const GRANULARITY_TYPE = ({ date_start = null, date_end = null }) => {
  if (!date_start || !date_end) {
    return {
      hour: 'Hourly',
      day: 'Daily',
      week: 'Weekly',
      month: 'Monthly',
      quarter: 'Quarterly',
    };
  }

  const days_diff = moment(date_end, 'YYYY-MM-DD').diff(moment(date_start, 'YYYY-MM-DD'), 'days');

  if (days_diff < 7) {
    return {
      hour: 'Hourly',
      day: 'Daily',
    };
  } else if (days_diff < 14) {
    return {
      hour: 'Hourly',
      day: 'Daily',
      week: 'Weekly',
    };
  } else if (days_diff < 90) {
    return {
      hour: 'Hourly',
      day: 'Daily',
      week: 'Weekly',
      month: 'Monthly',
    };
  } else {
    return {
      day: 'Daily',
      week: 'Weekly',
      month: 'Monthly',
      quarter: 'Quarterly',
    };
  }
};

export const REPORT_DIMENSION = {
  ACCOUNT: CLOUD_RESOURCE_COST_KEYS.ACCOUNTS,
  REGION: CLOUD_RESOURCE_COST_KEYS.PRODUCT_REGIONS,
  SERVICE: CLOUD_RESOURCE_COST_KEYS.PRODUCT_SERVICES,
  RESOURCE: CLOUD_RESOURCE_COST_KEYS.RESOURCES_ID,
  DESCRIPTION: CLOUD_RESOURCE_COST_KEYS.NON_RESOURCES,
  USAGETYPE: CLOUD_RESOURCE_COST_KEYS.USAGE_TYPES,
  OPERATION: CLOUD_RESOURCE_COST_KEYS.OPERATIONS,
  TAG: CLOUD_RESOURCE_COST_KEYS.TAGS,
  ITEM_TYPE: CLOUD_RESOURCE_COST_KEYS.ITEM_TYPE,
};

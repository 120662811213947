import React from 'react';
import { Charts } from './Charts';
import { Table } from './Table';

export const RightPageContent = ({ ...props }) => {
  return (
    <>
      <Charts {...props} />
      <Table {...props} />
    </>
  );
};

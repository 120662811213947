import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { DownArrowIcon } from 'assets/images/common';
import { SmallCloseIcon } from 'assets/images/modal';
import { Anchor } from 'components/Custom';
import { useSelector } from 'react-redux';
import { isTagDataEmpty } from './helper';
import { makeSelectSelectedTag, makeSelectPropsFilter } from './selectors';

export const getSelectedRender = (
  tagData = [],
  isView = false,
  selectedTag,
  onTypeChange,
  removeValue,
  removeKeyValue,
) => {
  const list = [];
  tagData.forEach(tags => {
    const con = tags?.value?.[0] === `No Tagkey:${tags.key}` && tags?.type === 'Include';
    list.push(
      <div key={`${tags.type}-${tags.key}`} className="keyGroupWrapper">
        <div className="np-input-group">
          <div className="np-select-group">
            <select
              id={tags.type}
              className="np-input"
              value={tags.type}
              onChange={onTypeChange(tags.key, isView, tags.cloudPlatform)}
            >
              <option value="Include">Include</option>
              <option value="Exclude">Exclude</option>
            </select>

            <div className="np-caret">
              <DownArrowIcon />
            </div>
          </div>
        </div>

        <ul className="tags">
          <li>
            <Anchor
              id={tags.key}
              title={tags.key}
              onClick={tags.key !== selectedTag ? removeKeyValue(tags.key, isView) : () => {}}
            >
              {tags.key.length > 15 ? tags.key.slice(0, 15) + '...' : tags.key}
              {tags.key !== selectedTag ? (
                <i className="np-svgIcon">
                  <SmallCloseIcon />
                </i>
              ) : null}
            </Anchor>
          </li>
        </ul>

        {con ? null : (
          <ul className="keys">
            {tags.value.map((tag, index) => (
              <li key={index}>
                <Anchor id={tags.value} title={tag} onClick={removeValue(tags.key, tag, isView)}>
                  {tag.length > 15 ? tag.slice(0, 15) + '...' : tag}
                  <i className="np-svgIcon">
                    <SmallCloseIcon />
                  </i>
                </Anchor>
              </li>
            ))}
          </ul>
        )}
      </div>,
    );
  });

  return list;
};

export const DefaultSelectedFilter = ({ tagData, onTypeChange, removeValue, removeKeyValue }) => {
  const selectedTag = useSelector(makeSelectSelectedTag);
  const propsFilter = useSelector(makeSelectPropsFilter);

  if (isTagDataEmpty(tagData) || isEmpty(propsFilter)) {
    return null;
  }

  return (
    <div className="selectedTags">
      <div className="np-row">
        <div className="npxs-12">
          <div className="selectedTagsValues">
            {getSelectedRender(propsFilter, true, selectedTag, onTypeChange, removeValue, removeKeyValue)}
          </div>
        </div>
      </div>
    </div>
  );
};

export const RenderSelectedTag = ({ data = [], onTypeChange, removeValue, removeKeyValue }) => {
  const selectedTag = useSelector(makeSelectSelectedTag);

  return (
    <div className="selectedTags">
      {!data.length ? null : (
        <div className="selectedTags">
          <div className="np-row">
            <div className="npxs-12">
              <div className="selectedTagsValues">
                {getSelectedRender(data, false, selectedTag, onTypeChange, removeValue, removeKeyValue)}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

import React from 'react';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import Tippy from '@tippyjs/react';
import { FilterExpandIcon, FilterCollapseIcon, SearchIcon } from 'assets/images';
import { Anchor } from 'components/Custom';
import Loader from '../LoadingIndicator/Loader';

class RadioFilter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: [],
      showData: [...props.data],
      search: [],
    };
  }

  componentDidMount() {
    if (this.props.isOpen) {
      const temp = cloneDeep(this.state.expanded);
      temp.push(this.props.type);
      this.setState({ expanded: [...temp] });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!isEqual(this.props.data, prevProps.data)) {
      this.setState({
        showData: [...this.props.data],
      });
    }

    if (this.props.isClear && prevProps.isClear !== this.props.isClear) {
      this.setState({
        showData: [...this.props.data],
      });
    }
  }

  onSearchToggle = key => () => {
    const { search, expanded } = this.state;
    const tempSearch = [...search];
    const tempExpanded = [...expanded];
    const findIndex = search.findIndex(keys => keys === key);

    if (findIndex >= 0) {
      tempSearch.splice(findIndex, 1);
    } else {
      tempSearch.push(key);

      const expandIndex = expanded.findIndex(keys => keys === key);

      if (expandIndex === -1) {
        tempExpanded.push(key);
      }
    }

    this.setState({ search: tempSearch, expanded: tempExpanded });
  };

  handleFilterSearch = e => {
    const { data } = this.props;

    if (!e.target.value) {
      this.setState({
        showData: data,
      });
    } else {
      const showData = (data || []).filter(val =>
        (val?.value || '').toLowerCase().includes(e.target.value.toLowerCase()),
      );
      this.setState({ showData });
    }
  };

  onExtendToggle = key => () => {
    const { expanded } = this.state;
    const temp = [...expanded];
    const findIndex = expanded.findIndex(keys => keys === key);

    if (findIndex >= 0) {
      temp.splice(findIndex, 1);
    } else {
      temp.push(key);
    }

    this.setState({ expanded: temp });
  };

  onChange = row => event => {
    const row_detail = { ...row };
    row_detail.checked = event.target.checked;
    this.props.onChange({
      type: this.props.type,
      data: row_detail,
    });
  };

  render() {
    const { expanded, showData, search, isSearch = true } = this.state;

    const {
      title,
      type,
      checkedData,
      placeHolder,
      loading = false,
      isSensitiveInfo = false,
      message = '',
    } = this.props;
    const condition = expanded.includes(type);
    const isDemoMode = sessionStorage.getItem('demo-mode') === 'on';

    return (
      <div className={`np-filtersBlock ${loading ? 'np-nops-loading' : ''}`}>
        <Loader />
        <div className="np-filtersHead">
          {title}

          <span>
            <Tippy content={`${condition ? 'Collapse' : 'Expand'}`} placement={'left'}>
              <Anchor id={'collapse-' + type} onClick={this.onExtendToggle(type)}>
                <i className="np-svgIcon">{condition ? <FilterCollapseIcon /> : <FilterExpandIcon />}</i>
              </Anchor>
            </Tippy>
            {isSearch ? (
              <Tippy content={'Show search'} placement={'left'}>
                <Anchor id={'searchIcon-' + type} onClick={this.onSearchToggle(type)}>
                  <SearchIcon />
                  &nbsp;
                </Anchor>
              </Tippy>
            ) : null}
          </span>
        </div>

        {isSearch ? (
          <div className={`np-filter search ${search.includes(type) && 'show'}`}>
            <div className="np-input-icon">
              <input
                id={'searchTitle-' + type}
                type="text"
                placeholder={`Search ${placeHolder ? placeHolder : title}...`}
                className="np-input search-input-filter"
                autoComplete="off"
                onChange={this.handleFilterSearch}
              />
              <SearchIcon />
            </div>
          </div>
        ) : null}
        {message && <p className="np-filter-desc">{message}</p>}

        <div className="np-filterList">
          {condition &&
            (showData || []).map((row, index) => (
              <div id={row.value} key={index} className="np-filter">
                <div className="np-radio">
                  <input
                    id={`${type}-np-radio-${index}`}
                    type="radio"
                    value={row.key}
                    checked={Boolean(checkedData && checkedData.toString() === row.key.toString())}
                    disabled={row.disabled}
                    onChange={this.onChange(row)}
                  />
                  <label
                    className={`np-radio-label ${row.disabled ? 'cursor-no-drop' : ''}`}
                    htmlFor={`${type}-np-radio-${index}`}
                  />
                </div>
                <label
                  className={`np-filterLabel ${isDemoMode && isSensitiveInfo ? 'demo-mode-black' : ''}`}
                  htmlFor={`${type}-np-radio-${index}`}
                >
                  {row.value} {row?.extraValue ? row.extraValue : null}
                </label>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default RadioFilter;

import produce from 'immer';
import { LOAD_USER_DATA_REQUEST, LOAD_USER_DATA_SUCCESS, LOAD_USER_DATA_ERROR } from './constants';

export const initialState = {
  loading: false,
  userData: [],
};

const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_USER_DATA_REQUEST:
        draft.loading = true;

        break;
      case LOAD_USER_DATA_SUCCESS:
        draft.loading = false;
        draft.userData = action.response || [];

        break;
      case LOAD_USER_DATA_ERROR:
        draft.loading = false;

        break;
      default:
        break;
    }
  });

export default appReducer;

export const default_tag = {
  tagKey: {},
  tagValue: {},
  errorKey: '',
  errorValue: '',
  selectedTagsAllValue: [],
  loading: false,
};

export const filter_keys = {
  PROJECTS: 'project.id',
  REGIONS: 'region.keyword',
  PRODUCTS: 'product.keyword',
  TAG_KEY: 'nested:tags_kv:tags_kv.key',
  TAG_VALUE: 'nested:tags_kv:tags_kv.value',
};

export const radioTypes = [
  { key: 'predefined', label: 'Predefined:' },
  { key: 'custom', label: 'Custom:' },
];

export const aggregations = ['arp_regions', 'arp_products', 'vpc_names', 'arp_resource_tag_key_list'];

export const CHARGEBACK_STATUS_VALUES = ['paid', 'unpaid', 'invoice_sent'];

export const CHARGEBACK_TYPE_VALUES = [
  { key: 'business_unit', label: 'Business unit' },
  { key: 'team', label: 'Team' },
  { key: 'billing', label: 'Billing' },
];

export const CHARGEBACK_STATUS = {
  paid: 'Paid',
  unpaid: 'Unpaid',
  invoice_sent: 'Invoice Sent',
};

export const basePayload = {
  q: '*',
  scope: 'client',
  aggregations: aggregations.join(':'),
  show_active: null,
};

export const payload = (id, configCatAzure, cloud_type) => {
  const queryParams = {
    parameters: {
      q: '*',
      scope: 'client',
      aggregations: 'arp_resource_tag_value_list',
      show_active: null,
      filters: {
        must: [
          {
            'nested:tags_kv:tags_kv.key': [id],
          },
        ],
        _multiple_conditions: [true],
      },
    },
  };

  if (configCatAzure) {
    queryParams.parameters.cloud_type = 'all';

    if (cloud_type) {
      queryParams.parameters.filters.must[0].cloud_type = cloud_type;
    }
  }

  return queryParams;
};

export const CLOUD_PLATFORMS = [
  { key: 'aws', label: 'AWS' },
  { key: 'azure', label: 'Azure' },
];

import React, { useRef } from 'react';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import moment from 'moment';
import { colors } from '../Color';
import { allowedFormat } from '../constant';

const BarChart = ({
  dataSet,
  columns,
  noLegend = false,
  noTooltip = false,
  unit,
  format = null,
  handleClick,
  isFormattedLabel = false,
  noLabelFormat = false,
}) => {
  const chartRef = useRef();

  const onClick = (event = []) => {
    const eventDetail = getElementAtEvent(chartRef.current, event);

    if (!eventDetail.length || typeof handleClick !== 'function') {
      return null;
    }

    handleClick(eventDetail);
  };

  const setDateFormat = () => {
    let dateFormat = 'MMM-D-YYYY';

    if (columns?.length) {
      const lastDate = moment(columns[columns.length - 1], 'YYYY-MM-DD');
      const startDate = moment(columns[0], 'YYYY-MM-DD');
      const diff = lastDate.diff(startDate, 'M');

      if ([2, 5].includes(diff)) {
        dateFormat = 'YYYY-MM';
      }
    }

    return dateFormat;
  };

  const dateFormat = !format ? setDateFormat() : format;

  const data = {
    labels: !isFormattedLabel
      ? (columns || []).map(row => moment(row, allowedFormat, true).format(dateFormat))
      : columns,
  };

  data.datasets = (dataSet || []).map((row, i) => ({
    label:
      !noLabelFormat && !noLegend && row.label.length > 15
        ? `${i + 1 + '. ' + row.label.slice(0, 15)}...`
        : i + 1 + '. ' + row.label,
    fill: false,
    // type: 'line',
    lineTension: 0.1,
    backgroundColor: colors[i % colors.length].backgroundColor,
    borderColor: colors[i % colors.length].borderColor,
    hoverBackgroundColor: colors[i % colors.length].hoverBackgroundColor,
    hoverBorderColor: colors[i % colors.length].hoverBorderColor,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: '#22C7E3',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: '#22C7E3',
    pointHoverBorderColor: '#22C7E3',
    pointHoverBorderWidth: 2,
    pointRadius: 2,
    borderWidth: 1.7,
    pointHitRadius: 40,
    stack: '1',
    data: row.data,
    ...row.colors,
  }));

  return (
    <div data-testid="bar-chart" className="chart_js" style={{ height: '350px' }}>
      <Bar
        type="bar"
        ref={chartRef}
        data={data}
        options={{
          plugins: {
            legend: {
              display: !noLegend,
              fullSize: true,
              position: 'bottom',
              labels: {
                usePointStyle: false,
                boxWidth: 12,
                boxHeight: 12,
                fontSize: 12,
                padding: 10,
              },
            },
            tooltips: {
              enabled: !noTooltip,
              mode: 'label',
              position: 'average',
              intersect: false,
            },
          },
          legendCallback: chart => {
            const text = [];
            text.push('<ul class="' + chart?.id + '-legend">');

            for (let i = 0; i < chart?.data?.datasets?.length; i++) {
              text.push(
                '<li><span style="background-color:' + chart?.data?.datasets?.[i]?.backgroundColor + '"></span>',
              );

              if (chart?.data?.datasets?.[i]?.label) {
                if (!noLabelFormat && chart?.data?.datasets?.[i]?.label?.length > 15) {
                  text.push(`${chart?.data?.datasets?.[i]?.label}`);
                } else {
                  text.push(chart?.data?.datasets?.[i]?.label);
                }
              }

              text.push('</li>');
            }

            text.push('</ul>');

            return text.join('');
          },
          scales: {
            y: {
              axis: 'y',
              stacked: true,
              type: 'linear',
              ticks: {
                maxTicksLimit: 6,
                callback: (value, index, values) => {
                  const tmpUnit = unit === undefined ? '$' : unit;

                  return [
                    tmpUnit,
                    value.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    }),
                  ].join('');
                },
              },
              grid: {
                color: '#e2e7ea',
                display: true,
                drawBorder: true,
              },
            },
            x: {
              stacked: true,
              grid: {
                color: '#e2e7ea',
                display: true,
                drawBorder: true,
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        }}
        onClick={onClick}
      />
    </div>
  );
};

export default BarChart;

import React from 'react';
import isEmpty from 'lodash/isEmpty';
import CreatableSelect from 'react-select/creatable';
import { checkIsEqual } from 'utils/helper';

export default class CreatableSelectDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValues: {},
    };
  }

  componentDidMount() {
    const { defaultData = {} } = this.props;

    if (!isEmpty(defaultData)) {
      this.setState({ selectedValues: { ...defaultData } });
    }
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    const { defaultData = {} } = this.props;

    if (!isEmpty(defaultData) && checkIsEqual(false, defaultData, prevProps.defaultData)) {
      this.setState({ selectedValues: { ...defaultData } });
    }

    if (isEmpty(defaultData) && !isEmpty(prevProps.defaultData)) {
      this.setState({ selectedValues: {} });
    }
  }

  handleOnChange = (selected = {}) => {
    this.setState({ selectedValues: selected });
    this.props.onChange(selected, this.props.type, this.props.extraProps);
  };

  render() {
    const { data, placeHolder, isLoading = false, isDisabled = false, type = '' } = this.props;
    const { selectedValues } = this.state;

    return (
      <CreatableSelect
        id={type}
        value={!isEmpty(selectedValues) ? selectedValues : null}
        options={data}
        className="basic-single"
        classNamePrefix="select"
        onChange={this.handleOnChange}
        isClearable
        placeholder={placeHolder}
        isLoading={isLoading}
        isDisabled={isDisabled}
        menuPlacement="auto"
      />
    );
  }
}

import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import cx from 'classnames';
import { RuleIcon } from 'assets/images/nOpsRule';
import NoData from 'assets/images/check-illustration.svg';
import { Anchor } from 'components/Custom';
import HorizontalChart from 'components/Charts/HorizontalChart';
import CustomHorizontalChart from 'components/Charts/HorizontalChartCustomLegend';
import { IsJsonValid, IsURIValid } from 'utils/helper';
import { getParamFromQueryString } from 'utils/queryString';
import { RULES_PILLAR } from 'containers/nOpsRules/utils';
import { ReliabilityChart } from 'containers/nOpsRules/RelaibilityChart';

const FILTERS = [
  { key: 'per_project', value: 'Accounts', filterKey: 'accountState' },
  { key: 'per_region', value: 'Regions', filterKey: 'regionState' },
  { key: 'per_vpc', value: 'VPCs', filterKey: 'vpcState' },
];

export const Charts = ({ ...props }) => {
  const [activeFilter, setActiveFilter] = useState(FILTERS[0]);
  const { chartData, updateFilter, configCatAzure } = props;
  const { per_project = [], per_region = [], per_vpc = [] } = chartData || {};

  const handleClick = (datasetLabel, cloudPlatform) => {
    const activeTab = FILTERS.find(tab => tab.value === activeFilter.value);
    const searchData = getParamFromQueryString(props.search, 'data') || '';
    let payload;
    const newData = IsJsonValid(IsURIValid(searchData));

    if (isEmpty(newData)) {
      payload = {};
    } else {
      payload = newData.payload;
    }

    const existData = payload?.[activeTab.filterKey] || [];
    const label = (datasetLabel || '').split(' (')?.[0] || '';

    const record = chartData[activeTab.key].find(
      cData => cData.name === label && (!configCatAzure || (configCatAzure && cData.cloudPlatform === cloudPlatform)),
    );

    if (!record?.key) {
      return;
    }

    let state = {};

    if (!(existData || []).includes(record?.key || '')) {
      if (configCatAzure && ['regionState'].includes(activeTab.filterKey)) {
        state = {
          ...payload,
          [activeTab.filterKey]: [...existData, record.key],
          [`${activeTab.filterKey}_values`]: [
            ...existData,
            {
              id: record.name,
              value: record.name,
              cloudPlatform,
            },
          ],
        };
      } else {
        state = {
          ...payload,
          [activeTab.filterKey]: [...existData, record.key],
          [`${activeTab.filterKey}_values`]: [...existData, record.name],
        };
      }

      updateFilter(state);
    }
  };

  const getDataByFilter = () => {
    let data = [];

    if (activeFilter.value === 'Accounts') {
      data = per_project;
    } else if (activeFilter.value === 'Regions') {
      data = per_region;
    } else {
      data = per_vpc;
    }

    return data.map(item => ({
      label: `${item.name} (${item.count})`,
      data: [item.count],
      cloudPlatform: item.cloudPlatform,
    }));
  };

  const noData = !per_project?.length && !per_region?.length && !per_vpc?.length;

  if (noData) {
    return null;
  }

  const data = getDataByFilter();

  return (
    <div className="nplg-12 npmd-12 npsm-12 npxs-12">
      <div className="np-card np-pillarSummary">
        <div className="title">
          <div className="np-cardIcon red">
            <RuleIcon />
          </div>
          <b>Top 10 Violations Distribution</b>
        </div>

        <ul className="np-tableTabs tabsHead">
          {FILTERS.map(filter => (
            <li key={filter.key}>
              <Anchor
                className={cx('np-button color-grey light', {
                  active: activeFilter.value === filter.value,
                })}
                onClick={() => setActiveFilter(filter)}
              >
                Show by {filter.value}
              </Anchor>
            </li>
          ))}
        </ul>

        {!chartData[activeFilter.key]?.length ? (
          <div className="no-dataMessage">
            <img src={NoData} alt="no-data" />
            <p>You don’t have any violations in any {activeFilter.value}, you are well architected!!</p>
          </div>
        ) : (
          <div className="graph_container">
            {props.configCatAzure ? (
              <CustomHorizontalChart
                dataSet={data.slice(0, 10)}
                labels="Violations"
                handleClick={handleClick}
                showXAxes={false}
                showYAxes={false}
              />
            ) : (
              <HorizontalChart
                dataSet={data.slice(0, 10)}
                labels="Violations"
                handleClick={handleClick}
                showXAxes={false}
                showYAxes={false}
              />
            )}
          </div>
        )}
      </div>
      {props.pillarActive === RULES_PILLAR.RELIABILITY &&
      (props.reliabilityChartData?.ec2_availability_zones?.length || props.reliabilityChartData?.ec2_types?.length) ? (
        <ReliabilityChart data={props.reliabilityChartData} configCatAzure={props.configCatAzure} />
      ) : null}
    </div>
  );
};

import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import {
  LOAD_INITIAL_REQUEST,
  LOGIN_REQUEST,
  LOAD_AUTO_SUGGESTION_DATA,
  CLEAR_SUGGESTION_DATA,
  SET_OVERRIDE_TIMESTAMP,
  UPDATE_POLICY_PER_PROJECT_REQUEST,
  UPDATE_POLICY_ALL_PROJECT_REQUEST,
  GET_JIRA_CREDENTIALS_REQUEST,
  GET_JIRA_ISSUES_REQUEST,
  LOAD_JIRA_OAUTH2_DATA_REQUEST,
} from './constants';
import {
  loadInitialSuccess,
  loadFirstBunchSuccess,
  loadSecondBunchSuccess,
  loadThirdBunchSuccess,
  loadInitialError,
  loginError,
  loadAutoSuggestionDataSuccess,
  loadAutoSuggestionDataError,
  clearSuggestionData,
  setTimeStampSuccess,
  showToastAction,
  loadPolicySuccess,
  loadPolicyError,
  updatePolicyForAllProjectError,
  updatePolicyPerProjectError,
  getJiraCredSuccess,
  getJiraCredError,
  getJiraIssuesSuccess,
  getJiraIssuesError,
  loadJIRAOAuth2Success,
  loadJIRAOAuth2Error,
} from './actions';
import {
  loadStatus,
  loadBillingInfo,
  loadProfile,
  login,
  loadAutoSuggestion,
  getClientDetail,
  getNewPolicies,
  updatePolicyForAllProject,
  updatePolicyForProject,
  getUserSegment,
} from 'api/global';
import { loadAllProjectsList } from 'api/global';
import { getSummaryData } from 'api/cloud-resource-cost';
import { getJiraCredentialsData, getJiraBoard, jiraOAuth2Integration } from 'api/jira';
import { getErrorMessage } from 'utils/helper';
import { checkNopsEmail } from 'utils/common';
import { logMyErrors } from 'utils/request';
import { FILTER_DATE_FORMAT, LAST_30_DAYS, YESTERDAY } from 'utils/filter';
import {
  configureSentry,
  configurePendo,
  initializePendoAndHotjar,
  getMtdDataFromCookies,
  setMtdDataInCookies,
  updatePendoConfigWithMtdData,
  updatePendoConfigWithSpendData,
  updatePendoConfigWithNoSpendData,
} from './helpers';

export const api_condition_success = (status = 0) => status >= 200 && status < 400;
export const api_condition_error = (status = 0) => status >= 400;

export function* globalShowNotification(res) {
  try {
    yield put(
      showToastAction({
        type: 'error',
        show: true,
        message: getErrorMessage(res),
      }),
    );
  } catch (e) {
    logMyErrors('Global', e);
  }
}

export function* loadInitialData() {
  try {
    yield call(getInitialData);
  } catch (e) {
    logMyErrors('Initial load', e);
  }
}

function* handleProfileResponse(profileRes) {
  if (api_condition_success(profileRes.status)) {
    const {
      first_name = '(First name)',
      last_name = '(Last name)',
      email,
      has_project,
      is_admin,
      is_partner_user,
    } = profileRes.data || {};
    const full_name = `${first_name} ${last_name}`;
    const profile = { ...(profileRes.data || {}), full_name };

    window.user = { has_aws_account: has_project, is_partner_user };

    configureSentry(profile);

    const firstBunchResponse = {
      isAdmin: is_admin,
      profile,
      isNopsAccount: checkNopsEmail(email),
    };
    yield put(loadFirstBunchSuccess(firstBunchResponse));

    return profile;
  } else {
    if (api_condition_error(profileRes.status)) {
      yield call(globalShowNotification, profileRes);
    }

    throw new Error('Profile API call failed');
  }
}

function* handleClientDetailResponse(clientDetailRes) {
  if (api_condition_success(clientDetailRes.status)) {
    const clientDetail = clientDetailRes.data || {};
    const { id, partner, sharesave_allowed, azure_billing_currency } = clientDetail || {};
    const sharesave_allowed_value = !!sharesave_allowed || partner === 485;

    const secondBunchResponse = {
      client: id,
      currentClient: clientDetail,
      sharesave_allowed: sharesave_allowed_value,
      azure_billing_currency: azure_billing_currency || 'USD',
      isMultipleCurrency: azure_billing_currency && azure_billing_currency !== 'USD',
    };
    const sharesaveAllowed = localStorage.getItem('sharesaveAllowed') || '';

    if ((sharesaveAllowed.toLowerCase() === 'true') !== secondBunchResponse.sharesave_allowed) {
      localStorage.setItem('sharesaveAllowed', secondBunchResponse.sharesave_allowed);
    }

    yield put(loadSecondBunchSuccess(secondBunchResponse));

    return clientDetail;
  } else {
    if (api_condition_error(clientDetailRes.status)) {
      yield call(globalShowNotification, clientDetailRes);
    }

    throw new Error('Status API call failed');
  }
}

function* handleAllResponse(statusRes, projectsListRes) {
  if (api_condition_success(statusRes.status) && api_condition_success(projectsListRes.status)) {
    const { project = {}, projects = [], share_save_eligibility = {} } = statusRes.data || {};
    const projectsList = projectsListRes?.data || [];

    const thirdBunchResponse = {
      project,
      projects: projectsList,
      projectStatus: keyBy(projects, 'id'),
      share_save_eligibility,
    };

    yield put(loadThirdBunchSuccess(thirdBunchResponse));

    if (projectsList.length) {
      yield call(getAllPolicy);
    }
  } else {
    if (api_condition_error(statusRes.status)) {
      yield call(globalShowNotification, statusRes);
    }

    if (api_condition_error(projectsListRes.status)) {
      yield call(globalShowNotification, projectsListRes);
    }

    throw new Error('Status API call failed');
  }
}

function* handleBillingResponse(billingRes) {
  if (api_condition_success(billingRes.status)) {
    const billing = billingRes.data || {};
    yield put(loadInitialSuccess({ payment: billing }));

    return billing;
  } else {
    if (api_condition_error(billingRes.status)) {
      yield call(globalShowNotification, billingRes);
    }

    throw new Error('Billing API call failed');
  }
}

function* fetchSpendData() {
  return yield call(getSummaryData, {
    date_start: LAST_30_DAYS.format(FILTER_DATE_FORMAT),
    date_end: YESTERDAY.format(FILTER_DATE_FORMAT),
    cost_type: 'line_item_unblended_cost',
  });
}

function* handleSpendData(pendoConfig) {
  const mtdData = getMtdDataFromCookies();

  if (!isEmpty(mtdData)) {
    updatePendoConfigWithMtdData(pendoConfig, mtdData);
  } else {
    const spendRes = yield call(fetchSpendData);

    if (api_condition_success(spendRes.status)) {
      const { mtd_spend = 0, mtd_delta_spend = 0 } = spendRes?.data || {};

      if (!isEmpty(spendRes?.data)) {
        setMtdDataInCookies(mtd_spend, mtd_delta_spend);
        updatePendoConfigWithSpendData(pendoConfig, mtd_spend, mtd_delta_spend);
      } else {
        updatePendoConfigWithNoSpendData(pendoConfig);
      }
    } else {
      updatePendoConfigWithNoSpendData(pendoConfig);
    }
  }
}

export function* getInitialData() {
  try {
    const profileRes = yield call(loadProfile);
    const profile = yield call(handleProfileResponse, profileRes);
    const clientDetailRes = yield call(getClientDetail, profile.client_id);
    const clientDetail = yield call(handleClientDetailResponse, clientDetailRes);
    const [statusRes, projectsListRes] = yield all([call(loadStatus), call(loadAllProjectsList)]);
    yield call(handleAllResponse, statusRes, projectsListRes);

    const billingRes = yield call(loadBillingInfo);
    const billing = yield call(handleBillingResponse, billingRes);
    const pendoConfig = configurePendo(profile, clientDetail, billing);

    if (profile.has_project) {
      yield call(handleSpendData, pendoConfig);
    }

    initializePendoAndHotjar(pendoConfig);

    const userSegmentRes = yield call(getUserSegment);

    if (api_condition_success(userSegmentRes.status)) {
      window.nopsData = { userSegment: userSegmentRes.data };
    } else {
      if (api_condition_error(userSegmentRes.status)) {
        window.nopsData = { userSegment: {} };
      }
    }
  } catch (err) {
    yield put(loadInitialError());
    yield put(
      showToastAction({
        message: 'Failed to load initial data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* loginSaga(action) {
  const { payload } = action;

  try {
    const res = yield call(login, payload);

    if (res.data.key) {
      yield put(push('/v3/usage-report/dashboard/'));
    }
  } catch (err) {
    yield put(loginError());
  }
}

export function* getSuggestionData(action) {
  const { query } = action;

  try {
    const response = yield call(loadAutoSuggestion, query);

    if (api_condition_success(response.status)) {
      yield put(loadAutoSuggestionDataSuccess(response.data));
    } else if (api_condition_error(response.status)) {
      // yield call(globalShowNotification, response)
      yield put(loadAutoSuggestionDataError());
    }
  } catch (err) {
    yield put(loadAutoSuggestionDataError());
    yield put(
      showToastAction({
        message: 'Failed to load searched data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* clearAutoSuggestionData() {
  return yield call(clearSuggestionData);
}

export function* setTimeStampData(action) {
  return yield put(setTimeStampSuccess(action.payload));
}

export function* getAllPolicy() {
  try {
    const response = yield call(getNewPolicies);

    if (api_condition_success(response.status)) {
      yield put(loadPolicySuccess(response?.data || {}));
    } else if (api_condition_error(response.status)) {
      yield call(globalShowNotification, response);
      yield put(loadPolicyError());
    }
  } catch (err) {
    yield put(loadPolicyError());
  }
}

export function* updatePolicyPerProject(action) {
  try {
    const response = yield call(updatePolicyForProject, action.payload);

    if (api_condition_success(response.status)) {
      yield call(getAllPolicy);
    } else if (api_condition_error(response.status)) {
      yield call(globalShowNotification, response);
      yield put(updatePolicyPerProjectError());
    }
  } catch (err) {
    yield put(updatePolicyPerProjectError());
    yield put(
      showToastAction({
        message: 'Failed to update policy data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* updatePolicyAllProject(action) {
  try {
    const response = yield call(updatePolicyForAllProject, action.payload);

    if (api_condition_success(response.status)) {
      yield call(getAllPolicy);
    } else if (api_condition_error(response.status)) {
      yield call(globalShowNotification, response);
      yield put(updatePolicyForAllProjectError());
    }
  } catch (err) {
    yield put(updatePolicyForAllProjectError());
    yield put(
      showToastAction({
        message: 'Failed to update policy data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* getJiraCred() {
  try {
    const Workload = yield call(getJiraCredentialsData);
    yield put(getJiraCredSuccess(Workload.status));

    if (api_condition_error(Workload.status)) {
      yield put(getJiraCredError(getErrorMessage(Workload)));
    }
  } catch (err) {
    yield put(getJiraCredError(''));
  }
}

export function* getJiraIssues() {
  try {
    const issues = yield call(getJiraBoard);

    if (api_condition_success(issues.status)) {
      yield put(getJiraIssuesSuccess(issues.data));
    } else if (api_condition_error(issues.status)) {
      yield put(getJiraIssuesError());
    }
  } catch (err) {
    yield put(getJiraIssuesError());
  }
}

export function* loadJIRAOAuth2Data(action) {
  try {
    const jiraOAuth2Data = yield call(jiraOAuth2Integration, action.payload);

    if (api_condition_success(jiraOAuth2Data.status)) {
      yield put(loadJIRAOAuth2Success(jiraOAuth2Data.data));
    } else if (api_condition_error(jiraOAuth2Data.status)) {
      yield put(loadJIRAOAuth2Error());
    }
  } catch (err) {
    yield put(loadJIRAOAuth2Error());
    yield put(
      showToastAction({
        message: 'Failed to load Jira OAuth2 data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export default function* appUpdate() {
  yield takeLatest(LOAD_INITIAL_REQUEST, loadInitialData);
  yield takeEvery(LOGIN_REQUEST, loginSaga);
  yield takeEvery(LOAD_AUTO_SUGGESTION_DATA, getSuggestionData);
  yield takeEvery(CLEAR_SUGGESTION_DATA, clearAutoSuggestionData);
  yield takeEvery(SET_OVERRIDE_TIMESTAMP, setTimeStampData);
  yield takeEvery(UPDATE_POLICY_PER_PROJECT_REQUEST, updatePolicyPerProject);
  yield takeEvery(UPDATE_POLICY_ALL_PROJECT_REQUEST, updatePolicyAllProject);
  yield takeEvery(GET_JIRA_CREDENTIALS_REQUEST, getJiraCred);
  yield takeEvery(GET_JIRA_ISSUES_REQUEST, getJiraIssues);
  yield takeLatest(LOAD_JIRA_OAUTH2_DATA_REQUEST, loadJIRAOAuth2Data);
}

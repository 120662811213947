import React, { useState, useEffect } from 'react';
import className from 'classnames';
import { DollarGreenIcon } from 'assets/images/cost';
import { getDetailForSelectedBarInChart } from 'utils/helper';
import { Anchor } from 'components/Custom';
import BarChart from 'components/Charts/BarChart';
import BarChartCustomLegend from 'components/Charts/BarChartCustomLegend';
import ForecastChart from '../ForecastChart';
import { COST_TYPE } from 'utils/constants';
import { getCurrencySymbol } from 'utils/currencySymbol';
import { useLocation } from 'react-router-dom';

const TAB_WITH_FORECAST = [COST_TYPE.CLOUD_ACCOUNTS, COST_TYPE.REGIONS, COST_TYPE.CLOUD_PRODUCTS];

const BaseChart = ({
  pageData,
  columns,
  forecastColumn,
  forecastData,
  configCatAzure,
  isMultipleCurrency = false,
  azure_billing_currency = 'USD',
  isAWSSelected = true,
  type,
  updateFilter,
  data: propsData,
  showForecastChart,
  isShowForecast,
}) => {
  const [isShow, setIsShow] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setIsShow(!TAB_WITH_FORECAST.includes(type));
  }, [type]);

  const handleClick = e => {
    if (!e.length || !columns?.length) {
      return;
    }

    const columns_copy = [...columns];
    const state = getDetailForSelectedBarInChart(e, columns_copy, location);
    updateFilter(state);
  };

  const getOthersData = (newColumns, propsData, dataSet) => {
    if (!propsData?.length) {
      return {};
    }

    let columnSet = {};
    newColumns.forEach(col => {
      const dataSetTotal = dataSet.map(item => item.data[col] || 0).reduce((a, b) => a + b, 0);
      const propsDataSetTotal = propsData.map(item => item.data[col] || 0).reduce((a, b) => a + b, 0);
      columnSet = { ...columnSet, [col]: propsDataSetTotal - dataSetTotal };
    });

    return columnSet;
  };

  const data = propsData.slice(pageData.limit * (pageData.page - 1), pageData.limit * pageData.page);
  const awsData = propsData.filter(item => item.cloudPlatform === 'aws');
  const azureData = propsData.filter(item => item.cloudPlatform === 'azure');

  if (awsData?.length) {
    data.push({
      cloudPlatform: 'aws',
      data: getOthersData(
        [...columns],
        awsData,
        data.filter(item => item.cloudPlatform === 'aws'),
      ),
      id: 'AWS',
      linkIds: {},
      mrc: 0,
      name: 'Other AWS Data',
      productName: '',
      total: 0,
    });
  }

  if (azureData?.length) {
    data.push({
      cloudPlatform: 'azure',
      data: getOthersData(
        [...columns],
        azureData,
        data.filter(item => item.cloudPlatform === 'azure'),
      ),
      id: 'Azure',
      linkIds: {},
      mrc: 0,
      name: 'Other Azure Data',
      productName: '',
      total: 0,
    });
  }

  const dataObject = {};
  data.forEach(row => {
    dataObject[row.id] = row.data;
  });

  const dataSet = data.map(row => {
    const costArray = columns.map(column => (dataObject[row.id][column] || 0).toFixed(2));

    return {
      label: row.name,
      data: costArray,
      cloudPlatform: row.cloudPlatform,
    };
  });

  const foreCastCardClass = className({
    'np-button color-grey light': true,
    active: isShowForecast,
  });

  const historyCardClass = className({
    'np-button color-grey light': true,
    active: !isShowForecast,
  });
  const unit = isMultipleCurrency && !isAWSSelected ? getCurrencySymbol(azure_billing_currency) : '$';

  return (
    <div className="nplg-12 npmd-12 npsm-12 npxs-12">
      <div className="np-card">
        {!isShow && (
          <div className="np-cardActions graphActions">
            <Anchor id={'spendForecast'} className={foreCastCardClass} onClick={showForecastChart(true)}>
              See Spend Forecast
            </Anchor>

            <Anchor id={'spendHistory'} className={historyCardClass} onClick={showForecastChart(false)}>
              See Spend History
            </Anchor>
          </div>
        )}

        <div className="title" id={isShow || !isShowForecast ? 'History' : 'Spend_Forecast'}>
          <div className="np-cardIcon teal">
            <DollarGreenIcon />
          </div>
          {isShow || !isShowForecast ? 'History' : 'Spend Forecast'}
        </div>

        <div className="np-cardGraph">
          {!isShow && isShowForecast ? (
            <>
              <label>Showing Forecast Data</label>
              <ForecastChart columns={forecastColumn} dataSet={forecastData} unit={unit} />
            </>
          ) : configCatAzure ? (
            <BarChartCustomLegend
              columns={columns}
              dataSet={dataSet}
              handleClick={handleClick}
              unit={unit}
              type={type}
            />
          ) : (
            <BarChart columns={columns} dataSet={dataSet} handleClick={handleClick} unit={unit} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BaseChart;

import isEmpty from 'lodash/isEmpty';
import { tag_key, tag_value } from './index';

export const isTagDataEmpty = tagData => {
  return isEmpty(tagData) || (isEmpty(tagData['must']) && isEmpty(tagData['must_not']));
};

export const setInitialData = (tagData = {}, dispatch, setSelectedKeyValue, setPropsFilter) => {
  const key = 'nested:tags_kv:tags_kv.key';
  const value = 'nested:tags_kv:tags_kv.value';
  let data = [];

  if (isTagDataEmpty(tagData)) {
    data = [];
  } else {
    tagData['must'].forEach(tag => {
      if (!tag[key]) {
        return null;
      }

      data.push({
        key: tag[key][0],
        value: tag[value] ? tag[value] : [`No Tagkey:${tag[key]}`],
        type: 'Include',
      });
    });
    tagData['must_not'].forEach(tag => {
      data.push({
        key: tag[key][0],
        value: tag[value] ? tag[value] : [`No Tagkey:${tag[key]}`],
        type: 'Exclude',
      });
    });
  }

  if (data.length) {
    dispatch(setPropsFilter(data));
    dispatch(setSelectedKeyValue(data));
  }
};

export const getTagDataToApplyFilter = (data, selectedKeyValue) => {
  const temp = {
    must: [],
    must_not: [],
  };

  if (data.length) {
    const tagData = selectedKeyValue.filter(tag => !(tag?.value || []).includes(`No Tagkey:${tag.key}`));
    const noTagData = selectedKeyValue.filter(tag => tag?.value?.[0] === `No Tagkey:${tag.key}`);

    if (noTagData.length) {
      noTagData.forEach(noTag => {
        if (noTag.type === 'Include') {
          temp['must'].push({ [tag_key]: [noTag.key] });
        } else {
          temp['must_not'].push({ [tag_key]: [noTag.key] });
        }
      });
    }

    if (tagData.length) {
      tagData.forEach(tag => {
        if (tag.value.includes(`No Tagkey:${tag.key}`)) {
          temp['must_not'].push({ [tag_key]: [tag.key] });
        } else {
          let item = {
            [tag_key]: [tag.key],
          };

          if (tag.value?.length) {
            item = { ...item, [tag_value]: tag.value };
          }

          if (tag.type === 'Include') {
            temp['must'].push(item);
          } else {
            temp['must_not'].push(item);
          }
        }
      });
    }
  }

  return temp;
};

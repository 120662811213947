import isEmpty from 'lodash/isEmpty';
import { default_tag, filter_keys, CHARGEBACK_TYPE_VALUES } from './constants';
import { IsJsonValid } from 'utils/helper';

export const updateChargebackDetails = (selectedChargeback, projects) => {
  if (isEmpty(selectedChargeback)) {
    return;
  }

  let defaultProjects = [],
    defaultRegions = [],
    defaultProducts = [],
    projects_ids = [],
    regions = [],
    products = [];
  const defaultTags = [default_tag];
  const filters = selectedChargeback.filters ? IsJsonValid(selectedChargeback.filters) : {};

  if (filters && filters?.must?.length) {
    filters.must.forEach(item => {
      //projects check
      if (item[filter_keys.PROJECTS]?.length) {
        defaultProjects = projects.reduce((acc, curr) => {
          if (item[filter_keys.PROJECTS].includes(curr.id.toString())) {
            return [...acc, { value: curr.id.toString(), label: curr.name }];
          }

          return acc;
        }, []);
        projects_ids = item[filter_keys.PROJECTS];

        return;
      }

      //regions check
      if (item[filter_keys.REGIONS]?.length) {
        defaultRegions = item[filter_keys.REGIONS].map(region => ({
          value: region,
          label: region,
        }));
        regions = item[filter_keys.REGIONS];

        return;
      }

      //products check
      if (item[filter_keys.PRODUCTS]?.length) {
        defaultProducts = item[filter_keys.PRODUCTS].map(product => ({
          value: product,
          label: product,
        }));
        products = item[filter_keys.PRODUCTS];

        return;
      }

      //tags check
      if (item[filter_keys.TAG_KEY]) {
        defaultTags.push({
          ...default_tag,
          tagKey: {
            value: item[filter_keys.TAG_KEY],
            label: item[filter_keys.TAG_KEY],
          },
          tagValue: {
            value: item[filter_keys.TAG_VALUE],
            label: item[filter_keys.TAG_VALUE],
          },
        });
      }
    });
  }

  const getTypeValue = type => CHARGEBACK_TYPE_VALUES.find(item => item.key === type);

  return [
    {
      id: selectedChargeback.id || 0,
      name: selectedChargeback.name || '',
      type: getTypeValue(selectedChargeback.chargeback_type) ? 'predefined' : 'custom',
      type_value_predefined: getTypeValue(selectedChargeback.chargeback_type)?.key || '',
      type_value_custom: getTypeValue(selectedChargeback.chargeback_type) ? '' : selectedChargeback.chargeback_type,
      budget: selectedChargeback.limit || '',
      // budget_type: selectedChargeback.chargeback_comparison || '',
      notify: !!selectedChargeback.notification_threshold,
      notify_threshold: selectedChargeback.notification_threshold || '',
      notify_slack: selectedChargeback.notification_slack || '',
      emailList: selectedChargeback?.notification_emails
        ? (selectedChargeback?.notification_emails || '').split(';').map(item => ({ value: item, label: item }))
        : [],
      projects_ids: projects_ids,
      regions: regions,
      products: products,
    },
    defaultProjects,
    defaultRegions,
    defaultProducts,
    defaultTags,
  ];
};

export const mapCloudPlatform = aggs => {
  if (!aggs?.tag_key_list_per_billing_type && !aggs?.arp_summary_billing_type) {
    return aggs;
  }

  const buckets = [];
  let doc_count = 0,
    doc_count_ = 0,
    doc_count_error_upper_bound = 0,
    sum_other_doc_count = 0;

  const summTmp = {
    arp_summary_products: {
      buckets: [],
      doc_count_error_upper_bound: 0,
      sum_other_doc_count: 0,
    },
    arp_summary_projects: {
      buckets: [],
      doc_count_error_upper_bound: 0,
      sum_other_doc_count: 0,
    },
    arp_summary_regions: {
      buckets: [],
      doc_count_error_upper_bound: 0,
      sum_other_doc_count: 0,
    },
  };
  const summArray = ['arp_summary_products', 'arp_summary_projects', 'arp_summary_regions'];

  (aggs.tag_key_list_per_billing_type?.buckets || []).forEach(bucket => {
    const cloudPlatform = bucket.key;
    doc_count += bucket.arp_resource_tag_key_list?.doc_count || 0;
    doc_count_ += bucket.arp_resource_tag_key_list?.filter?.doc_count || 0;
    doc_count_error_upper_bound += bucket.arp_resource_tag_key_list?.filter?.terms?.doc_count_error_upper_bound || 0;
    sum_other_doc_count += bucket.arp_resource_tag_key_list?.filter?.terms?.sum_other_doc_count || 0;

    (bucket.arp_resource_tag_key_list?.filter?.terms?.buckets || []).forEach(tag => {
      tag.cloudPlatform = cloudPlatform;
      buckets.push(tag);
    });
  });

  (aggs.arp_summary_billing_type?.buckets || []).forEach(bucket => {
    const cloudPlatform = bucket.key;

    summArray.forEach(item => {
      (bucket[item]?.buckets || []).forEach(bucket => {
        bucket.cloudPlatform = cloudPlatform;
        summTmp[item].buckets.push(bucket);
      });

      summTmp[item].doc_count_error_upper_bound += bucket[item]?.doc_count_error_upper_bound || 0;
    });
  });

  return {
    arp_resource_tag_key_list: {
      doc_count,
      filter: {
        doc_count: doc_count_,
        terms: {
          buckets,
          doc_count_error_upper_bound,
          sum_other_doc_count,
        },
      },
    },
    arp_summary_products: summTmp.arp_summary_products,
    arp_summary_projects: summTmp.arp_summary_projects,
    arp_summary_regions: summTmp.arp_summary_regions,
  };
};

import { numberWithCommasAndDecimal } from './helper';
import { currencyFormat } from './number';

export const CURRENCY_SYMBOL = [
  {
    code: 'AED',
    symbol: 'د.إ',
  },
  {
    code: 'AFN',
    symbol: '؋',
  },
  {
    code: 'ALL',
    symbol: 'Lek',
  },
  {
    code: 'AMD',
    symbol: '֏',
  },
  {
    code: 'ANG',
    symbol: 'ƒ',
  },
  {
    code: 'AOA',
    symbol: 'Kz',
  },
  {
    code: 'ARS',
    symbol: '$',
  },
  {
    code: 'AUD',
    symbol: 'A$',
  },
  {
    code: 'AWG',
    symbol: 'AWG',
  },
  {
    code: 'AZN',
    symbol: 'man.',
  },
  {
    code: 'BAM',
    symbol: 'KM',
  },
  {
    code: 'BBD',
    symbol: 'BBD',
  },
  {
    code: 'BDT',
    symbol: 'Tk',
  },
  {
    code: 'BGN',
    symbol: 'лв',
  },
  {
    code: 'BHD',
    symbol: 'BHD',
  },
  {
    code: 'BIF',
    symbol: 'BIF',
  },
  {
    code: 'BMD',
    symbol: 'BMD',
  },
  {
    code: 'BND',
    symbol: 'BND',
  },
  {
    code: 'BOB',
    symbol: 'BOB',
  },
  {
    code: 'BRL',
    symbol: 'R$',
  },
  {
    code: 'BSD',
    symbol: 'BSD',
  },
  {
    code: 'BTN',
    symbol: 'BTN',
  },
  {
    code: 'BWP',
    symbol: 'BWP',
  },
  {
    code: 'BYN',
    symbol: 'BYN',
  },
  {
    code: 'BZD',
    symbol: 'BZ$',
  },
  {
    code: 'CAD',
    symbol: 'C$',
  },
  {
    code: 'CDF',
    symbol: 'CDF',
  },
  {
    code: 'CHF',
    symbol: 'SFr.',
  },
  {
    code: 'CLP',
    symbol: '$',
  },
  {
    code: 'CNY',
    symbol: '¥',
  },
  {
    code: 'COP',
    symbol: '$',
  },
  {
    code: 'CRC',
    symbol: '₡',
  },
  {
    code: 'CUC',
    symbol: 'CUC$',
  },
  {
    code: 'CUP',
    symbol: 'CUP',
  },
  {
    code: 'CVE',
    symbol: 'CVE',
  },
  {
    code: 'CZK',
    symbol: 'Kč',
  },
  {
    code: 'DJF',
    symbol: 'DJF',
  },
  {
    code: 'DKK',
    symbol: 'kr',
  },
  {
    code: 'DOP',
    symbol: '$',
  },
  {
    code: 'DZD',
    symbol: 'DZD',
  },
  {
    code: 'EGP',
    symbol: 'E£',
  },
  {
    code: 'ERN',
    symbol: 'ERN',
  },
  {
    code: 'ETB',
    symbol: 'Br',
  },
  {
    code: 'EUR',
    symbol: '€',
  },
  {
    code: 'FJD',
    symbol: 'FJD',
  },
  {
    code: 'FKP',
    symbol: '£',
  },
  {
    code: 'GBP',
    symbol: '£',
  },
  {
    code: 'GEL',
    symbol: 'GEL',
  },
  {
    code: 'GGP',
    symbol: '£',
  },
  {
    code: 'GHS',
    symbol: 'GH¢',
  },
  {
    code: 'GIP',
    symbol: '£',
  },
  {
    code: 'GMD',
    symbol: 'GMD',
  },
  {
    code: 'GNF',
    symbol: 'GNF',
  },
  {
    code: 'GTQ',
    symbol: 'Q',
  },
  {
    code: 'GYD',
    symbol: 'GYD',
  },
  {
    code: 'HKD',
    symbol: '$',
  },
  {
    code: 'HNL',
    symbol: 'L',
  },
  {
    code: 'HRK',
    symbol: 'kn',
  },
  {
    code: 'HTG',
    symbol: 'HTG',
  },
  {
    code: 'HUF',
    symbol: 'Ft',
  },
  {
    code: 'IDR',
    symbol: 'Rp',
  },
  {
    code: 'ILS',
    symbol: '₪',
  },
  {
    code: 'IMP',
    symbol: '£',
  },
  {
    code: 'INR',
    symbol: '₹',
  },
  {
    code: 'IQD',
    symbol: 'IQD',
  },
  {
    code: 'IRR',
    symbol: '﷼',
  },
  {
    code: 'ISK',
    symbol: 'kr',
  },
  {
    code: 'JEP',
    symbol: '£',
  },
  {
    code: 'JMD',
    symbol: 'J$',
  },
  {
    code: 'JOD',
    symbol: 'JD',
  },
  {
    code: 'JPY',
    symbol: '¥',
  },
  {
    code: 'KES',
    symbol: 'Ksh',
  },
  {
    code: 'KGS',
    symbol: 'KGS',
  },
  {
    code: 'KHR',
    symbol: '៛',
  },
  {
    code: 'KMF',
    symbol: 'KMF',
  },
  {
    code: 'KPW',
    symbol: 'KPW',
  },
  {
    code: 'KRW',
    symbol: '₩',
  },
  {
    code: 'KWD',
    symbol: 'ك',
  },
  {
    code: 'KYD',
    symbol: '$',
  },
  {
    code: 'KZT',
    symbol: '₸',
  },
  {
    code: 'LAK',
    symbol: 'LAK',
  },
  {
    code: 'LBP',
    symbol: 'LBP',
  },
  {
    code: 'LKR',
    symbol: 'Sr',
  },
  {
    code: 'LRD',
    symbol: 'L$',
  },
  {
    code: 'LSL',
    symbol: 'LSL',
  },
  {
    code: 'LYD',
    symbol: 'LYD',
  },
  {
    code: 'MAD',
    symbol: 'د.م.',
  },
  {
    code: 'MDL',
    symbol: 'MDL',
  },
  {
    code: 'MGA',
    symbol: 'MGA',
  },
  {
    code: 'MKD',
    symbol: 'ден',
  },
  {
    code: 'MMK',
    symbol: 'K',
  },
  {
    code: 'MNT',
    symbol: 'MNT',
  },
  {
    code: 'MOP',
    symbol: 'MOP',
  },
  {
    code: 'MRU',
    symbol: 'UM',
  },
  {
    code: 'MUR',
    symbol: 'Rs',
  },
  {
    code: 'MVR',
    symbol: 'MVR',
  },
  {
    code: 'MWK',
    symbol: 'MWK',
  },
  {
    code: 'MXN',
    symbol: 'Mex$',
  },
  {
    code: 'MYR',
    symbol: 'RM',
  },
  {
    code: 'MZN',
    symbol: 'MT',
  },
  {
    code: 'NAD',
    symbol: '$',
  },
  {
    code: 'NGN',
    symbol: '₦',
  },
  {
    code: 'NIO',
    symbol: 'NIO',
  },
  {
    code: 'NOK',
    symbol: 'kr',
  },
  {
    code: 'NPR',
    symbol: 'Rs',
  },
  {
    code: 'NZD',
    symbol: '$',
  },
  {
    code: 'OMR',
    symbol: 'OMR',
  },
  {
    code: 'PAB',
    symbol: 'B/.',
  },
  {
    code: 'PEN',
    symbol: 'S/.',
  },
  {
    code: 'PGK',
    symbol: 'PGK',
  },
  {
    code: 'PHP',
    symbol: '₱',
  },
  {
    code: 'PKR',
    symbol: 'Rs',
  },
  {
    code: 'PLN',
    symbol: 'zł',
  },
  {
    code: 'PYG',
    symbol: '₲',
  },
  {
    code: 'QAR',
    symbol: 'QR',
  },
  {
    code: 'RON',
    symbol: 'L',
  },
  {
    code: 'RSD',
    symbol: 'РСД',
  },
  {
    code: 'RUB',
    symbol: 'руб',
  },
  {
    code: 'RWF',
    symbol: 'RWF',
  },
  {
    code: 'SAR',
    symbol: 'SR',
  },
  {
    code: 'SBD',
    symbol: 'SI$',
  },
  {
    code: 'SCR',
    symbol: 'Rs',
  },
  {
    code: 'SDG',
    symbol: 'SDG',
  },
  {
    code: 'SEK',
    symbol: 'kr',
  },
  {
    code: 'SGD',
    symbol: 'S$',
  },
  {
    code: 'SHP',
    symbol: '£',
  },
  {
    code: 'SLL',
    symbol: 'SLL',
  },
  {
    code: 'SLE',
    symbol: 'SLE',
  },
  {
    code: 'SOS',
    symbol: 'Sh.So.',
  },
  {
    code: 'SRD',
    symbol: '$',
  },
  {
    code: 'SSP',
    symbol: 'SSP',
  },
  {
    code: 'STD',
    symbol: 'STD',
  },
  {
    code: 'STN',
    symbol: 'STN',
  },
  {
    code: 'SVC',
    symbol: '₡',
  },
  {
    code: 'SYP',
    symbol: '£',
  },
  {
    code: 'SZL',
    symbol: 'SZL',
  },
  {
    code: 'THB',
    symbol: '฿',
  },
  {
    code: 'TJS',
    symbol: 'TJS',
  },
  {
    code: 'TMT',
    symbol: 'TMT',
  },
  {
    code: 'TND',
    symbol: 'TND',
  },
  {
    code: 'TOP',
    symbol: 'TOP',
  },
  {
    code: 'TRY',
    symbol: '₺',
  },
  {
    code: 'TTD',
    symbol: 'TTD',
  },
  {
    code: 'TWD',
    symbol: 'NT$',
  },
  {
    code: 'TZS',
    symbol: 'tzs',
  },
  {
    code: 'UAH',
    symbol: '₴',
  },
  {
    code: 'UGX',
    symbol: 'Ush',
  },
  {
    code: 'USD',
    symbol: '$',
  },
  {
    code: 'UYU',
    symbol: '$U',
  },
  {
    code: 'UYW',
    symbol: 'UYW',
  },
  {
    code: 'UZS',
    symbol: 'UZS',
  },
  {
    code: 'VES',
    symbol: 'Bs.',
  },
  {
    code: 'VND',
    symbol: 'VND',
  },
  {
    code: 'VUV',
    symbol: 'VUV',
  },
  {
    code: 'WST',
    symbol: 'WST',
  },
  {
    code: 'XAF',
    symbol: 'XAF',
  },
  {
    code: 'XCD',
    symbol: '$',
  },
  {
    code: 'XOF',
    symbol: 'XOF',
  },
  {
    code: 'XPF',
    symbol: 'XPF',
  },
  {
    code: 'YER',
    symbol: '﷼',
  },
  {
    code: 'ZAR',
    symbol: 'R',
  },
  {
    code: 'ZMW',
    symbol: 'ZMW',
  },
];

export const getCurrencySymbol = code => {
  const rec = CURRENCY_SYMBOL.find(item => item.code === code);

  return rec?.symbol || '$';
};

export const formatCurrencyByCode = (amount, code) => {
  const rec = CURRENCY_SYMBOL.find(item => item.code === code);

  return (rec?.symbol || '') + numberWithCommasAndDecimal(amount);
};

//For ag-grid
export const currencyByCodeFormatter = (params, code) => {
  return formatCurrencyByCode(params.value, code);
};

// For page-use
export const currencyFormatterByCode = (isMultipleCurrency, isAWSSelected, value, code) => {
  return isMultipleCurrency && !isAWSSelected ? formatCurrencyByCode(value, code) : currencyFormat(value);
};

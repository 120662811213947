import produce from 'immer';
import get from 'lodash/get';

import {
  LOAD_MRC_COST_DATA_REQUEST,
  LOAD_MRC_COST_DATA_SUCCESS,
  LOAD_MRC_COST_DATA_ERROR,
  LOAD_COST_SUMMARY_REQUEST,
  LOAD_COST_SUMMARY_SUCCESS,
  LOAD_COST_SUMMARY_CREDITS_SUCCESS,
  LOAD_COST_SUMMARY_ERROR,
  LOAD_FILTER_DATA_REQUEST,
  LOAD_FILTER_DATA_SUCCESS,
  LOAD_FILTER_DATA_ERROR,
  LOAD_DEFAULT_FILTER_DATA_REQUEST,
  LOAD_DEFAULT_FILTER_DATA_SUCCESS,
  LOAD_DEFAULT_FILTER_DATA_ERROR,
  LOAD_COST_DETAIL_REQUEST,
  LOAD_COST_DETAIL_SUCCESS,
  LOAD_COST_DETAIL_ERROR,
  LOAD_COST_TAGS_REQUEST,
  LOAD_COST_TAGS_SUCCESS,
  LOAD_COST_TAGS_ERROR,
  LOAD_COST_CHARGEBACK_REQUEST,
  LOAD_COST_CHARGEBACK_SUCCESS,
  LOAD_COST_CHARGEBACK_ERROR,
  CREATE_COST_CHARGEBACK_SUCCESS,
  CREATE_COST_CHARGEBACK_REQUEST,
  CREATE_COST_CHARGEBACK_ERROR,
  UPDATE_MONTHLY_COST_CHARGEBACK,
  UPDATE_COST_CHARGEBACK_SUCCESS,
  UPDATE_COST_CHARGEBACK_REQUEST,
  UPDATE_COST_CHARGEBACK_ERROR,
  DELETE_COST_CHARGEBACK_SUCCESS,
  DELETE_COST_CHARGEBACK_REQUEST,
  DELETE_COST_CHARGEBACK_ERROR,
  LOAD_COST_CM_REQUEST,
  LOAD_COST_CM_SUCCESS,
  LOAD_COST_CM_ERROR,
  LOAD_ALL_COST_DATA_ERROR,
  LOAD_ALL_COST_DATA_SUCCESS,
  LOAD_ALL_COST_DATA_REQUEST,
  LOAD_USAGE_TYPE_REQUEST,
  LOAD_USAGE_TYPE_SUCCESS,
  LOAD_USAGE_TYPE_ERROR,
  LOAD_OPERATION_REQUEST,
  LOAD_OPERATION_SUCCESS,
  LOAD_OPERATION_ERROR,
  LOAD_CLUSTER_STATUS_REQUEST,
  LOAD_CLUSTER_STATUS_SUCCESS,
  LOAD_CLUSTER_STATUS_ERROR,
} from './constants';
import { COST_TYPE } from 'utils/constants';

export const savingLoadingInitialState = {
  unused: false,
  unusedAzure: false,
  underutilized: false,
  underutilizedAzure: false,
  rightsizing: false,
  spot: false,
};

// The initial state of the App
export const initialState = {
  costForAll: [
    { tab: COST_TYPE.CLOUD_ACCOUNTS, data: {}, filter: {} },
    { tab: COST_TYPE.CLOUD_PRODUCTS, data: {}, filter: {} },
    { tab: COST_TYPE.REGIONS, data: {}, filter: {} },
    { tab: COST_TYPE.RESOURCES, data: {}, filter: {} },
    { tab: COST_TYPE.NON_RESOURCES, data: {}, filter: {} },
    { tab: COST_TYPE.OPERATIONS, data: {}, filter: {} },
    { tab: COST_TYPE.USAGE_TYPES, data: {}, filter: {} },
  ],
  loadedAWSOnlyData: false,
  loadedCloudData: false,
  filters: {},
  filtersCloud: {},
  selectedFilters: {},
  filterLoading: false,
  defaultFilters: {},
  defaultFiltersCloud: {},
  defaultFilterLoading: false,
  loading: false,
  detailCostAll: null,
  detailLoading: false,
  tagsLoading: false,
  tagsData: null,
  untaggedResources: {},
  tagsFilter: {},
  chargebackData: [],
  chargebackLoading: false,
  summaryData: {},
  summaryLoading: false,
  summaryFilter: {},
  CMData: {
    daily: {},
    weekly: {},
    monthly: {},
  },
  mrcData: {},
  clusterStatus: [],
  cmLoading: false,
  unused: null,
  unusedAzure: null,
  underutilized: null,
  underutilizedAzure: null,
  rightsizing: null,
  spot: null,
  savingLoading: savingLoadingInitialState,
  operationData: {
    search: '',
    data: [],
  },
  usageTypeData: {
    search: '',
    data: [],
  },
};

/* eslint-disable default-case, no-param-reassign */
const homeReducer = (state = initialState, action) =>
  produce(state, draft => {
    let rec, tagsData, untaggedResources;

    switch (action.type) {
      case LOAD_CLUSTER_STATUS_REQUEST:
        draft.clusterStatus = [];

        break;
      case LOAD_CLUSTER_STATUS_SUCCESS:
        draft.clusterStatus = action.response || [];

        break;
      case LOAD_CLUSTER_STATUS_ERROR:
        draft.clusterStatus = [];

        break;
      case LOAD_MRC_COST_DATA_REQUEST:
        draft.mrcData = {};

        break;
      case LOAD_MRC_COST_DATA_SUCCESS:
        draft.mrcData = action.response || {};

        break;
      case LOAD_MRC_COST_DATA_ERROR:
        draft.mrcData = {};

        break;
      case LOAD_COST_SUMMARY_REQUEST:
        draft.summaryLoading = true;
        draft.summaryData = {};

        break;
      case LOAD_COST_SUMMARY_SUCCESS:
        draft.summaryLoading = false;
        draft.summaryData = action.response.data || {};
        draft.summaryFilter = action.response.filter;

        break;
      case LOAD_COST_SUMMARY_CREDITS_SUCCESS:
        {
          let creditsAgg = action.response.data?.cloud_type_agg?.buckets.find(({ key }) => key === 'aws');
          creditsAgg = creditsAgg ? creditsAgg.record_type_filter?.credits_nested_agg?.credits_agg || [] : [];
          draft.summaryLoading = false;
          draft.summaryCreditsData = creditsAgg;
        }

        break;
      case LOAD_COST_SUMMARY_ERROR:
        draft.summaryLoading = false;
        draft.summaryData = {};

        break;
      case LOAD_ALL_COST_DATA_REQUEST:
        draft.loading = true;

        break;
      case LOAD_ALL_COST_DATA_SUCCESS:
        {
          draft.loading = false;

          const index = (initialState?.costForAll || []).findIndex(x => x.tab === action.response.tab);

          draft.costForAll[index].data = action.response.data.data.aggregations || {};
          draft.costForAll[index].filter = action.response.filter;
          draft.selectedFilters = action.response?.filter || {};
        }

        break;
      case LOAD_ALL_COST_DATA_ERROR:
        draft.loading = false;

        break;
      case LOAD_FILTER_DATA_REQUEST:
        draft.filters = {};
        draft.filterLoading = true;

        break;
      case LOAD_FILTER_DATA_SUCCESS:
        {
          draft.filterLoading = false;

          const arp_obj = action.response?.data?.aggregations?.arp_summary_billing_type;
          arp_obj && (draft.loadedCloudData = true);
          !arp_obj && (draft.loadedAWSOnlyData = true);

          const arp_summary_products = arp_obj
            ? {
                buckets: (arp_obj.buckets || []).flatMap(b =>
                  (b.arp_summary_products?.buckets || []).map(v => ({
                    ...v,
                    cloudPlatform: b.key,
                  })),
                ),
              }
            : [];

          const arp_summary_projects = arp_obj
            ? {
                buckets: (arp_obj.buckets || []).flatMap(b =>
                  (b.arp_summary_projects?.buckets || []).map(v => ({
                    ...v,
                    cloudPlatform: b.key,
                  })),
                ),
              }
            : [];

          const arp_summary_regions = arp_obj
            ? {
                buckets: (arp_obj.buckets || []).flatMap(b =>
                  (b.arp_summary_regions?.buckets || []).map(v => ({
                    ...v,
                    cloudPlatform: b.key,
                  })),
                ),
              }
            : [];
          const filters = {};

          if (arp_summary_products && arp_summary_products.buckets) {
            filters.arp_summary_products = arp_summary_products;
          }

          if (arp_summary_projects && arp_summary_projects.buckets) {
            filters.arp_summary_projects = arp_summary_projects;
          }

          if (arp_summary_regions && arp_summary_regions.buckets) {
            filters.arp_summary_regions = arp_summary_regions;
          }

          if (action.response?.data?.aggregations?.arp_summary_billing_type) {
            filters.cloud_data = action.response?.data?.aggregations?.arp_summary_billing_type
              ? (action.response?.data?.aggregations?.arp_summary_billing_type?.buckets || []).map(bucket => ({
                  key: bucket.key,
                  doc_count: bucket.doc_count,
                }))
              : [];
          }

          draft.filters = arp_obj ? {} : action.response.data.aggregations || {};
          draft.filtersCloud = filters;
        }

        break;
      case LOAD_FILTER_DATA_ERROR:
        draft.filterLoading = false;

        break;
      case LOAD_DEFAULT_FILTER_DATA_REQUEST:
        draft.defaultFilters = {};
        draft.defaultFilterLoading = true;

        break;
      case LOAD_DEFAULT_FILTER_DATA_SUCCESS:
        {
          draft.defaultFilterLoading = false;

          const arp_summary_products = action?.response?.arp_summary_billing_type
            ? {
                buckets: (action?.response?.arp_summary_billing_type?.buckets || []).flatMap(b =>
                  (b.arp_summary_products?.buckets || []).map(v => ({
                    ...v,
                    cloudPlatform: b.key,
                  })),
                ),
              }
            : [];

          const arp_summary_projects = action?.response?.arp_summary_billing_type
            ? {
                buckets: (action?.response?.arp_summary_billing_type?.buckets || []).flatMap(b =>
                  (b.arp_summary_projects?.buckets || []).map(v => ({
                    ...v,
                    cloudPlatform: b.key,
                  })),
                ),
              }
            : [];

          const arp_summary_regions = action?.response?.arp_summary_billing_type
            ? {
                buckets: (action?.response?.arp_summary_billing_type?.buckets || []).flatMap(b =>
                  (b.arp_summary_regions?.buckets || []).map(v => ({
                    ...v,
                    cloudPlatform: b.key,
                  })),
                ),
              }
            : [];
          const defaultFiltersCloud = {};

          if (arp_summary_products && arp_summary_products.buckets) {
            defaultFiltersCloud.arp_summary_products = arp_summary_products;
          }

          if (arp_summary_projects && arp_summary_projects.buckets) {
            defaultFiltersCloud.arp_summary_projects = arp_summary_projects;
          }

          if (arp_summary_regions && arp_summary_regions.buckets) {
            defaultFiltersCloud.arp_summary_regions = arp_summary_regions;
          }

          if (action?.response?.arp_summary_billing_type) {
            defaultFiltersCloud.cloud_data = action?.response?.arp_summary_billing_type
              ? (action?.response?.arp_summary_billing_type?.buckets || []).map(bucket => ({
                  key: bucket.key,
                  doc_count: bucket.doc_count,
                }))
              : [];
          }

          draft.defaultFilters = !action?.response?.arp_summary_billing_type ? action.response || {} : {};
          draft.defaultFiltersCloud = defaultFiltersCloud;
        }

        break;
      case LOAD_DEFAULT_FILTER_DATA_ERROR:
        draft.defaultFilterLoading = false;

        break;
      case LOAD_COST_DETAIL_REQUEST:
        draft.detailCostAll = null;
        draft.detailLoading = true;

        break;
      case LOAD_COST_DETAIL_SUCCESS:
        draft.detailLoading = false;
        draft.detailCostAll = action.response.data.aggregations;

        break;
      case LOAD_COST_DETAIL_ERROR:
        draft.detailLoading = false;

        break;
      case LOAD_COST_TAGS_REQUEST:
        draft.tagsLoading = true;
        draft.tagsData = null;
        draft.untaggedResources = {};

        break;
      case LOAD_COST_TAGS_SUCCESS:
        draft.tagsLoading = false;
        tagsData = get(action.response, 'res.data.aggregations.arp_tags_kv.arp_tags_keys.buckets', []);
        untaggedResources = get(action.response, 'res.data.aggregations.untagged_resources', {});
        draft.tagsData = tagsData ? tagsData.filter(tag => tag.key !== 'description') : [];
        draft.untaggedResources = untaggedResources ? untaggedResources : {};
        draft.tagsFilter = action.response.filters;

        if (action.response.payload) {
          draft.selectedFilters = action.response.payload;
        }

        break;
      case LOAD_COST_TAGS_ERROR:
        draft.tagsLoading = false;
        draft.tagsData = [];

        break;
      case LOAD_COST_CHARGEBACK_REQUEST:
        draft.chargebackLoading = true;
        draft.chargebackData = [];

        break;
      case LOAD_COST_CHARGEBACK_SUCCESS:
        draft.chargebackLoading = false;
        draft.chargebackData = action.response;

        break;
      case LOAD_COST_CHARGEBACK_ERROR:
        draft.chargebackLoading = false;
        draft.chargebackData = [];

        break;
      case CREATE_COST_CHARGEBACK_REQUEST:
        draft.chargebackLoading = true;

        break;
      case CREATE_COST_CHARGEBACK_SUCCESS:
        draft.chargebackLoading = false;

        if (draft.chargebackData.length === 1 && draft.chargebackData[0].id === action.response.id) {
          break;
        }

        draft.chargebackData.unshift(action.response);

        break;
      case CREATE_COST_CHARGEBACK_ERROR:
        draft.chargebackLoading = false;

        break;
      case UPDATE_COST_CHARGEBACK_REQUEST:
        draft.chargebackLoading = true;

        break;
      case UPDATE_COST_CHARGEBACK_SUCCESS:
        draft.chargebackLoading = false;
        rec = (draft.chargebackData || []).findIndex(t => t.id === action.response.id);
        draft.chargebackData[rec] = action.response.data;

        break;
      case UPDATE_MONTHLY_COST_CHARGEBACK:
        rec = (draft.chargebackData || []).findIndex(t => t.id === action.payload.id);
        draft.chargebackData[rec].updated_cost_data = action.payload.data;

        break;
      case UPDATE_COST_CHARGEBACK_ERROR:
        draft.chargebackLoading = false;

        break;
      case DELETE_COST_CHARGEBACK_REQUEST:
        draft.chargebackLoading = true;

        break;
      case DELETE_COST_CHARGEBACK_SUCCESS:
        draft.chargebackLoading = false;
        rec = (draft.chargebackData || []).findIndex(t => t.id === action.response);

        if (rec > -1) {
          draft.chargebackData.splice(rec, 1);
        }

        break;
      case DELETE_COST_CHARGEBACK_ERROR:
        draft.chargebackLoading = false;

        break;
      case LOAD_COST_CM_REQUEST:
        draft.cmLoading = true;

        break;
      case LOAD_COST_CM_SUCCESS:
        {
          draft.cmLoading = false;

          const { tab, data } = action.response;

          switch (tab) {
            case 'Daily':
              draft.CMData.daily = {
                ...data.data,
                filters: data.filters,
              };

              break;
            case 'Weekly':
              draft.CMData.weekly = {
                ...data.data,
                filters: data.filters,
              };

              break;
            case 'Monthly':
              draft.CMData.monthly = {
                mtd: data.data.mtd,
                monthly: data.data.monthly,
                filters: data.filters,
              };

              break;
            default:
              break;
          }
        }

        break;
      case LOAD_COST_CM_ERROR:
        draft.cmLoading = false;
        draft.CMData = {
          daily: {},
          weekly: {},
          monthly: {},
        };

        break;
      case LOAD_OPERATION_REQUEST:
        draft.operationData.search = '';
        draft.operationData.data = [];
        draft.operationData.loading = true;

        break;
      case LOAD_OPERATION_SUCCESS:
        draft.operationData.search = action.response.search;
        draft.operationData.data = action.response.data;
        draft.operationData.loading = false;

        break;
      case LOAD_OPERATION_ERROR:
        draft.operationData.loading = false;

        break;
      case LOAD_USAGE_TYPE_REQUEST:
        draft.usageTypeData.search = '';
        draft.usageTypeData.data = [];
        draft.usageTypeData.loading = true;

        break;
      case LOAD_USAGE_TYPE_SUCCESS:
        draft.usageTypeData.search = action.response.search;
        draft.usageTypeData.data = action.response.data;
        draft.usageTypeData.loading = false;

        break;
      case LOAD_USAGE_TYPE_ERROR:
        draft.usageTypeData.loading = false;

        break;
    }
  });

export default homeReducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api_condition_success, globalShowNotification } from 'containers/App/saga';
import { getErrorMessage, numberFix } from 'utils/helper';
import { loadCostCachedData, loadCostData } from 'api/cost';

export const initialState = {
  resourceLoading: false,
  summaryData: {},
  summaryLoading: false,
  resourcesData: [],
  nonResourcesData: {},
  counter: {},
};

export const RESOURCE_DETAIL_COST_PAGE = 'RESOURCE_DETAIL_COST_PAGE';

export const loadResourceDetailData = createAsyncThunk(
  `${RESOURCE_DETAIL_COST_PAGE}/loadResourceDetailData`,
  async (payload, { rejectWithValue }) => {
    try {
      const payloadDetail = { ...payload };
      const type = payloadDetail.type;
      const apiCall = type === 'cached' ? loadCostCachedData : loadCostData;
      delete payloadDetail.type;

      const res = await apiCall(payloadDetail);

      if (api_condition_success(res.status)) {
        return type === 'cached' ? res.data : res.data.aggregations;
      } else {
        await globalShowNotification(res);

        throw res;
      }
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  },
);

export const loadSummaryDetailData = createAsyncThunk(
  `${RESOURCE_DETAIL_COST_PAGE}/loadSummaryDetailData`,
  async (payload, { rejectWithValue }) => {
    try {
      const payloadDetail = { ...payload };
      const type = payloadDetail.type;
      const apiCall = type === 'cached' ? loadCostCachedData : loadCostData;
      delete payloadDetail.type;

      const res = await apiCall(payloadDetail);

      if (api_condition_success(res.status)) {
        if (type === 'cached') {
          return res.data;
        }

        const resourceCost = numberFix(
          res?.data?.aggregations?.resources?.resources_agg?.resources_total_cost?.summary?.value || 0,
        );

        const nonResourceCost = numberFix(
          res?.data?.aggregations?.non_resources?.non_resources_agg?.non_resources_total_cost?.summary?.value || 0,
        );

        const credits = numberFix(
          res?.data?.aggregations?.record_type_filter?.credits_nested_agg?.credits_agg?.credits_total?.summary?.value ||
            0,
        );

        return {
          totalCost: numberFix(resourceCost + nonResourceCost),
          nonResourceCost,
          credits,
          resourceCost,
        };
      } else {
        await globalShowNotification(res);
      }
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  },
);

const resourceDetailSlice = createSlice({
  name: RESOURCE_DETAIL_COST_PAGE,
  initialState,
  reducers: {},
  extraReducers: {
    [loadResourceDetailData.pending]: state => {
      state.resourceLoading = true;
    },
    [loadResourceDetailData.fulfilled]: (state, { payload }) => {
      const count = payload?.arp_billing_type_operations?.buckets?.[0]?.non_resources_agg?.doc_count || 0;
      state.counter.totalCount = count + (payload?.resources?.doc_count || 0);
      state.counter.resourceCount = payload?.resources?.doc_count || 0;
      state.counter.nonResourceCount = count;
      state.resourceLoading = false;
      state.resourcesData = typeof payload === 'object' ? payload?.resources?.resources_sorted?.buckets : [];
      state.nonResourcesData =
        typeof payload === 'object'
          ? {
              operations: (
                payload?.arp_billing_type_operations?.buckets?.[0]?.non_resources_agg?.arp_operations_nested
                  ?.arp_operations?.buckets || []
              ).map(item => ({
                name: item.key,
                cost: numberFix(item?._sort_values?.summary?.value || 0),
                type: 'Operations',
                billing_type: payload?.arp_billing_type_operations?.buckets?.[0]?.key || '',
              })),
              usageTypes: (
                payload?.arp_billing_type_usage_types?.buckets?.[0]?.non_resources_agg?.arp_usagetypes_nested
                  ?.arp_usagetypes?.buckets || []
              ).map(item => ({
                name: item.key,
                cost: numberFix(item?._sort_values?.summary?.value || 0),
                type: 'Usage Types',
                billing_type: payload?.arp_billing_type_usage_types?.buckets?.[0]?.key || '',
              })),
            }
          : {};
    },
    [loadResourceDetailData.rejected]: state => {
      state.resourceLoading = false;
    },
    [loadSummaryDetailData.pending]: state => {
      state.summaryLoading = true;
    },
    [loadSummaryDetailData.fulfilled]: (state, { payload }) => {
      state.summaryLoading = false;
      state.summaryData = typeof payload === 'object' ? payload : {};
    },
    [loadSummaryDetailData.rejected]: state => {
      state.summaryLoading = false;
    },
  },
});

export default resourceDetailSlice.reducer;

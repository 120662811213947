import React, { Fragment } from 'react';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import Tippy from '@tippyjs/react';

import { RULE_PATH } from 'utils/constants';
import { checkIsEqual, getClassNameBasedOnCloud } from 'utils/helper';
import { getNewQueryString, getParamFromQueryString, removeQueryString } from 'utils/queryString';
import { RULES_DESCRIPTION_TEXT } from 'utils/RuleDetail';

import { GraterThenArrowIcon, BottomArrowIcon } from 'assets/images';
import { CMRuleIcon, WarningIcon, LinkArrowIcon } from 'assets/images/nOpsRule';
import NoDetectionIcon from 'assets/images/no-detection.svg';

import { Anchor } from 'components/Custom';
import Loader from 'components/LoadingIndicator/Loader';
import { CMDataInitialize } from 'containers/Search/WARSummary/initializeCMData';
import ResourceDetails from 'containers/Search/Detail';
import { getDataOfCMToDisplay, RULE_DETAILS_MAPPING, RULES_PILLAR } from '../utils';

export default class ChangeManagement extends React.Component {
  constructor(props) {
    super(props);

    const activePillar = getParamFromQueryString(props.location.search, 'cmTab') || RULES_PILLAR.SECURITY;
    this.state = {
      activePillar,
      pillar: [],
      ruleMapping: RULE_DETAILS_MAPPING(RULES_DESCRIPTION_TEXT),
      collapse: {},
      loading: false,
      showModal: false,
      record: {},
    };
  }

  componentDidMount() {
    this.setInitialBucketData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data: { change_log: { buckets = [] } = {} } = {} } = this.props;
    const { data: { change_log: { buckets: oldBuckets = [] } = {} } = {} } = prevProps;
    const { pillar } = this.state;

    if (checkIsEqual(false, this.props.data, {}) && buckets.length && checkIsEqual(false, buckets, oldBuckets)) {
      this.setInitialBucketData();
    }

    if (oldBuckets.length && !buckets.length) {
      pillar.forEach(x => (x.data = []));
      this.setState({
        pillar,
      });
    }
  }

  handleRecord = row => {
    this.setState({
      showModal: true,
      record: { id: row.id, projectId: row.projectId },
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      record: {},
    });
  };

  handlePillarChange = key => () => {
    this.setState({ activePillar: key }, () => {
      const search = removeQueryString(this.props.location.search, 'cmTab');
      this.props.history.push({
        pathname: RULE_PATH.RESOURCES,
        search: `${search}&cmTab=${key}`,
      });
    });
  };

  redirectToRule = (link, cDate) => () => {
    let searchString = removeQueryString(this.props.location.search, 'cmTab');
    searchString = removeQueryString(searchString, 'tab');

    const search = getNewQueryString(searchString, {
      activeTab: RULES_PILLAR.CM,
    });
    window.open(`${link + '&' + search + '#' + cDate}`, '_blank');
  };

  setInitialBucketData = () => {
    const {
      data: { change_log: { buckets = [] } = {} },
      projects,
    } = this.props;
    const { ruleMapping } = this.state;

    if (!buckets.length) {
      return null;
    }

    const bucket_clone = cloneDeep(buckets);
    const data = CMDataInitialize([], bucket_clone, ruleMapping, projects, [], []);
    this.setState({
      pillar: data.pillar,
      collapse: data.collapse,
    });
  };

  handleViewMore = date => () => {
    const { collapse, activePillar } = this.state;
    const test = cloneDeep(collapse);
    const cIndex = collapse[activePillar].findIndex(x => x === date);

    if (cIndex > -1) {
      test[activePillar].splice(cIndex, 1);
    } else {
      test[activePillar].push(date);
    }

    this.setState({
      collapse: { ...test },
    });
  };

  getTextToShow = ruleData => {
    const data = getDataOfCMToDisplay(ruleData, this.handleRecord, undefined);

    return data || null;
  };

  render() {
    const {
      data: { doc_count = 0 },
      configCatAzure,
    } = this.props;
    const { activePillar, pillar, collapse, loading } = this.state;
    const activePillarData = pillar.find(x => x.key === activePillar);

    return (
      <div
        className={classNames('np-table2 np-rulesCm np-rulesCmMain', {
          'np-nops-loading': loading,
        })}
      >
        <Loader />

        <div className="title">
          <div className="np-titleIcon blue">
            <CMRuleIcon />
          </div>
          &nbsp;Change Management ({doc_count})
        </div>

        <ul className="np-tableTabs">
          {(pillar || []).map((x, index) => {
            const pillarClass = classNames({
              'np-button color-grey light': true,
              active: activePillar === x.key,
            });

            return (
              <li key={index}>
                <Anchor id={x.key} className={pillarClass} onClick={this.handlePillarChange(x.key)}>
                  {x.value}
                </Anchor>
              </li>
            );
          })}
        </ul>

        <div className="np-table2-wrapper">
          {!activePillarData || !activePillarData?.data || activePillarData?.data?.length === 0 ? (
            <div className="noData">
              <div className="icon">
                <img src={NoDetectionIcon} alt={''} />
              </div>
              <p>No changes detected to show</p>
            </div>
          ) : (
            (activePillarData?.data || []).map((data, index) => {
              const collapseClass =
                (collapse[activePillar].length > 0 && collapse[activePillar].includes(data.date)) || '';

              return (
                <Fragment key={index}>
                  <div className={`date ${!collapseClass ? 'collapse' : ''}`}>
                    {data.key}
                    <Tippy content={collapseClass ? 'Collapse' : 'Expand'} placement={'left'}>
                      <button
                        id={data.date}
                        type="button"
                        className="np-button grey"
                        onClick={this.handleViewMore(data.date)}
                      >
                        {!collapseClass ? <GraterThenArrowIcon /> : <BottomArrowIcon />}
                      </button>
                    </Tippy>
                  </div>
                  {collapseClass && (
                    <table className={!collapseClass ? 'collapse' : ''}>
                      <tbody>
                        {(data?.data || []).map((ruleData, index) => (
                          <tr id={'redirectToRule'} key={index} onClick={this.redirectToRule(ruleData.link, data.date)}>
                            <td>
                              <div
                                className={`data detection ${getClassNameBasedOnCloud(
                                  configCatAzure,
                                  ruleData.cloudPlatform,
                                )}`}
                              >
                                <div title={ruleData.desc}>
                                  <i className="np-tableIcon red">
                                    <WarningIcon />
                                  </i>
                                  {this.getTextToShow(ruleData)}

                                  <i className="np-tableIcon grey action">
                                    <LinkArrowIcon />
                                  </i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </Fragment>
              );
            })
          )}
        </div>
        {this.state.showModal ? <ResourceDetails {...this.state.record} onClose={this.closeModal} /> : null}
      </div>
    );
  }
}

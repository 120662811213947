/* istanbul ignore file */

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'regenerator-runtime';
import configureStore from './configureStore';
import { IntercomProvider } from 'react-use-intercom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { QueryClientProvider, QueryClient } from 'react-query';
import { LicenseManager } from 'ag-grid-enterprise';
import TagManager from 'react-gtm-module';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  registerables,
  LineController,
  BarElement,
  BarController,
} from 'chart.js';
import tippy from 'tippy.js';

import App from 'containers/App';
import LoadingIndicator from 'components/LoadingIndicator';
import ScrollToTop from 'components/ScrollToTop';
import history from 'utils/history';
import { FeatureFlagsProvider, configClient } from 'context/feature-flags-context';
import './global-setup';
import { getEnv, PRODUCTION_ENV } from 'utils/env';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  BarElement,
  BarController,
  ...registerables,
);

const MOUNT_NODE = document.getElementById('root');
const initialState = {};
const store = configureStore(initialState, history);
const INTERCOM_APP_ID = getEnv() === PRODUCTION_ENV ? 'z6bxagzt' : 'qy7gde73';
const queryClient = new QueryClient();
const tagManagerArgs = { gtmId: 'GTM-TD4P5BK' };

TagManager.initialize(tagManagerArgs);

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-060812}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Nops_Operations}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{nOps}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{nOps}_need_to_be_licensed___{nOps}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{31_July_2025}____[v3]_[01]_MTc1MzkxNjQwMDAwMA==ef8640207df219c3968caefe7a0a143b',
);

tippy.setDefaultProps({
  arrow: false,
  delay: 50,
  theme: 'nOps',
});

ReactDOM.render(
  <IntercomProvider appId={INTERCOM_APP_ID}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Suspense fallback={<LoadingIndicator />}>
          <ConnectedRouter history={history}>
            <FeatureFlagsProvider value={configClient}>
              <ScrollToTop />
              <App />
            </FeatureFlagsProvider>
          </ConnectedRouter>
        </Suspense>
      </Provider>
    </QueryClientProvider>
  </IntercomProvider>,
  MOUNT_NODE,
);

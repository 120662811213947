const augment_list = (list, unMutable) => {
  const tmp = [];

  for (let i = 0; i < list.length - 1; i++) {
    tmp.push(' ' + list[i] + ' ');
  }

  for (let i = 0; i < list.length - 1; i++) {
    tmp.push(' ' + list[i]);
  }

  for (let i = 0; i < list.length - 1; i++) {
    tmp.push(list[i] + ' ');
  }

  return tmp.concat(unMutable).concat(list);
};

const before_field_name_words = augment_list(['|', ' &', '(', ']'], [' and ', ' or ', ' in ']);
const after_field_name_words = augment_list(['>=', '<=', '=', '>', '<'], ['in']);
const delimiters = augment_list(['|', ' &', '(', ')', '[', ']', '>=', '<=', '=', '>', '<'], [' and ', ' or ', 'in ']);
const logic_ops = [' and ', ' or ', 'in '];
let autocomplete_type = 'field';
let last_parsed_field = '';
let empty_string = true;

export const getDSLAutocompleteData = () => {
  const data = parse_dsl();

  if (data !== undefined) {
    return data;
  }
};

const check_stop_patterns_delimiter = (patterns_array, buffer, dsl_string, iter_pos, offset, stop_pattern) => {
  if (offset > 1) {
    buffer = dsl_string[iter_pos - offset] + buffer;
  }

  patterns_array.forEach(value => {
    if (buffer.includes(value) && value.length > stop_pattern.length) {
      stop_pattern = value;
    }
  });

  if (offset < 4) {
    const values = check_stop_patterns_delimiter(
      patterns_array,
      buffer,
      dsl_string,
      iter_pos,
      offset + 1,
      stop_pattern,
    );

    if (values[1].length > stop_pattern.length) {
      buffer = values[0];
      stop_pattern = values[1];
    }
  }

  return [buffer, stop_pattern];
};

const parse_last_object = (dsl_string, cursor_position) => {
  let buffer = '';
  let posFrom = -1;
  let stop_pattern_found = false;
  let iterator_position = cursor_position;

  if (dsl_string === '') {
    autocomplete_type = 'field';
    last_parsed_field = '';
    empty_string = true;
  }

  // let current_char = dsl_string[iterator_position - 1]
  // let spaces_count = 0
  while (iterator_position > 0) {
    posFrom = iterator_position;

    const current_char = dsl_string[iterator_position - 1];
    buffer = current_char + buffer;

    // if (current_char === ' ') spaces_count += 1
    if (delimiters.indexOf(buffer) >= 0) {
      if (autocomplete_type === 'field') {
        parse_last_object(dsl_string, iterator_position - buffer.length);

        if (last_parsed_field[last_parsed_field.length - 1] === ' ') {
          last_parsed_field = last_parsed_field.slice(0, last_parsed_field.length - 1);
        }

        const result = {};

        if (logic_ops.indexOf(buffer) >= 0) {
          result.type = 'field';
        } else {
          result.type = 'value';
        }

        result.relatedField = last_parsed_field;
        result.value = '';
        result.posFrom = iterator_position + buffer.length;
        result.posTo = cursor_position;

        return result;
      }

      return;
    }

    let tmp = buffer;
    let delimiter_found = false;
    let cnt = 0;

    if (autocomplete_type !== '') {
      // TODO need to fix this later
      // eslint-disable-next-line no-loop-func
      delimiters.forEach(value => {
        if (tmp.startsWith(value)) {
          if (tmp[0] === ' ') {
            cnt = 1;
          }

          tmp = tmp.slice(value.length, buffer.length);

          if (tmp.split(' ').length - 1 > cnt) {
            delimiter_found = true;
          }

          return null;
        }
      });
    }

    if (delimiter_found) {
      return;
    }

    if (!delimiter_found && autocomplete_type === 'value') {
      autocomplete_type = 'field';
      empty_string = true;
    }

    // TODO need to fix this later
    // eslint-disable-next-line no-loop-func
    before_field_name_words.forEach(value => {
      if (!stop_pattern_found && buffer.includes(value)) {
        stop_pattern_found = true;
        buffer = buffer.slice(value.length, buffer.length);
        posFrom = iterator_position + value.length;
        autocomplete_type = 'field';
        last_parsed_field = buffer;
        empty_string = false;
      }
    });

    if (stop_pattern_found) {
      break;
    }

    // TODO need to fix this later
    // eslint-disable-next-line no-loop-func
    after_field_name_words.forEach(value => {
      if (buffer.includes(value)) {
        stop_pattern_found = true;

        const values = check_stop_patterns_delimiter(
          after_field_name_words,
          buffer,
          dsl_string,
          iterator_position,
          1,
          value,
        );
        parse_last_object(dsl_string, iterator_position - values[1].length + 1);

        if (values[1][values[1].length - 1] === ' ') {
          posFrom += 2;
        } else {
          posFrom += 1;
        }

        buffer = values[0].slice(values[1].length, values[0].length);
        autocomplete_type = 'value';
        empty_string = false;
      }
    });

    if (stop_pattern_found) {
      // TODO need to fix this later
      // eslint-disable-next-line no-loop-func
      before_field_name_words.forEach(value => {
        if (buffer.includes(value)) {
          stop_pattern_found = true;
          buffer = buffer.slice(value.length, buffer.length);
          autocomplete_type = 'field';
          last_parsed_field = buffer;
          empty_string = false;
        }
      });

      break;
    }

    iterator_position -= 1;
  }

  if (empty_string) {
    last_parsed_field = buffer;
  }

  const result = {};
  result.type = autocomplete_type;
  result.relatedField = last_parsed_field;
  result.value = buffer;
  result.posFrom = posFrom;
  result.posTo = cursor_position;

  return result;
};

const parse_dsl = () => {
  const input_field = document.getElementById('dsl-input');

  if (!input_field) {
    return {};
  }

  const truncated_input = input_field.value.replace(/\s+/g, ' ');

  if (input_field.value.length !== truncated_input.length) {
    input_field.value = truncated_input;

    return;
  }

  input_field.value = truncated_input;

  const dsl_string = input_field.value;
  const cursor_position = input_field.selectionStart;

  return parse_last_object(dsl_string, cursor_position);
};

/*const getCaretPosition = (oField) => {
  // Initialize
  let iCaretPos = 0

  // IE Support
  if (document.selection) {
    // Set focus on the element
    oField.focus()

    // To get cursor position, get empty selection range
    let oSel = document.selection.createRange()

    // Move selection start to 0 position
    oSel.moveStart('character', -oField.value.length)

    // The caret position is selection length
    iCaretPos = oSel.text.length
  }

  // Firefox support
  else if (oField.selectionStart || oField.selectionStart === '0')
    iCaretPos =
      oField.selectionDirection === 'backward'
        ? oField.selectionStart
        : oField.selectionEnd

  // Return results
  return iCaretPos
}*/

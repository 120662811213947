import { call, put, all, takeLatest } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import clone from 'lodash/clone';
import {
  getClusterStatus,
  loadCostData,
  loadCostCachedData,
  loadChargebackData,
  createBudgetData,
  updateBudgetData,
  deleteBudgetData,
  getMRCData,
  getOpUtData,
} from 'api/cost';
import {
  LOAD_COST_SUMMARY_REQUEST,
  LOAD_FILTER_DATA_REQUEST,
  LOAD_COST_DETAIL_REQUEST,
  LOAD_COST_TAGS_REQUEST,
  LOAD_COST_CHARGEBACK_REQUEST,
  CREATE_COST_CHARGEBACK_REQUEST,
  UPDATE_COST_CHARGEBACK_REQUEST,
  DELETE_COST_CHARGEBACK_REQUEST,
  LOAD_COST_CM_REQUEST,
  LOAD_ALL_COST_DATA_REQUEST,
  LOAD_MRC_COST_DATA_REQUEST,
  LOAD_DEFAULT_FILTER_DATA_REQUEST,
  LOAD_OPERATION_REQUEST,
  LOAD_USAGE_TYPE_REQUEST,
  LOAD_CLUSTER_STATUS_REQUEST,
} from './constants';
import {
  loadCostSummarySuccess,
  loadCostSummaryCreditsSuccess,
  loadCostSummaryError,
  loadFilterDataSuccess,
  loadFilterDataError,
  loadDefaultFilterDataSuccess,
  loadDefaultFilterDataError,
  loadCostDetailSuccess,
  loadCostDetailError,
  loadCostTagsSuccess,
  loadCostTagsError,
  loadCostChargebackSuccess,
  loadCostChargebackError,
  createCostChargebackSuccess,
  createCostChargebackError,
  updateCostChargebackSuccess,
  updateCostChargebackError,
  deleteCostChargebackSuccess,
  deleteCostChargebackError,
  loadCostCMSuccess,
  loadCostCMError,
  loadAllCostDataError,
  loadAllCostDataSuccess,
  loadMRCCostDataSuccess,
  loadMRCCostDataError,
  loadOperationDataSuccess,
  loadOperationDataError,
  loadUsageTypeDataSuccess,
  loadUsageTypeDataError,
  loadClusterStatusSuccess,
  loadClusterStatusError,
} from './actions';
import { showToastAction } from 'containers/App/actions';
import { api_condition_error, api_condition_success, globalShowNotification } from 'containers/App/saga';

export function* getClusterStatusSaga() {
  try {
    const res = yield call(getClusterStatus);

    if (api_condition_success(res.status)) {
      yield put(loadClusterStatusSuccess(res?.data || []));
    } else if (api_condition_error(res.status)) {
      yield call(globalShowNotification, res);
      yield put(loadClusterStatusError());
    }
  } catch (e) {
    yield put(loadClusterStatusError());
    yield put(
      showToastAction({
        message: 'Failed to load Cluster statuses.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* loadMRCData() {
  try {
    const res = yield call(getMRCData);

    if (api_condition_success(res.status)) {
      yield put(loadMRCCostDataSuccess(res?.data || {}));
    } else if (api_condition_error(res.status)) {
      yield call(globalShowNotification, res);
      yield put(loadMRCCostDataError());
    }
  } catch (e) {
    yield put(loadMRCCostDataError());
    yield put(
      showToastAction({
        message: 'Failed to load MRC data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* getCostSummarySaga(action) {
  try {
    const stateData = action.payload?.state;
    const type = action.payload?.type;

    if (!isEmpty(stateData)) {
      delete action.payload.state;
    }

    if (type) {
      delete action.payload.type;
    }

    const apiCall = type === 'cached' ? loadCostCachedData : loadCostData;
    const res = yield call(apiCall, action.payload);

    if (api_condition_success(res.status)) {
      if (action.payload.creditsParams?.mustGetCredits) {
        const newPayload = clone(action.payload);
        //for credits we need a different aggregation also need to set include_credit to all
        newPayload.parameters.aggregations = 'arp_spend_credits_summary';
        newPayload.parameters.include_credit = 'all';
        newPayload.parameters.cost_from = action.payload.creditsParams.cost_from;
        newPayload.parameters.cost_to = action.payload.creditsParams.cost_to;
        delete newPayload.creditsParams;

        const creditsRes = yield call(loadCostData, newPayload);

        if (api_condition_success(creditsRes.status)) {
          yield put(
            loadCostSummaryCreditsSuccess({
              data: creditsRes.data.aggregations,
              filter: stateData,
            }),
          );
        } else if (api_condition_error(creditsRes.status)) {
          yield call(globalShowNotification, creditsRes);
          yield put(loadCostSummaryError());
        }
      }

      yield put(
        loadCostSummarySuccess({
          data: type === 'cached' ? res.data : res.data.aggregations,
          filter: stateData,
        }),
      );
    } else if (api_condition_error(res.status)) {
      yield call(globalShowNotification, res);
      yield put(loadCostSummaryError());
    }
  } catch (err) {
    yield put(loadCostSummaryError());
    yield put(
      showToastAction({
        message: 'Failed to Summary data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* getAllDataSaga(action) {
  try {
    const stateData = action.payload?.state;

    if (!isEmpty(stateData)) {
      delete action.payload.state;
    }

    const base = action.payload.parameters;

    const data = {
      data: {
        aggregations: {},
      },
    };
    const aggregations = base.aggregations.split(':');

    const res = yield all(
      (aggregations || []).map(row =>
        call(loadCostData, {
          parameters: {
            ...base,
            aggregations: row,
          },
        }),
      ),
    );

    res.forEach(row => {
      if (api_condition_success(row.status)) {
        forEach(row.data.aggregations, (value, key) => {
          data.data.aggregations[key] = value;
        });
      }
    });
    yield put(
      loadAllCostDataSuccess({
        data,
        tab: action.payload.tab,
        filter: stateData,
      }),
    );
  } catch (err) {
    yield put(loadAllCostDataError());
    yield put(
      showToastAction({
        message: 'Failed to load ARP data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* getFilterSaga(action) {
  try {
    const res = yield call(loadCostData, action.payload);

    if (api_condition_success(res.status)) {
      yield put(loadFilterDataSuccess(res));
    } else if (api_condition_error(res.status)) {
      yield call(globalShowNotification, res);
      yield put(loadFilterDataError());
    }
  } catch (err) {
    yield put(loadFilterDataError());
    yield put(
      showToastAction({
        message: 'Failed to Filter data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* getDefaultFilterSaga(action) {
  try {
    const defaultFilter = yield call(loadCostData, action.payload);

    if (api_condition_success(defaultFilter.status)) {
      yield put(
        loadDefaultFilterDataSuccess({
          ...(defaultFilter?.data?.aggregations || {}),
        }),
      );
    } else if (api_condition_error(defaultFilter.status)) {
      yield call(globalShowNotification, defaultFilter);
      yield put(loadDefaultFilterDataError());
    }
  } catch (err) {
    yield put(loadDefaultFilterDataError());
  }
}

export function* getOperationFilterSaga(action) {
  try {
    const search = action?.payload || '';
    const configCatAzure = action?.configCatAzure || false;
    const param = `query=${search}&field=operations`;
    const op = yield call(getOpUtData, param, configCatAzure);

    if (api_condition_success(op.status)) {
      yield put(
        loadOperationDataSuccess({
          data: op?.data?.data || [],
          search: search,
        }),
      );
    } else if (api_condition_error(op.status)) {
      // yield call(globalShowNotification, op)
      yield put(loadOperationDataError());
    }
  } catch (err) {
    yield put(loadOperationDataError());
    yield put(
      showToastAction({
        message: 'Failed to load operation data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* getUsageTypeSaga(action) {
  try {
    const search = action?.payload || '';
    const configCatAzure = action?.configCatAzure || false;
    const param = `query=${search}&field=usagetypes`;
    const op = yield call(getOpUtData, param, configCatAzure);

    if (api_condition_success(op.status)) {
      yield put(
        loadUsageTypeDataSuccess({
          data: op?.data?.data || [],
          search: search,
        }),
      );
    } else if (api_condition_error(op.status)) {
      // yield call(globalShowNotification, op)
      yield put(loadUsageTypeDataError());
    }
  } catch (err) {
    yield put(loadUsageTypeDataError());
    yield put(
      showToastAction({
        message: 'Failed to load usagetype data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* getCostDetailSaga(action) {
  try {
    const payload = { ...action.payload };
    const type = payload.type;
    const apiCall = type === 'cached' ? loadCostCachedData : loadCostData;
    delete payload.type;

    const res = yield call(apiCall, payload);
    delete payload.parameters.cost_forecast;
    delete payload.parameters.cost_from;
    delete payload.parameters.cost_to;
    payload.parameters.aggregations = action.payload.configCatAzure
      ? 'arp_spend_summary_billing_type'
      : 'arp_spend_summary';
    action.payload.configCatAzure && (payload.parameters.cloud_type = 'all');

    const res1 = yield call(apiCall, payload);

    if (api_condition_success(res.status) && api_condition_success(res1.status)) {
      const data = type === 'cached' ? res.data : res.data.aggregations;
      const data1 = type === 'cached' ? res1.data : res1.data.aggregations;
      yield put(
        loadCostDetailSuccess({
          data: {
            aggregations: {
              ...data,
              ...data1,
            },
          },
        }),
      );
    } else {
      if (api_condition_error(res.status)) {
        yield call(globalShowNotification, res);
      } else if (api_condition_error(res1.status)) {
        yield call(globalShowNotification, res1);
      }

      yield put(loadCostDetailError());
    }
  } catch (err) {
    yield put(loadCostDetailError());
    yield put(
      showToastAction({
        message: 'Failed to load cost detail data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* getCostTagsSaga(action) {
  try {
    const filters = action.payload.filters;

    if (!isEmpty(filters)) {
      delete action.payload.filters;
    }

    const payload = action.payload.state;

    if (!isEmpty(payload)) {
      delete action.payload.state;
    }

    const res = yield call(loadCostData, action.payload);

    if (api_condition_success(res.status)) {
      yield put(loadCostTagsSuccess({ res, filters, payload }));
    } else if (api_condition_error(res.status)) {
      yield call(globalShowNotification, res);
      yield put(loadCostTagsError());
    }
  } catch (err) {
    yield put(loadCostTagsError());
  }
}

export function* getCostCMData(action) {
  const whatAgg = action.payload?.configCatAzure ? '_billing_type' : '';

  try {
    const { param, dates, tab, filters } = action.payload;
    let res, res1, res2, status, result;
    const isTrue = param?.parameters?.filters;
    const apiCall = isTrue ? loadCostData : loadCostCachedData;

    switch (tab) {
      case 'Daily':
        param.parameters.aggregations = `arp${whatAgg}_cm_daily`;
        param.parameters.cost_from = dates.daily_start_date;
        action.payload?.configCatAzure && (param.parameters.cloud_type = 'all');
        res = yield call(apiCall, param);
        status = res.status;
        result = res;
        res = isTrue ? res?.data?.aggregations || {} : res?.data || {};

        break;
      case 'Weekly':
        param.parameters.aggregations = `arp${whatAgg}_cm_7d`;
        param.parameters.cost_from = dates.weekly_start_date;
        action.payload?.configCatAzure && (param.parameters.cloud_type = 'all');
        res = yield call(apiCall, param);
        status = res.status;
        result = res;
        res = isTrue ? res?.data?.aggregations || {} : res?.data || {};

        break;
      case 'Monthly':
        param.parameters.aggregations = `arp${whatAgg}_cm_mtd`;
        param.parameters.cost_from = dates.mtd_start_date;
        action.payload?.configCatAzure && (param.parameters.cloud_type = 'all');
        res1 = yield call(apiCall, param);
        param.parameters.aggregations = `arp${whatAgg}_cm_monthly`;
        param.parameters.cost_from = dates.monthly_start_date;
        action.payload?.configCatAzure && (param.parameters.cloud_type = 'all');
        res2 = yield call(apiCall, param);
        res = {
          mtd: isTrue ? res1?.data?.aggregations || {} : res1?.data || {},
          monthly: isTrue ? res2?.data?.aggregations || {} : res2?.data || {},
        };
        status = res1.status || res2.status;
        result = api_condition_error(res1.status) ? res1 : api_condition_error(res2.status) ? res2 : {};

        break;
      default:
        break;
    }

    if (api_condition_success(status)) {
      yield put(
        loadCostCMSuccess({
          data: {
            data: res,
            filters: filters,
          },
          tab: tab,
        }),
      );
    } else {
      if (api_condition_error(status)) {
        yield call(globalShowNotification, result);
      }

      yield put(loadCostCMError());
    }
  } catch (err) {
    yield put(loadCostCMError());
    yield put(
      showToastAction({
        message: 'Failed to load change management data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* getChargebackData() {
  try {
    const res = yield call(loadChargebackData);

    if (api_condition_success(res.status)) {
      yield put(loadCostChargebackSuccess(res.data));
    } else if (api_condition_error(res.status)) {
      yield call(globalShowNotification, res);
      yield put(loadCostChargebackError());
    }
  } catch (err) {
    yield put(loadCostChargebackError());
    yield put(
      showToastAction({
        message: 'Failed to load chargeback data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* createChargeback(action) {
  try {
    const res = yield call(createBudgetData, action.payload);

    if (api_condition_success(res.status)) {
      yield put(createCostChargebackSuccess(res.data));
      yield put(
        showToastAction({
          message: 'Chargeback created successfully',
          type: 'success',
          show: true,
        }),
      );
    } else if (api_condition_error(res.status)) {
      yield call(globalShowNotification, res);
      yield put(createCostChargebackError());
    }
  } catch (err) {
    yield put(createCostChargebackError());
    yield put(
      showToastAction({
        message: 'Failed to create a chargeback',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* updateChargeback(action) {
  try {
    const res = yield call(updateBudgetData, action.payload);

    if (api_condition_success(res.status)) {
      yield put(updateCostChargebackSuccess({ data: res.data, id: action.payload.id }));
      yield put(
        showToastAction({
          message: 'Chargeback updated successfully',
          type: 'success',
          show: true,
        }),
      );
    } else if (api_condition_error(res.status)) {
      yield call(globalShowNotification, res);
      yield put(updateCostChargebackError());
    }
  } catch (err) {
    yield put(updateCostChargebackError());
    yield put(
      showToastAction({
        message: 'Failed to update chargeback.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* deleteChargeback(action) {
  try {
    const res = yield call(deleteBudgetData, action.payload);

    if (api_condition_success(res.status)) {
      yield put(
        showToastAction({
          message: 'Chargeback deleted successfully',
          type: 'success',
          show: true,
        }),
      );
      yield put(deleteCostChargebackSuccess(action.payload));
      action.callback && action.callback();
    } else if (api_condition_error(res.status)) {
      yield put(deleteCostChargebackError());
      yield call(globalShowNotification, res);
    }
  } catch (err) {
    yield put(
      showToastAction({
        message: 'Failed to delete a chargeback',
        type: 'error',
        show: true,
      }),
    );
    yield put(deleteCostChargebackError());
  }
}

export default function* costData() {
  yield takeLatest(LOAD_CLUSTER_STATUS_REQUEST, getClusterStatusSaga);
  yield takeLatest(LOAD_COST_SUMMARY_REQUEST, getCostSummarySaga);
  yield takeLatest(LOAD_ALL_COST_DATA_REQUEST, getAllDataSaga);
  yield takeLatest(LOAD_COST_DETAIL_REQUEST, getCostDetailSaga);
  yield takeLatest(LOAD_FILTER_DATA_REQUEST, getFilterSaga);
  yield takeLatest(LOAD_DEFAULT_FILTER_DATA_REQUEST, getDefaultFilterSaga);
  yield takeLatest(LOAD_COST_TAGS_REQUEST, getCostTagsSaga);
  yield takeLatest(LOAD_COST_CHARGEBACK_REQUEST, getChargebackData);
  yield takeLatest(CREATE_COST_CHARGEBACK_REQUEST, createChargeback);
  yield takeLatest(UPDATE_COST_CHARGEBACK_REQUEST, updateChargeback);
  yield takeLatest(DELETE_COST_CHARGEBACK_REQUEST, deleteChargeback);
  yield takeLatest(LOAD_COST_CM_REQUEST, getCostCMData);
  yield takeLatest(LOAD_MRC_COST_DATA_REQUEST, loadMRCData);
  yield takeLatest(LOAD_OPERATION_REQUEST, getOperationFilterSaga);
  yield takeLatest(LOAD_USAGE_TYPE_REQUEST, getUsageTypeSaga);
}

import moment from 'moment-timezone';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';

export const TIMEZONE_ROWS = [
  {
    id: 1,
    continent: 'Asia',
    rows: [
      { id: 1, country: 'Dubai', tz: 'Asia/Dubai' },
      { id: 2, country: 'Kabul', tz: 'Asia/Kabul' },
      { id: 3, country: 'Yerevan', tz: 'Asia/Yerevan' },
      { id: 4, country: 'Baku', tz: 'Asia/Baku' },
      { id: 5, country: 'Dhaka', tz: 'Asia/Dhaka' },
      { id: 6, country: 'Brunei', tz: 'Asia/Brunei' },
      { id: 7, country: 'Thimphu', tz: 'Asia/Thimphu' },
      { id: 8, country: 'Shanghai', tz: 'Asia/Shanghai' },
      { id: 9, country: 'Urumqi', tz: 'Asia/Urumqi' },
      { id: 10, country: 'Nicosia', tz: 'Asia/Nicosia' },
      { id: 11, country: 'Famagusta', tz: 'Asia/Famagusta' },
      { id: 12, country: 'Tbilisi', tz: 'Asia/Tbilisi' },
      { id: 13, country: 'Hong_Kong', tz: 'Asia/Hong_Kong' },
      { id: 14, country: 'Jakarta', tz: 'Asia/Jakarta' },
      { id: 15, country: 'Pontianak', tz: 'Asia/Pontianak' },
      { id: 16, country: 'Makassar', tz: 'Asia/Makassar' },
      { id: 17, country: 'Jayapura', tz: 'Asia/Jayapura' },
      { id: 18, country: 'Jerusalem', tz: 'Asia/Jerusalem' },
      { id: 19, country: 'Kolkata', tz: 'Asia/Kolkata' },
      { id: 20, country: 'Baghdad', tz: 'Asia/Baghdad' },
      { id: 21, country: 'Tehran', tz: 'Asia/Tehran' },
      { id: 22, country: 'Amman', tz: 'Asia/Amman' },
      { id: 23, country: 'Tokyo', tz: 'Asia/Tokyo' },
      { id: 24, country: 'Bishkek', tz: 'Asia/Bishkek' },
      { id: 25, country: 'Pyongyang', tz: 'Asia/Pyongyang' },
      { id: 26, country: 'Seoul', tz: 'Asia/Seoul' },
      { id: 27, country: 'Almaty', tz: 'Asia/Almaty' },
      { id: 28, country: 'Qyzylorda', tz: 'Asia/Qyzylorda' },
      { id: 29, country: 'Qostanay', tz: 'Asia/Qostanay' },
      { id: 30, country: 'Aqtobe', tz: 'Asia/Aqtobe' },
      { id: 31, country: 'Aqtau', tz: 'Asia/Aqtau' },
      { id: 32, country: 'Atyrau', tz: 'Asia/Atyrau' },
      { id: 33, country: 'Oral', tz: 'Asia/Oral' },
      { id: 34, country: 'Beirut', tz: 'Asia/Beirut' },
      { id: 35, country: 'Colombo', tz: 'Asia/Colombo' },
      { id: 36, country: 'Yangon', tz: 'Asia/Yangon' },
      { id: 37, country: 'Ulaanbaatar', tz: 'Asia/Ulaanbaatar' },
      { id: 38, country: 'Hovd', tz: 'Asia/Hovd' },
      { id: 39, country: 'Choibalsan', tz: 'Asia/Choibalsan' },
      { id: 40, country: 'Macau', tz: 'Asia/Macau' },
      { id: 41, country: 'Kuala_Lumpur', tz: 'Asia/Kuala_Lumpur' },
      { id: 42, country: 'Kuching', tz: 'Asia/Kuching' },
      { id: 43, country: 'Karachi', tz: 'Asia/Karachi' },
      { id: 44, country: 'Gaza', tz: 'Asia/Gaza' },
      { id: 45, country: 'Hebron', tz: 'Asia/Hebron' },
      { id: 46, country: 'Kathmandu', tz: 'Asia/Kathmandu' },
      { id: 47, country: 'Yekaterinburg', tz: 'Asia/Yekaterinburg' },
      { id: 48, country: 'Qatar', tz: 'Asia/Qatar' },
      { id: 49, country: 'Omsk', tz: 'Asia/Omsk' },
      { id: 50, country: 'Novosibirsk', tz: 'Asia/Novosibirsk' },
      { id: 51, country: 'Barnaul', tz: 'Asia/Barnaul' },
      { id: 52, country: 'Tomsk', tz: 'Asia/Tomsk' },
      { id: 53, country: 'Novokuznetsk', tz: 'Asia/Novokuznetsk' },
      { id: 54, country: 'Krasnoyarsk', tz: 'Asia/Krasnoyarsk' },
      { id: 55, country: 'Irkutsk', tz: 'Asia/Irkutsk' },
      { id: 56, country: 'Chita', tz: 'Asia/Chita' },
      { id: 57, country: 'Yakutsk', tz: 'Asia/Yakutsk' },
      { id: 58, country: 'Khandyga', tz: 'Asia/Khandyga' },
      { id: 59, country: 'Vladivostok', tz: 'Asia/Vladivostok' },
      { id: 60, country: 'Ust-Nera', tz: 'Asia/Ust-Nera' },
      { id: 61, country: 'Singapore', tz: 'Asia/Singapore' },
      { id: 62, country: 'Magadan', tz: 'Asia/Magadan' },
      { id: 63, country: 'Sakhalin', tz: 'Asia/Sakhalin' },
      { id: 64, country: 'Srednekolymsk', tz: 'Asia/Srednekolymsk' },
      { id: 65, country: 'Kamchatka', tz: 'Asia/Kamchatka' },
      { id: 66, country: 'Anadyr', tz: 'Asia/Anadyr' },
      { id: 67, country: 'Bangkok', tz: 'Asia/Bangkok' },
      { id: 68, country: 'Dushanbe', tz: 'Asia/Dushanbe' },
      { id: 69, country: 'Taipei', tz: 'Asia/Taipei' },
      { id: 70, country: 'Dili', tz: 'Asia/Dili' },
      { id: 71, country: 'Ashgabat', tz: 'Asia/Ashgabat' },
      { id: 72, country: 'Damascus', tz: 'Asia/Damascus' },
      { id: 73, country: 'Riyadh', tz: 'Asia/Riyadh' },
      { id: 74, country: 'Samarkand', tz: 'Asia/Samarkand' },
      { id: 75, country: 'Tashkent', tz: 'Asia/Tashkent' },
      { id: 76, country: 'Ho_Chi_Minh', tz: 'Asia/Ho_Chi_Minh' },
    ],
  },
  {
    id: 2,
    continent: 'Europe',
    rows: [
      { id: 1, country: 'Andorra', tz: 'Europe/Andorra' },
      { id: 2, country: 'Tirane', tz: 'Europe/Tirane' },
      { id: 3, country: 'Vienna', tz: 'Europe/Vienna' },
      { id: 4, country: 'Brussels', tz: 'Europe/Brussels' },
      { id: 5, country: 'Sofia', tz: 'Europe/Sofia' },
      { id: 6, country: 'Minsk', tz: 'Europe/Minsk' },
      { id: 7, country: 'Zurich', tz: 'Europe/Zurich' },
      { id: 8, country: 'Prague', tz: 'Europe/Prague' },
      { id: 9, country: 'Berlin', tz: 'Europe/Berlin' },
      { id: 10, country: 'Copenhagen', tz: 'Europe/Copenhagen' },
      { id: 11, country: 'Tallinn', tz: 'Europe/Tallinn' },
      { id: 12, country: 'Madrid', tz: 'Europe/Madrid' },
      { id: 13, country: 'Helsinki', tz: 'Europe/Helsinki' },
      { id: 14, country: 'Paris', tz: 'Europe/Paris' },
      { id: 15, country: 'London', tz: 'Europe/London' },
      { id: 16, country: 'Gibraltar', tz: 'Europe/Gibraltar' },
      { id: 17, country: 'Athens', tz: 'Europe/Athens' },
      { id: 18, country: 'Budapest', tz: 'Europe/Budapest' },
      { id: 19, country: 'Dublin', tz: 'Europe/Dublin' },
      { id: 20, country: 'Rome', tz: 'Europe/Rome' },
      { id: 21, country: 'Vilnius', tz: 'Europe/Vilnius' },
      { id: 22, country: 'Luxembourg', tz: 'Europe/Luxembourg' },
      { id: 23, country: 'Riga', tz: 'Europe/Riga' },
      { id: 24, country: 'Monaco', tz: 'Europe/Monaco' },
      { id: 25, country: 'Chisinau', tz: 'Europe/Chisinau' },
      { id: 26, country: 'Malta', tz: 'Europe/Malta' },
      { id: 27, country: 'Amsterdam', tz: 'Europe/Amsterdam' },
      { id: 28, country: 'Oslo', tz: 'Europe/Oslo' },
      { id: 29, country: 'Warsaw', tz: 'Europe/Warsaw' },
      { id: 31, country: 'Lisbon', tz: 'Europe/Lisbon' },
      { id: 32, country: 'Bucharest', tz: 'Europe/Bucharest' },
      { id: 33, country: 'Belgrade', tz: 'Europe/Belgrade' },
      { id: 34, country: 'Kaliningrad', tz: 'Europe/Kaliningrad' },
      { id: 35, country: 'Moscow', tz: 'Europe/Moscow' },
      { id: 36, country: 'Simferopol', tz: 'Europe/Simferopol' },
      { id: 37, country: 'Kirov', tz: 'Europe/Kirov' },
      { id: 38, country: 'Astrakhan', tz: 'Europe/Astrakhan' },
      { id: 39, country: 'Volgograd', tz: 'Europe/Volgograd' },
      { id: 40, country: 'Saratov', tz: 'Europe/Saratov' },
      { id: 41, country: 'Ulyanovsk', tz: 'Europe/Ulyanovsk' },
      { id: 42, country: 'Samara', tz: 'Europe/Samara' },
      { id: 43, country: 'Stockholm', tz: 'Europe/Stockholm' },
      { id: 44, country: 'Istanbul', tz: 'Europe/Istanbul' },
      { id: 45, country: 'Kiev', tz: 'Europe/Kyiv' },
      { id: 46, country: 'Uzhgorod', tz: 'Europe/Uzhgorod' },
      { id: 47, country: 'Zaporozhye', tz: 'Europe/Zaporozhye' },
    ],
  },
  {
    id: 3,
    continent: 'Antarctica',
    rows: [
      { id: 1, country: 'Casey', tz: 'Antarctica/Casey' },
      { id: 2, country: 'Davis', tz: 'Antarctica/Davis' },
      { id: 3, country: 'DumontDUrville', tz: 'Antarctica/DumontDUrville' },
      { id: 4, country: 'Mawson', tz: 'Antarctica/Mawson' },
      { id: 5, country: 'Palmer', tz: 'Antarctica/Palmer' },
      { id: 6, country: 'Rothera', tz: 'Antarctica/Rothera' },
      { id: 7, country: 'Syowa', tz: 'Antarctica/Syowa' },
      { id: 8, country: 'Troll', tz: 'Antarctica/Troll' },
      { id: 9, country: 'Vostok', tz: 'Antarctica/Vostok' },
      { id: 10, country: 'Macquarie', tz: 'Antarctica/Macquarie' },
    ],
  },
  {
    id: 4,
    continent: 'America',
    rows: [
      { id: 1, country: 'Buenos_Aires', tz: 'America/Argentina/Buenos_Aires' },
      { id: 2, country: 'Cordoba', tz: 'America/Argentina/Cordoba' },
      { id: 3, country: 'Salta', tz: 'America/Argentina/Salta' },
      { id: 4, country: 'Jujuy', tz: 'America/Argentina/Jujuy' },
      { id: 5, country: 'Tucuman', tz: 'America/Argentina/Tucuman' },
      { id: 6, country: 'Catamarca', tz: 'America/Argentina/Catamarca' },
      { id: 7, country: 'La_Rioja', tz: 'America/Argentina/La_Rioja' },
      { id: 8, country: 'San_Juan', tz: 'America/Argentina/San_Juan' },
      { id: 9, country: 'Mendoza', tz: 'America/Argentina/Mendoza' },
      { id: 10, country: 'San_Luis', tz: 'America/Argentina/San_Luis' },
      { id: 11, country: 'Rio_Gallegos', tz: 'America/Argentina/Rio_Gallegos' },
      { id: 12, country: 'Ushuaia', tz: 'America/Argentina/Ushuaia' },
      { id: 13, country: 'Barbados', tz: 'America/Barbados' },
      { id: 14, country: 'La_Paz', tz: 'America/La_Paz' },
      { id: 15, country: 'Belem', tz: 'America/Belem' },
      { id: 16, country: 'Fortaleza', tz: 'America/Fortaleza' },
      { id: 17, country: 'Recife', tz: 'America/Recife' },
      { id: 18, country: 'Araguaina', tz: 'America/Araguaina' },
      { id: 19, country: 'Maceio', tz: 'America/Maceio' },
      { id: 20, country: 'Bahia', tz: 'America/Bahia' },
      { id: 21, country: 'Sao_Paulo', tz: 'America/Sao_Paulo' },
      { id: 22, country: 'Campo_Grande', tz: 'America/Campo_Grande' },
      { id: 23, country: 'Cuiaba', tz: 'America/Cuiaba' },
      { id: 24, country: 'Porto_Velho', tz: 'America/Porto_Velho' },
      { id: 25, country: 'Boa_Vista', tz: 'America/Boa_Vista' },
      { id: 26, country: 'Manaus', tz: 'America/Manaus' },
      { id: 27, country: 'Eirunepe', tz: 'America/Eirunepe' },
      { id: 28, country: 'Rio_Branco', tz: 'America/Rio_Branco' },
      { id: 29, country: 'Nassau', tz: 'America/Nassau' },
      { id: 30, country: 'Belize', tz: 'America/Belize' },
      { id: 31, country: 'St_Johns', tz: 'America/St_Johns' },
      { id: 32, country: 'Halifax', tz: 'America/Halifax' },
      { id: 33, country: 'Glace_Bay', tz: 'America/Glace_Bay' },
      { id: 34, country: 'Moncton', tz: 'America/Moncton' },
      { id: 35, country: 'Goose_Bay', tz: 'America/Goose_Bay' },
      { id: 36, country: 'Blanc-Sablon', tz: 'America/Blanc-Sablon' },
      { id: 37, country: 'Toronto', tz: 'America/Toronto' },
      { id: 38, country: 'Nipigon', tz: 'America/Nipigon' },
      { id: 39, country: 'Thunder_Bay', tz: 'America/Thunder_Bay' },
      { id: 40, country: 'Iqaluit', tz: 'America/Iqaluit' },
      { id: 41, country: 'Pangnirtung', tz: 'America/Pangnirtung' },
      { id: 42, country: 'Atikokan', tz: 'America/Atikokan' },
      { id: 43, country: 'Winnipeg', tz: 'America/Winnipeg' },
      { id: 44, country: 'Rainy_River', tz: 'America/Rainy_River' },
      { id: 45, country: 'Resolute', tz: 'America/Resolute' },
      { id: 46, country: 'Rankin_Inlet', tz: 'America/Rankin_Inlet' },
      { id: 47, country: 'Regina', tz: 'America/Regina' },
      { id: 48, country: 'Swift_Current', tz: 'America/Swift_Current' },
      { id: 49, country: 'Edmonton', tz: 'America/Edmonton' },
      { id: 50, country: 'Cambridge_Bay', tz: 'America/Cambridge_Bay' },
      { id: 51, country: 'Yellowknife', tz: 'America/Yellowknife' },
      { id: 52, country: 'Inuvik', tz: 'America/Inuvik' },
      { id: 53, country: 'Creston', tz: 'America/Creston' },
      { id: 54, country: 'Dawson_Creek', tz: 'America/Dawson_Creek' },
      { id: 55, country: 'Fort_Nelson', tz: 'America/Fort_Nelson' },
      { id: 56, country: 'Vancouver', tz: 'America/Vancouver' },
      { id: 57, country: 'Whitehorse', tz: 'America/Whitehorse' },
      { id: 58, country: 'Dawson', tz: 'America/Dawson' },
      { id: 59, country: 'Santiago', tz: 'America/Santiago' },
      { id: 60, country: 'Punta_Arenas', tz: 'America/Punta_Arenas' },
      { id: 61, country: 'Bogota', tz: 'America/Bogota' },
      { id: 62, country: 'Costa_Rica', tz: 'America/Costa_Rica' },
      { id: 63, country: 'Havana', tz: 'America/Havana' },
      { id: 64, country: 'Curacao', tz: 'America/Curacao' },
      { id: 65, country: 'Santo_Domingo', tz: 'America/Santo_Domingo' },
      { id: 66, country: 'Guayaquil', tz: 'America/Guayaquil' },
      { id: 67, country: 'Cayenne', tz: 'America/Cayenne' },
      { id: 68, country: 'Godthab', tz: 'America/Godthab' },
      { id: 69, country: 'Danmarkshavn', tz: 'America/Danmarkshavn' },
      { id: 70, country: 'Scoresbysund', tz: 'America/Scoresbysund' },
      { id: 73, country: 'Danmarkshavn', tz: 'America/Danmarkshavn' },
      { id: 75, country: 'Thule', tz: 'America/Thule' },
      { id: 76, country: 'Guatemala', tz: 'America/Guatemala' },
      { id: 77, country: 'Guyana', tz: 'America/Guyana' },
      { id: 78, country: 'Tegucigalpa', tz: 'America/Tegucigalpa' },
      { id: 79, country: 'Port-au-Prince', tz: 'America/Port-au-Prince' },
      { id: 80, country: 'Jamaica', tz: 'America/Jamaica' },
      { id: 81, country: 'Martinique', tz: 'America/Martinique' },
      { id: 82, country: 'Mexico_City', tz: 'America/Mexico_City' },
      { id: 83, country: 'Cancun', tz: 'America/Cancun' },
      { id: 84, country: 'Merida', tz: 'America/Merida' },
      { id: 85, country: 'Monterrey', tz: 'America/Monterrey' },
      { id: 86, country: 'Matamoros', tz: 'America/Matamoros' },
      { id: 87, country: 'Caracas', tz: 'America/Caracas' },
      { id: 88, country: 'Mazatlan', tz: 'America/Mazatlan' },
      { id: 89, country: 'Chihuahua', tz: 'America/Chihuahua' },
      { id: 90, country: 'Ojinaga', tz: 'America/Ojinaga' },
      { id: 91, country: 'Hermosillo', tz: 'America/Hermosillo' },
      { id: 92, country: 'Tijuana', tz: 'America/Tijuana' },
      { id: 93, country: 'Bahia_Banderas', tz: 'America/Bahia_Banderas' },
      { id: 94, country: 'Managua', tz: 'America/Managua' },
      { id: 95, country: 'Panama', tz: 'America/Panama' },
      { id: 96, country: 'Lima', tz: 'America/Lima' },
      { id: 97, country: 'Miquelon', tz: 'America/Miquelon' },
      { id: 98, country: 'Puerto_Rico', tz: 'America/Puerto_Rico' },
      { id: 99, country: 'El_Salvador', tz: 'America/El_Salvador' },
      { id: 100, country: 'Grand_Turk', tz: 'America/Grand_Turk' },
      { id: 101, country: 'Paramaribo', tz: 'America/Paramaribo' },
      { id: 102, country: 'Asuncion', tz: 'America/Asuncion' },
      { id: 103, country: 'Port_of_Spain', tz: 'America/Port_of_Spain' },
      { id: 104, country: 'New_York', tz: 'America/New_York' },
      { id: 105, country: 'Detroit', tz: 'America/Detroit' },
      { id: 106, country: 'Louisville', tz: 'America/Kentucky/Louisville' },
      { id: 107, country: 'Monticello', tz: 'America/Kentucky/Monticello' },
      { id: 108, country: 'Indianapolis', tz: 'America/Indiana/Indianapolis' },
      { id: 109, country: 'Vincennes', tz: 'America/Indiana/Vincennes' },
      { id: 110, country: 'Winamac', tz: 'America/Indiana/Winamac' },
      { id: 111, country: 'Marengo', tz: 'America/Indiana/Marengo' },
      { id: 112, country: 'Petersburg', tz: 'America/Indiana/Petersburg' },
      { id: 113, country: 'Vevay', tz: 'America/Indiana/Vevay' },
      { id: 114, country: 'Tell_City', tz: 'America/Indiana/Tell_City' },
      { id: 115, country: 'Knox', tz: 'America/Indiana/Knox' },
      { id: 116, country: 'Chicago', tz: 'America/Chicago' },
      { id: 117, country: 'Menominee', tz: 'America/Menominee' },
      { id: 118, country: 'Denver', tz: 'America/Denver' },
      { id: 119, country: 'Boise', tz: 'America/Boise' },
      { id: 120, country: 'Phoenix', tz: 'America/Phoenix' },
      { id: 121, country: 'Center', tz: 'America/North_Dakota/Center' },
      { id: 122, country: 'New_Salem', tz: 'America/North_Dakota/New_Salem' },
      { id: 123, country: 'Beulah', tz: 'America/North_Dakota/Beulah' },
      { id: 124, country: 'Los_Angeles', tz: 'America/Los_Angeles' },
      { id: 125, country: 'Anchorage', tz: 'America/Anchorage' },
      { id: 126, country: 'Juneau', tz: 'America/Juneau' },
      { id: 127, country: 'Sitka', tz: 'America/Sitka' },
      { id: 128, country: 'Metlakatla', tz: 'America/Metlakatla' },
      { id: 129, country: 'Yakutat', tz: 'America/Yakutat' },
      { id: 130, country: 'Nome', tz: 'America/Nome' },
      { id: 131, country: 'Adak', tz: 'America/Adak' },
      { id: 132, country: 'Montevideo', tz: 'America/Montevideo' },
    ],
  },
  {
    id: 5,
    continent: 'Pacific',
    rows: [
      { id: 1, country: 'Pago_Pago', tz: 'Pacific/Pago_Pago' },
      { id: 2, country: 'Rarotonga', tz: 'Pacific/Rarotonga' },
      { id: 3, country: 'Easter', tz: 'Pacific/Easter' },
      { id: 4, country: 'Galapagos', tz: 'Pacific/Galapagos' },
      { id: 5, country: 'Fiji', tz: 'Pacific/Fiji' },
      { id: 6, country: 'Chuuk', tz: 'Pacific/Chuuk' },
      { id: 7, country: 'Pohnpei', tz: 'Pacific/Pohnpei' },
      { id: 8, country: 'Kosrae', tz: 'Pacific/Kosrae' },
      { id: 9, country: 'Guam', tz: 'Pacific/Guam' },
      { id: 10, country: 'Majuro', tz: 'Pacific/Majuro' },
      { id: 11, country: 'Kwajalein', tz: 'Pacific/Kwajalein' },
      { id: 12, country: 'Tarawa', tz: 'Pacific/Tarawa' },
      { id: 13, country: 'Enderbury', tz: 'Pacific/Enderbury' },
      { id: 14, country: 'Kiritimati', tz: 'Pacific/Kiritimati' },
      { id: 15, country: 'Noumea', tz: 'Pacific/Noumea' },
      { id: 16, country: 'Norfolk', tz: 'Pacific/Norfolk' },
      { id: 17, country: 'Nauru', tz: 'Pacific/Nauru' },
      { id: 18, country: 'Niue', tz: 'Pacific/Niue' },
      { id: 19, country: 'Auckland', tz: 'Pacific/Auckland' },
      { id: 20, country: 'Chatham', tz: 'Pacific/Chatham' },
      { id: 21, country: 'Tahiti', tz: 'Pacific/Tahiti' },
      { id: 22, country: 'Marquesas', tz: 'Pacific/Marquesas' },
      { id: 23, country: 'Gambier', tz: 'Pacific/Gambier' },
      { id: 24, country: 'Port_Moresby', tz: 'Pacific/Port_Moresby' },
      { id: 25, country: 'Bougainville', tz: 'Pacific/Bougainville' },
      { id: 26, country: 'Pitcairn', tz: 'Pacific/Pitcairn' },
      { id: 27, country: 'Palau', tz: 'Pacific/Palau' },
      { id: 28, country: 'Guadalcanal', tz: 'Pacific/Guadalcanal' },
      { id: 29, country: 'Fakaofo', tz: 'Pacific/Fakaofo' },
      { id: 30, country: 'Tongatapu', tz: 'Pacific/Tongatapu' },
      { id: 31, country: 'Funafuti', tz: 'Pacific/Funafuti' },
      { id: 32, country: 'Wake', tz: 'Pacific/Wake' },
      { id: 33, country: 'Honolulu', tz: 'Pacific/Honolulu' },
      { id: 34, country: 'Efate', tz: 'Pacific/Efate' },
      { id: 35, country: 'Wallis', tz: 'Pacific/Wallis' },
      { id: 36, country: 'Apia', tz: 'Pacific/Apia' },
    ],
  },
  {
    id: 6,
    continent: 'Australia',
    rows: [
      { id: 1, country: 'Lord_Howe', tz: 'Australia/Lord_Howe' },
      { id: 2, country: 'Hobart', tz: 'Australia/Hobart' },
      { id: 3, country: 'Currie', tz: 'Australia/Currie' },
      { id: 4, country: 'Melbourne', tz: 'Australia/Melbourne' },
      { id: 5, country: 'Sydney', tz: 'Australia/Sydney' },
      { id: 6, country: 'Broken_Hill', tz: 'Australia/Broken_Hill' },
      { id: 7, country: 'Brisbane', tz: 'Australia/Brisbane' },
      { id: 8, country: 'Lindeman', tz: 'Australia/Lindeman' },
      { id: 9, country: 'Adelaide', tz: 'Australia/Adelaide' },
      { id: 10, country: 'Darwin', tz: 'Australia/Darwin' },
      { id: 11, country: 'Perth', tz: 'Australia/Perth' },
      { id: 12, country: 'Eucla', tz: 'Australia/Eucla' },
    ],
  },
  {
    id: 7,
    continent: 'Africa',
    rows: [
      { id: 1, country: 'Abidjan', tz: 'Africa/Abidjan' },
      { id: 2, country: 'Algiers', tz: 'Africa/Algiers' },
      { id: 3, country: 'Cairo', tz: 'Africa/Cairo' },
      { id: 4, country: 'El_Aaiun', tz: 'Africa/El_Aaiun' },
      { id: 5, country: 'Ceuta', tz: 'Africa/Ceuta' },
      { id: 6, country: 'Accra', tz: 'Africa/Accra' },
      { id: 7, country: 'Bissau', tz: 'Africa/Bissau' },
      { id: 8, country: 'Nairobi', tz: 'Africa/Nairobi' },
      { id: 9, country: 'Monrovia', tz: 'Africa/Monrovia' },
      { id: 10, country: 'Tripoli', tz: 'Africa/Tripoli' },
      { id: 11, country: 'Casablanca', tz: 'Africa/Casablanca' },
      { id: 12, country: 'Maputo', tz: 'Africa/Maputo' },
      { id: 13, country: 'Windhoek', tz: 'Africa/Windhoek' },
      { id: 14, country: 'Lagos', tz: 'Africa/Lagos' },
      { id: 15, country: 'Khartoum', tz: 'Africa/Khartoum' },
      { id: 16, country: 'Juba', tz: 'Africa/Juba' },
      { id: 17, country: 'Sao_Tome', tz: 'Africa/Sao_Tome' },
      { id: 18, country: 'Ndjamena', tz: 'Africa/Ndjamena' },
      { id: 19, country: 'Tunis', tz: 'Africa/Tunis' },
      { id: 20, country: 'Johannesburg', tz: 'Africa/Johannesburg' },
    ],
  },
  {
    id: 8,
    continent: 'Atlantic',
    rows: [
      { id: 1, country: 'Azores', tz: 'Atlantic/Azores' },
      { id: 2, country: 'Bermuda', tz: 'Atlantic/Bermuda' },
      { id: 3, country: 'Madeira', tz: 'Atlantic/Madeira' },
    ],
  },
  {
    id: 9,
    continent: 'Atlantic',
    rows: [
      { id: 1, country: 'Cape_Verde', tz: 'Atlantic/Cape_Verde' },
      { id: 2, country: 'Canary', tz: 'Atlantic/Canary' },
      { id: 3, country: 'Stanley', tz: 'Atlantic/Stanley' },
      { id: 4, country: 'Faroe', tz: 'Atlantic/Faroe' },
      { id: 5, country: 'South_Georgia', tz: 'Atlantic/South_Georgia' },
      { id: 6, country: 'Reykjavik', tz: 'Atlantic/Reykjavik' },
    ],
  },
  {
    id: 10,
    continent: 'Indian',
    rows: [
      { id: 1, country: 'Cocos', tz: 'Indian/Cocos' },
      { id: 2, country: 'Christmas', tz: 'Indian/Christmas' },
      { id: 3, country: 'Chagos', tz: 'Indian/Chagos' },
      { id: 4, country: 'Mauritius', tz: 'Indian/Mauritius' },
      { id: 5, country: 'Maldives', tz: 'Indian/Maldives' },
      { id: 6, country: 'Mahe', tz: 'Indian/Mahe' },
      { id: 7, country: 'Reunion', tz: 'Indian/Reunion' },
      { id: 8, country: 'Kerguelen', tz: 'Indian/Kerguelen' },
    ],
  },
];

export const range = (begin, end) => {
  const tempArr = [];

  if (begin > end) {
    for (let i = begin; i <= 167; i++) {
      tempArr.push(i);
    }

    for (let i = 0; i < end; i++) {
      tempArr.push(i);
    }

    return tempArr;
  }

  for (let i = begin; i < end; i++) {
    tempArr.push(i);
  }

  return tempArr;
};

export const getTimeZoneDiff = (timeZone = 'America/Los_Angeles') => {
  const now = moment().utc();
  const utcOffset = now.utcOffset();
  now.tz(timeZone);

  const offset = now.utcOffset();

  return (offset - utcOffset) / 60;
};

export const TIMEZONES = [
  { value: 'utc', name: 'Coordinated Universal Time (UTC)', diff: 0 },
  ...sortBy(
    TIMEZONE_ROWS.flatMap(item => item.rows)
      .map(({ tz }) => {
        const diff = getTimeZoneDiff(tz);
        const format = `${tz} (UTC${moment.tz(tz).format('Z')})`;

        return { value: tz, name: format, diff };
      })
      .filter(item => item.diff && item.diff % 1 === 0),
    'value',
    'asc',
  ),
];

export const getActionStoppedPositions = (tempActions = [], timeZone) => {
  const orderedActions = orderBy([...tempActions], ['position', 'action'], 'asc');

  if (orderedActions[orderedActions.length - 1]['action'] === 'stop') {
    orderedActions.push(orderedActions.shift());
  }

  return orderedActions.reduce((acc, item, index) => {
    if (orderedActions.length > 1) {
      if (item.action === 'stop') {
        let { position } = item;
        let { position: position1 } = orderedActions[index + 1];

        if (timeZone.value !== 'utc') {
          const diff = getTimeZoneDiff(timeZone.value);
          position += diff;
          position1 += diff;

          if (position < 0) {
            position = 168 + position;
          } else if (position > 167) {
            position = position - 168;
          }

          if (position1 < 0) {
            position1 = 168 + position1;
          } else if (position1 > 167) {
            position1 = position1 - 168;
          }
        }

        const rangeValues = range(position, position1);
        const tempAcc = [...acc, ...rangeValues];

        return tempAcc;
      }

      return acc;
    }

    return acc;
  }, []);
};

import { addMonthFilter, getRegionName, numberFix, setDefaultFilterData } from 'utils/helper';
import { getEIPCostData } from 'api/rule';
import { api_condition_error, api_condition_success } from 'containers/App/saga';
import { loadCMRuleDetailDataRequest, loadRuleDetailData } from '../reducer';

const monthType = month => {
  switch (month) {
    case 'months_1':
      return 'utilization.months.months_1.cpu.cpu_usage';
    case 'months_3':
      return 'utilization.months.months_3.cpu.cpu_usage';
    case 'months_6':
      return 'utilization.months.months_6.cpu.cpu_usage';
    case 'months_12':
      return 'utilization.months.months_12.cpu.cpu_usage';
    default:
      return 'utilization.months.months_1.cpu.cpu_usage';
  }
};

export const loadViolationsData = (
  attribute,
  filters,
  workload,
  payload,
  projects,
  dispatch,
  configCatAzure,
  hideAzure,
) => {
  let queryFilter = {};
  const { rule_key, rule_service, activeResourceSorter, selectedMonthFilter, is_cost } = attribute;
  const asRIFilter = [];

  const newPayload = {
    filters,
    workload,
    tagData: payload?.tagData,
    cit: payload?.cit,
    selectedAutoScaling: payload?.selectedAutoScaling,
  };
  const params = setDefaultFilterData(newPayload);
  const historyParams = setDefaultFilterData(newPayload);

  if (rule_key === 'underutilized_resources') {
    const mType = monthType(selectedMonthFilter);
    queryFilter = { [mType]: { lt: 0, gte: 0 } };

    switch (payload.selectedResourceUtilization) {
      case '0':
        queryFilter[mType].lt = 101;

        break;
      case '1':
        queryFilter[mType].lt = 10;

        break;
      case '2':
        queryFilter[mType].lt = 20;

        break;
      case '3':
        queryFilter[mType].lt = 30;

        break;
      default:
        queryFilter[mType].lt = 101;

        break;
    }

    if (payload?.isASData?.[0]?.checked) {
      asRIFilter.push({
        is_as: [true],
      });
    }

    if (payload?.isRUData?.[0]?.checked) {
      asRIFilter.push({
        is_ri: [true],
      });
    }
  }

  const condition = addMonthFilter(rule_service, rule_key);

  if (condition) {
    params.filters['must'] = [
      ...params.filters['must'],
      ...asRIFilter,
      {
        'nested:violations:violations.violation_type.keyword': [rule_key],
        'nested:violations:violations.violation_subtype.keyword': [selectedMonthFilter],
      },
    ];
    historyParams.filters['must'] = [
      ...historyParams.filters['must'],
      ...asRIFilter,
      {
        'nested:violations_history:violations_history.violation_type.keyword': [rule_key],
        'nested:violations_history:violations_history.violation_subtype.keyword': [selectedMonthFilter],
      },
    ];
  } else {
    params.filters['must'] = [
      ...params.filters['must'],
      ...asRIFilter,
      { 'nested:violations:violations.violation_type.keyword': [rule_key] },
    ];
    historyParams.filters['must'] = [
      ...historyParams.filters['must'],
      ...asRIFilter,
      {
        'nested:violations_history:violations_history.violation_type.keyword': [rule_key],
      },
    ];
  }

  if (rule_service !== 'all') {
    params.filters['must'] = [...params.filters['must'], { type: [rule_service] }];
  }

  const data = {
    rule: params,
    historyRule: historyParams,
    range: queryFilter,
    configCatAzure,
    hideAzure,
  };

  if (rule_key === 'ec2_low_network_utilization') {
    data.customParams = {
      show_active: activeResourceSorter === 'all' ? null : activeResourceSorter === 'active',
    };
  }

  dispatch(
    loadRuleDetailData({
      data,
      rule_key,
      rule_service,
      is_cost,
      projects,
      payload,
      configCatAzure,
    }),
  );
  dispatch(loadCMRuleDetailDataRequest(data));
};

export const getEIPData = async () => {
  let data = { eipCost: 0, eipData: [] };
  await getEIPCostData()
    .then(res => {
      if (api_condition_success(res.status)) {
        const buckets =
          res?.data?.aggregations?.resources?.nested_agg?.unused_eip?.unused_eip_usage_type?.buckets || [];
        let cost = 0;
        buckets.forEach(b => {
          cost += b?.usagetypes_sum?.value || 0;
        });
        data = {
          eipData: buckets,
          eipCost: numberFix(cost),
        };
      } else if (api_condition_error(res.status)) {
        data = { eipCost: 0, eipData: [] };
      }
    })
    .catch(c => {
      data = { eipCost: 0, eipData: [] };
    });

  return data;
};

export const updatedEIPCost = (eipData, regions) => {
  let eipCost = 0;

  if (regions?.length) {
    eipData.forEach(e => {
      let key = e?.key?.split('-')[0] || '';

      if (!key) {
        key = 'USE1';
      }

      const regionKey = getRegionName(key);

      if (regions.includes(regionKey)) {
        eipCost += e?.usagetypes_sum?.value || 0;
      }
    });
  } else {
    eipData.forEach(b => {
      eipCost += b?.usagetypes_sum?.value || 0;
    });
  }

  return numberFix(eipCost);
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loadOverrideHistory } from 'api/search';

export const initialState = {
  overrideHistoryData: [],
  overrideHistoryLoading: false,
};

export const RESOURCE_DETAIL_SLICE = 'RESOURCE_DETAIL_SLICE';

export const loadOverrideHistoryDataRequest = createAsyncThunk(
  `${RESOURCE_DETAIL_SLICE}/loadOverrideHistory`,
  async payload => {
    const response = await loadOverrideHistory(payload.id, payload?.partner_scope_id);

    return response.data;
  },
);

const ruleDetailSlice = createSlice({
  name: RESOURCE_DETAIL_SLICE,
  initialState,
  reducers: {},
  extraReducers: {
    [loadOverrideHistoryDataRequest.pending]: state => {
      state.overrideHistoryLoading = true;
    },
    [loadOverrideHistoryDataRequest.fulfilled]: (state, { payload }) => {
      state.overrideHistoryLoading = false;
      state.overrideHistoryData = payload;
    },
    [loadOverrideHistoryDataRequest.rejected]: state => {
      state.overrideHistoryLoading = false;
    },
  },
});

export default ruleDetailSlice.reducer;

import axiosObj from './config';

export const allPartnerUsers = id => {
  return axiosObj({
    method: 'GET',
    url: `/app_partner/partner_user/?partner_scope_id=${id}`,
  });
};

export const allPartnerUserInvites = id => {
  return axiosObj({
    method: 'GET',
    url: `/app_partner/partner_user_invite/?partner_scope_id=${id}`,
  });
};

export const updatePartnerUsers = (invite, userID, id, payload) => {
  return axiosObj({
    method: 'PATCH',
    url: `/app_partner/partner_user${invite ? '_invite' : ''}/${userID}/?partner_scope_id=${id}`,
    data: payload,
  });
};

export const resendInviteEmail = (id, payload = {}) => {
  return axiosObj({
    method: 'POST',
    url: `/app_partner/partner_user_invite/${id}/resend_invite_email/`,
    data: payload,
  });
};

export const deletePartnerUser = (invite, id) => {
  return axiosObj({
    method: 'DELETE',
    url: `/app_partner/partner_user${invite ? '_invite' : ''}/${id}/`,
  });
};

export const partnerSSOSettings = () => {
  return axiosObj({
    method: 'GET',
    url: `/app_partner/sso_settings/`,
  });
};

export const createOrUpdatePartnerSSOSettings = (payload, id) => {
  let method = 'POST',
    url = '/app_partner/sso_settings';

  if (id) {
    method = 'PATCH';
    url = `${url}/${id}/`;
  }

  return axiosObj({
    method,
    url,
    data: payload,
  });
};

export const billingAccounts = () => {
  return axiosObj({
    method: 'GET',
    url: '/app_partner/consolidated_billing/',
  });
};

export const allClients = () => {
  return axiosObj({
    method: 'GET',
    url: '/app_partner/clients/',
  });
};

export const allFinopsClients = () => {
  return axiosObj({
    method: 'GET',
    url: '/app_partner/clients/exclude_billing_imports/',
  });
};

export const partnerExclusiveClients = () => {
  return axiosObj({
    method: 'GET',
    url: '/app_partner/clients/nops_partner_clients/',
  });
};

export const deletePartnerClient = id => {
  return axiosObj({
    method: 'DELETE',
    url: `/app_partner/clients/${id}/`,
  });
};

export const cancelClientSubscription = id => {
  return axiosObj({
    method: 'POST',
    url: `/app_partner/clients/${id}/change_payment_to_did_not_pay/`,
  });
};

export const manageWeeklyReport = (id, toggleAPI) => {
  return axiosObj({
    method: 'POST',
    url: `/app_partner/clients/${id + toggleAPI}`,
  });
};

export const manageMoveToContract = id => {
  return axiosObj({
    method: 'POST',
    url: `/app_partner/clients/${id}/change_payment_to_contract/`,
  });
};

export const manageClientPaymentStatus = toggleAPI => {
  return axiosObj({
    method: 'POST',
    url: toggleAPI,
  });
};

export const addNewClient = payload => {
  return axiosObj({
    method: 'POST',
    url: `/app_partner/clients/`,
    data: payload,
  });
};

export const updateClientDetail = (id, payload) => {
  return axiosObj({
    method: 'PATCH',
    url: `/app_partner/clients/${id}/`,
    data: payload,
  });
};

export const updateClientName = (id, name) => {
  return axiosObj({
    method: 'PATCH',
    url: `/app_partner/clients/${id}`,
    data: {
      name,
    },
  });
};

export const updateClientAddress = (id, payload) => {
  return axiosObj({
    method: 'PATCH',
    url: `/app_partner/clients/${id}/`,
    data: payload,
  });
};

export const toggleAccount = (id, toggleAPI, payload) => {
  return axiosObj({
    method: 'POST',
    url: `/app_partner/consolidated_billing/${id + toggleAPI}`,
    data: payload || {},
  });
};

export const inviteUser = payload => {
  return axiosObj({
    method: 'POST',
    url: '/app_partner/invite_user/',
    data: payload,
  });
};

export const getSignUpURL = () => {
  return axiosObj({
    method: 'GET',
    url: '/app_partner/invite_user/',
  });
};

export const getAllQuestions = () => {
  return axiosObj({
    method: 'GET',
    url: '/app_partner/war_question_template/',
  });
};

export const addQuestion = payload => {
  return axiosObj({
    method: 'POST',
    url: '/app_partner/war_question_template/',
    data: payload,
  });
};

export const editQuestion = (id, payload) => {
  return axiosObj({
    method: 'PATCH',
    url: `/app_partner/war_question_template/${id}/`,
    data: payload,
  });
};

export const deleteQuestion = id => {
  return axiosObj({
    method: 'DELETE',
    url: `/app_partner/war_question_template/${id}/`,
  });
};

export const getWARData = (clientList, configCatAzure) => {
  const parameters = {
    q: '*',
    from: 0,
    scope: 'partner',
    filters: { 'project.client_id': clientList },
    aggregations: 'violations_counts_per_client:violations_counts_history_per_client',
  };

  if (configCatAzure) {
    parameters.cloud_type = 'all';
  }

  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters,
    },
  });
};

export const getWARChangesData = filters => {
  const parameters = {
    scope: 'partner',
    ...filters,
  };

  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters,
    },
  });
};

export const getPartner = () => {
  return axiosObj({
    method: 'GET',
    url: '/app_partner/partner/',
  });
};

export const updatePartner = (id, payload) => {
  return axiosObj({
    method: 'PATCH',
    url: `/app_partner/partner/${id}/`,
    data: payload,
  });
};

export const spendSummary = (clientList, configCatAzure) => {
  const parameters = {
    q: '*',
    from: 0,
    show_active: null,
    scope: 'partner',
    filters: { 'project.client_id': clientList },
    aggregations: configCatAzure ? 'arp_spend_summary_client_per_billing_type' : 'arp_spend_summary_per_client',
  };
  configCatAzure && (parameters.cloud_type = 'all');

  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters: { ...parameters },
    },
  });
};

export const opportunitiesData = action => {
  const agg = action?.configCatAzure
    ? ['arp_new_operations_in_product_per_project_billing_type', 'arp_new_products_in_region_per_project_billing_type']
    : ['arp_new_operations_in_product_per_project', 'arp_new_products_in_region_per_project'];

  const parameters = {
    q: '*',
    from: 0,
    show_active: null,
    scope: 'partner',
    aggregations: agg.join(';'),
  };

  action?.configCatAzure && (parameters.cloud_type = 'all');

  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters,
    },
  });
};

export const getAllTypeFilterList = () => {
  return axiosObj({
    method: 'GET',
    url: '/app_partner/opportunity/filter_condition/',
  });
};

export const getAllAzureTypeFilterList = () => {
  return axiosObj({
    method: 'GET',
    url: '/app_partner/opportunity/azure/filter_condition/',
  });
};

export const getFilterList = () => {
  return axiosObj({
    method: 'GET',
    url: '/app_partner/opportunity/filter/',
  });
};

export const postFilterList = payload => {
  return axiosObj({
    method: 'POST',
    url: '/app_partner/opportunity/filter/',
    data: payload,
  });
};

export const patchFilterList = (id, payload) => {
  return axiosObj({
    method: 'PATCH',
    url: `/app_partner/opportunity/filter/${id}`,
    data: payload,
  });
};

export const deleteFilterList = id => {
  return axiosObj({
    method: 'DELETE',
    url: `/app_partner/opportunity/filter/${id}`,
  });
};

export const getBillingList = (month, year) => {
  return axiosObj({
    method: 'GET',
    url: `/app_partner/clients_with_invoice/?month=${month}&year=${year}`,
  });
};

export const getBillingClientList = id => {
  return axiosObj({
    method: 'GET',
    url: `/app_partner/invoice_of_client/${id}/`,
  });
};

export const getBillingInvoice = ({ bId, cId }) => {
  return axiosObj({
    method: 'GET',
    url: `/app_partner/invoice_of_client/${bId}/${cId}/`,
  });
};

export const createOrUpdateBillingImport = payload => {
  return axiosObj({
    method: payload.id ? 'PUT' : 'POST',
    url: `/app_partner/billing/configuration/${payload.id ? payload.id + '/' : ''}`,
    data: payload,
  });
};

export const getAllBillingImports = () => {
  return axiosObj({
    method: 'GET',
    url: '/app_partner/billing/configuration/',
  });
};

export const getBillingImport = id => {
  return axiosObj({
    method: 'GET',
    url: `/app_partner/billing/configuration/${id}/`,
  });
};

export const removeBillingImport = id => {
  return axiosObj({
    method: 'DELETE',
    url: `/app_partner/billing/configuration/${id}/`,
  });
};

export const createOrUpdateBillingExport = payload => {
  return axiosObj({
    method: payload.id ? 'PUT' : 'POST',
    url: `/app_partner/billing/client/${payload.id ? payload.id + '/' : ''}`,
    data: payload,
  });
};

export const getAllBillingExports = () => {
  return axiosObj({
    method: 'GET',
    url: '/app_partner/billing/client/',
  });
};

export const getBillingExport = id => {
  return axiosObj({
    method: 'GET',
    url: `/app_partner/billing/client/${id}/`,
  });
};

export const removeBillingExport = id => {
  return axiosObj({
    method: 'DELETE',
    url: `/app_partner/billing/client/${id}/`,
  });
};

export const getPartnerCAList = () => {
  return axiosObj({
    method: 'GET',
    url: `/app_partner/billing_account/`,
  });
};

export const postEmptyProjectBA = payload => {
  return axiosObj({
    method: 'POST',
    url: '/app_partner/billing_account/',
    data: payload,
  });
};

export const postAssignExistingBAToProject = (id, payload) => {
  return axiosObj({
    method: 'PUT',
    url: `/app_partner/billing_account/${id}/`,
    data: payload,
  });
};

export const removePartnerCA = id => {
  return axiosObj({
    method: 'DELETE',
    url: `/app_partner/billing_account/${id}/`,
  });
};

/******************* Credit list ********************/

const creditURL = '/app_partner/credits/';

export const getCreditList = () => {
  return axiosObj({
    method: 'GET',
    url: creditURL,
  });
};

export const addCreditList = payload => {
  return axiosObj({
    method: 'POST',
    url: creditURL,
    data: payload,
  });
};

export const updateCreditList = payload => {
  return axiosObj({
    method: 'PATCH',
    url: `${creditURL + payload.id}/`,
    data: payload.updateField,
  });
};

export const updateBulkCreditList = payload => {
  return axiosObj({
    method: 'PATCH',
    url: '/app_partner/credits/bulk_update/',
    data: payload,
  });
};

export const deleteCreditList = payload => {
  return axiosObj({
    method: 'DELETE',
    url: `${creditURL + payload.id}`,
  });
};

export const getAssignedBAList = client_id => {
  return axiosObj({
    method: 'GET',
    url: `/app_partner/billing_account/?client_id=${client_id}`,
  });
};

/******************* ********************/

/******************* Custom Billing Charges ********************/

const customBillingChargesURL = '/app_partner/custom_charges/';

export const getBillingFilter = () => {
  return axiosObj({
    method: 'GET',
    url: '/app_partner/billing/filters/',
  });
};

export const getCustomBillingChargesList = () => {
  return axiosObj({
    method: 'GET',
    url: customBillingChargesURL,
  });
};

export const addCustomBillingCharge = payload => {
  return axiosObj({
    method: 'POST',
    url: customBillingChargesURL,
    data: payload,
  });
};

export const updateCustomBillingCharge = payload => {
  return axiosObj({
    method: 'PATCH',
    url: `${customBillingChargesURL + payload.id}/`,
    data: payload,
  });
};

export const deleteCustomBillingCharge = payload => {
  return axiosObj({
    method: 'DELETE',
    url: `${customBillingChargesURL + payload}/`,
  });
};

/****************************** ********************************/

export const getClientMetricsList = (cliend_id, partner_id) => {
  return axiosObj({
    method: 'GET',
    url: `/app_partner/sow/metrics/?client_id=${cliend_id}&partner_id=${partner_id}`,
  });
};

export const getUnusedResourcesCostByClient = (cliend_id, partner_id) => {
  return axiosObj({
    method: 'GET',
    url: `/app_partner/sow/unused_resources_cost_data_by_client/?client_id=${cliend_id}&partner_id=${partner_id}`,
  });
};

export const getUnderutilizedResourcesCostByClient = (cliend_id, partner_id) => {
  return axiosObj({
    method: 'GET',
    url: `/app_partner/sow/underutilized_resources_cost_data_by_client/?client_id=${cliend_id}&partner_id=${partner_id}`,
  });
};

export const getArgSpendSummaryDataByCostByClient = (cliend_id, partner_id) => {
  return axiosObj({
    method: 'GET',
    url: `/app_partner/sow/current_and_last_spend_summary_data/?client_id=${cliend_id}&partner_id=${partner_id}`,
  });
};

export const getCurrentMonthSpend = params => {
  return axiosObj({
    method: 'GET',
    url: '/app_partner/sow/current_month_mini_spend_summary_by_client/',
    params,
  });
};

export const getLastMonthSpend = params => {
  return axiosObj({
    method: 'GET',
    url: '/app_partner/sow/last_month_mini_spend_summary_by_client/',
    params,
  });
};

export const getRightsizingResourcesCostDataByClient = (cliend_id, partner_id) => {
  return axiosObj({
    method: 'GET',
    url: `/app_partner/sow/rightsizing_resources_cost_data_by_client/?client_id=${cliend_id}&partner_id=${partner_id}`,
  });
};

export const getRIPartnerRecByClient = cliend_id => {
  return axiosObj({
    method: 'GET',
    url: `/c/sharesave/ri_partner_recommendation_by_client/?client_id=${cliend_id}`,
  });
};

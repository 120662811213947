import React, { useEffect, useRef, useState } from 'react';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { colors } from '../Color';
import cloneDeep from 'lodash/cloneDeep';

const CLOUD_NAMES = {
  aws: 'AWS',
  azure: 'Azure',
};

const CustomLegend = ({ data, onClick }) => {
  if (!data.length) {
    return null;
  }

  const _data = cloneDeep(data) || [];

  const platforms = _data.reduce((obj, elem, index) => {
    !elem.cloudPlatform && (elem.cloudPlatform = 'aws');

    if (!obj[elem.cloudPlatform]) {
      obj[elem.cloudPlatform] = [];
    }

    obj[elem.cloudPlatform].push({ ...elem, index });

    return obj;
  }, {});
  const platformsArray = Object.keys(platforms) || [];

  return (
    <div className="np-legendBlock">
      {platformsArray.map(pl => (
        <ul className="np-customLegend" key={pl}>
          <li className="legendTitle">{CLOUD_NAMES[pl]}:</li>
          {platforms[pl].map((item, index) => {
            return (
              <li key={item.label + index} data-cloud={pl} data-index={item.index} onClick={() => onClick(item.index)}>
                <span className="legendColor" style={{ backgroundColor: item.backgroundColor }} />
                <span className={`legendLabel ${item.hidden ? 'strike' : ''}`}>{item.label}</span>
              </li>
            );
          })}
        </ul>
      ))}
    </div>
  );
};

const CustomHorizontalChart = ({ handleClick, dataSet, labels, showXAxes = true, showYAxes = true }) => {
  const chartRef = useRef();
  const [chartHeight, setChartHeight] = useState('100px');
  const [chartDataSet, setDataSet] = useState([]);

  useEffect(() => {
    const data_split = Math.round(dataSet.length / 6);
    setChartHeight(`${100 + data_split * 15}px`);
    addStyle();
  }, [dataSet]); //eslint-disable-line

  const onClick = (event = []) => {
    const eventDetail = getElementAtEvent(chartRef.current, event);

    if (!eventDetail.length) {
      return null;
    }

    if (typeof handleClick === 'function') {
      const label = dataSet?.[eventDetail[0].datasetIndex || 0]?.label || '';
      const record = (chartDataSet || []).find(item => (item?.label || '').toString() === label.toString());
      handleClick(label, record?.cloudPlatform);
    }
  };

  const addStyle = () => {
    const newDS = [];
    dataSet.forEach((row, i) => {
      const color = colors[i % colors.length];
      newDS.push({
        axis: 'y',
        data: row.data,
        label: row.label,
        fill: true,
        lineTension: 0.1,
        backgroundColor: color.backgroundColor,
        borderColor: color.borderColor,
        hoverBackgroundColor: color.hoverBackgroundColor,
        hoverBorderColor: color.hoverBorderColor,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#22C7E3',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#22C7E3',
        pointHoverBorderColor: '#22C7E3',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        borderWidth: 1,
        pointHitRadius: 40,
        stack: '1',
        hidden: false,
        cloudPlatform: row.cloudPlatform,
      });
    });
    setDataSet(newDS);
  };

  const handleLegendClick = index => {
    const chart = chartRef.current._metasets;
    chart[index].hidden = chart[index].hidden === null ? true : !chart[index].hidden;
    chartRef.current.legend.chart.update();

    const data = [...chartDataSet];
    data[index] = {
      ...data[index],
      hidden: !data[index].hidden,
    };
    setDataSet(data);
  };

  return (
    <>
      <div className="chart_js" data-testid="HorizontalBar-chart" style={{ height: chartHeight }}>
        <Bar
          type="bar"
          ref={chartRef}
          id="HorizontalBar"
          data={{
            labels: [labels],
            datasets: chartDataSet,
          }}
          options={{
            indexAxis: 'y',
            plugins: {
              legend: {
                display: false,
              },
              tooltips: {
                enabled: true,
                mode: 'label',
                position: 'average',
                intersect: true,
                axis: 'y',
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                id: 'y',
                display: showYAxes,
                stacked: true,
                barThickness: 60,
                maxBarThickness: 80,
              },
              x: {
                id: 'x',
                stacked: true,
                display: showXAxes,
              },
            },
          }}
          // height={50}
          // width={width || 50}
          onClick={onClick}
        />
      </div>
      <CustomLegend data={chartDataSet} onClick={handleLegendClick} />
    </>
  );
};

export default CustomHorizontalChart;

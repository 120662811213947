import React, { useState, useCallback, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { TableData } from './tableData';
import { TableChart } from './tableChart';
import { RulesTable } from './rulesTable';
import { getLink } from '../helper';
import { ActivityLog } from './activityLog';
import { useLocation } from 'react-router';
import { RecommendationChart } from './recommendationChart';
import ResourceDetails from 'containers/Search/Detail';
import { ScheduleGrid } from 'components/ScheduleGrid';
import { NodeGroups } from './nodeGroups';

export const ResourceDetail = React.memo(
  ({ ...props }) => {
    const location = useLocation();
    const [selectedInstanceId, setSelectedInstanceId] = useState(null);
    const isDetailPage = location.pathname.includes('/v3/search/detail/');
    const violation = cloneDeep(props.data?._source?.violations || []);
    const resource_type = props.data?._source?.type || props?.recommendation?.resource_type;
    const noCluster = useMemo(() => !['rds_cluster', 'eks_cluster'].includes(resource_type), [resource_type]);

    const getRecommendation = useCallback(() => {
      if (!props?.recommendation) {
        return null;
      }

      return {
        ...props?.recommendation,
        id: selectedInstanceId,
        resource_type: 'rds',
      };
    }, [props?.recommendation, selectedInstanceId]);

    return (
      <div className="np-row">
        <div className="npxs-12">
          {!isDetailPage && violation?.length ? (
            <ActivityLog data={props.data} isPartner={props.isPartner} isDetailPage={isDetailPage} />
          ) : null}
          {props?.recommendation && noCluster && resource_type !== 'autoscaling_groups' ? (
            <RecommendationChart {...props} />
          ) : null}

          {props?.recommendation && noCluster ? (
            <div className="np-workloadDetail">
              <ScheduleGrid actions={props?.recommendation?.actions} setGridPayload={() => {}} readOnly={true} />
            </div>
          ) : null}

          <TableChart {...props} />

          <TableData
            {...props}
            awsLink={getLink(props.data)}
            overrideRecord={props.overrideRecord}
            isDetailPage={isDetailPage}
            setSelectedInstanceId={setSelectedInstanceId}
            configCatAzure={props.configCatAzure}
          />

          {resource_type === 'eks_cluster' ? <NodeGroups {...props} /> : null}

          <RulesTable {...props} />
          {selectedInstanceId ? (
            <ResourceDetails
              isSharesaveScheduler={true}
              id={selectedInstanceId}
              projectId={props?.projectId}
              recommendation={getRecommendation()}
              onClose={() => setSelectedInstanceId(null)}
            />
          ) : null}
        </div>
      </div>
    );
  },
  (pp, np) => {
    isEqual(pp, np);
  },
);

import React, { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { getLineOptions } from './constants';

export const LineColors = [
  {
    backgroundColor: '#008BFF',
    borderColor: '#0071EA',
    hoverBackgroundColor: '#FFFFFF',
    hoverBorderColor: '#008BFF',
  },
  {
    backgroundColor: '#ff7f77',
    borderColor: '#f95b5b',
    hoverBackgroundColor: '#FFFFFF',
    hoverBorderColor: '#ff7f77',
  },
  {
    backgroundColor: '#fdd660',
    borderColor: '#fdbd61',
    hoverBackgroundColor: '#FFFFFF',
    hoverBorderColor: '#fdd660',
  },
  {
    backgroundColor: '#99e3dd',
    borderColor: '#22c7e3',
    hoverBackgroundColor: '#FFFFFF',
    hoverBorderColor: '#99e3dd',
  },
  {
    backgroundColor: '#E59682',
    borderColor: '#e57373',
    hoverBackgroundColor: '#FFFFFF',
    hoverBorderColor: '#E59682',
  },
  {
    backgroundColor: '#8E44E8',
    borderColor: '#673AB7',
    hoverBackgroundColor: '#FFFFFF',
    hoverBorderColor: '#8E44E8',
  },
  {
    backgroundColor: '#13CDFC',
    borderColor: '#07acfc',
    hoverBackgroundColor: '#FFFFFF',
    hoverBorderColor: '#13CDFC',
  },
  {
    backgroundColor: '#D4DE66',
    borderColor: '#DECF3F',
    hoverBackgroundColor: '#FFFFFF',
    hoverBorderColor: '#D4DE66',
  },
  {
    backgroundColor: '#7C7DE1',
    borderColor: '#5d54e1',
    hoverBackgroundColor: '#FFFFFF',
    hoverBorderColor: '#7C7DE1',
  },
  {
    backgroundColor: '#0F77FF',
    borderColor: '#0D5AB6',
    hoverBackgroundColor: '#FFFFFF',
    hoverBorderColor: '#0F77FF',
  },
];

const MultiLineChart = ({ data: propsData, columns, defaultFormat, unit, yLabel, xLabel, height }) => {
  const [data, setData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    setInitialState();
  }, [propsData, columns]); //eslint-disable-line

  const setInitialState = () => {
    const newData = {};
    newData.datasets = orderBy(propsData || [], 'data', 'desc').map((row, i) => ({
      label: row.label,
      fill: false,
      type: 'line',
      lineTension: 0.2,
      backgroundColor: row.backgroundColor || LineColors[i % LineColors.length].backgroundColor,
      borderColor: row.borderColor || LineColors[i % LineColors.length].borderColor,
      hoverBackgroundColor: row.hoverBackgroundColor || LineColors[i % LineColors.length].hoverBackgroundColor,
      hoverBorderColor: row.hoverBorderColor || LineColors[i % LineColors.length].hoverBorderColor,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: row.borderColor || LineColors[i % LineColors.length].borderColor,
      pointBackgroundColor: row.backgroundColor || LineColors[i % LineColors.length].backgroundColor,
      pointBorderWidth: 1,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: row.hoverBackgroundColor || LineColors[i % LineColors.length].hoverBackgroundColor,
      pointHoverBorderColor: row.hoverBorderColor || LineColors[i % LineColors.length].hoverBorderColor,
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      borderWidth: 1.7,
      pointHitRadius: 10,
      data: row.data || [0],
    }));
    setData(newData);
  };

  let dateFormat = 'MMM-D-YYYY';

  if (defaultFormat) {
    dateFormat = defaultFormat;
  } else if (
    columns &&
    columns.length &&
    Math.floor(moment(columns[columns.length - 1]).diff(moment(columns[0]), 'months')) > 1
  ) {
    dateFormat = 'YYYY-MM';
  }

  const options = getLineOptions(
    unit,
    yLabel,
    xLabel,
    (columns || []).map(row => moment(row).format(dateFormat)),
  );

  return (
    <div data-testid="multi-line-chart">
      <Line type="line" data={data} options={options} height={height || 100} />
    </div>
  );
};

export default MultiLineChart;

import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { FILTER_DATE_FORMAT } from 'utils/filter';
import { getIndexOfDate } from '../AWSResource/TableChart';
import { numberWithCommasAndDecimal } from 'utils/helper';

const ForecastChart = ({ dataSet, columns, unit, height = undefined }) => {
  const [data, setChartData] = useState({ datasets: [] });

  useEffect(() => {
    setData();
  }, [dataSet]); //eslint-disable-line

  const setData = () => {
    const recIndex = getIndexOfDate(columns);

    if (recIndex < 0) {
      return null;
    }

    const dottedChartArray = [],
      lineChartArray = [];

    if (recIndex !== 0) {
      for (let i = 0; i <= recIndex; i++) {
        lineChartArray.push(dataSet[i] ? Number(dataSet[i]) : 0);

        if (i === recIndex) {
          dottedChartArray.push(dataSet[i] ? Number(dataSet[i]) : null);
        } else {
          dottedChartArray.push(null);
        }
      }
    } else {
      dottedChartArray.push(null);
    }

    const dottedChartArrayData = [];

    for (let i = recIndex + 1; i <= columns.length; i++) {
      dottedChartArray.push(dataSet[i] ? Number(dataSet[i]) : NaN);
      dottedChartArrayData.push(dataSet[i] ? Number(dataSet[i]) : 0);
    }

    const detail = {};
    const currentSpend = lineChartArray.length ? lineChartArray.reduce((total, num) => total + num) : 0;
    const estimatedSpend = dottedChartArrayData.length ? dottedChartArrayData.reduce((total, num) => total + num) : 0;
    detail.datasets = [
      {
        label: `Current Spend (${unit + numberWithCommasAndDecimal(currentSpend)})`,
        type: 'line',
        borderColor: '#0071EA',
        backgroundColor: '#0071EA',
        borderWidth: 2,
        fill: false,
        data: lineChartArray,
      },
    ];

    if (estimatedSpend) {
      detail.datasets.push({
        label: `Estimated Spend (${unit + numberWithCommasAndDecimal(estimatedSpend)})`,
        type: 'line',
        borderColor: '#a5afbd',
        fill: false,
        backgroundColor: '#a5afbd',
        borderDash: [3, 3],
        data: dottedChartArray,
      });
    }

    setChartData(detail);
  };

  return (
    <div style={{ height: height || 'inherit' }}>
      <Line
        type="line"
        data={data}
        options={{
          scales: {
            x: {
              barPercentage: 1.0,
              labels: (columns || []).map(row => moment(row, FILTER_DATE_FORMAT).format('MMM-D')),
            },
            y: {
              position: 'left',
              type: 'linear',
              beginAtZero: true,
              title: { display: true, text: 'Spend' },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: { legend: { display: true, position: 'bottom' } },
        }}
        height={293}
      />
    </div>
  );
};

export default ForecastChart;

import get from 'lodash/get';

export const getTabData = (data, tab) => {
  const index = (data || []).findIndex(x => x.tab === tab);

  if (index < 0) {
    return {};
  }

  return data[index].data || {};
};

export const getTabFilterData = (data, tab) => {
  const index = (data || []).findIndex(x => x.tab === tab);

  if (index < 0) {
    return {};
  }

  return data[index];
};

export const getTabSummaryData = (data, tab) => {
  const index = (data || []).findIndex(x => x.tab === tab);

  if (index < 0) {
    return {};
  }

  return {
    arp_spend_summary: get(data[index].data, 'arp_spend_summary', {}),
    arp_spend_summary_history: get(data[index].data, 'arp_spend_summary_history', {}),
  };
};

import moment from 'moment';
import { TWO_WEEKS_AGO, WEEK_AGO, YESTERDAY, LAST_30_DAYS } from 'utils/filter';

export const isSameMonth = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) {
    return false;
  }

  return a.month() === b.month() && a.year() === b.year();
};

export const dropDownList = () => {
  const options = [];
  let i = 1;

  do {
    options.push({
      start: moment().utc().subtract(i, 'M').startOf('M'),
      end: moment().utc().subtract(i, 'M').endOf('M'),
      text: moment().subtract(i, 'M').utc().format('MMM YYYY'),
    });
    i++;
  } while (i <= 6);

  return options;
};

export const cost_presets = [
  {
    text: '1D',
    end: YESTERDAY,
    start: YESTERDAY,
  },
  {
    text: '7D',
    end: YESTERDAY,
    start: WEEK_AGO,
  },
  {
    text: '14D',
    end: YESTERDAY,
    start: TWO_WEEKS_AGO,
  },
  {
    text: 'MTD',
    end: isSameMonth(moment().utc(), moment().utc().subtract(1, 'days'))
      ? moment().utc().subtract(1, 'days')
      : moment().utc().set('date', 1),
    start: moment().utc().set('date', 1),
  },
  {
    text: '30D',
    end: YESTERDAY,
    start: LAST_30_DAYS,
  },
  {
    text: '1M',
    end: moment().utc().subtract(1, 'M').endOf('M'),
    start: moment().utc().subtract(1, 'M').startOf('M'),
  },
  {
    text: '3M',
    end: moment().utc().subtract(1, 'M').endOf('M'),
    start: moment().utc().subtract(3, 'M').startOf('M'),
  },
  {
    text: '6M',
    end: moment().utc().subtract(1, 'M').endOf('M'),
    start: moment().utc().subtract(6, 'M').startOf('M'),
  },
  {
    text: '1Y',
    end: moment().utc().subtract(1, 'M').endOf('M'),
    start: moment().utc().subtract(12, 'M').startOf('M'),
  },
  {
    text: '3Y',
    end: moment().utc().subtract(1, 'M').endOf('M'),
    start: moment().utc().subtract(36, 'M').startOf('M'),
  },
  {
    text: 'YTD',
    end: moment().utc().subtract(1, 'days'),
    start: moment().utc().startOf('y'),
  },
  ...dropDownList(),
];

export const getGranularity = (date_start, date_end, granularity) => {
  const days_diff = date_end.diff(date_start, 'days');
  const months_diff = date_end.diff(date_start, 'months');

  if (days_diff < 7) {
    if (!['day', 'hour'].includes(granularity)) {
      return 'day';
    } else {
      return granularity;
    }
  } else if (days_diff < 14) {
    if (!['day', 'hour', 'week'].includes(granularity)) {
      return 'day';
    } else {
      return granularity;
    }
  } else if (days_diff < 90) {
    if (!['day', 'hour', 'week', 'month'].includes(granularity)) {
      return 'day';
    } else {
      return granularity;
    }
  } else if (months_diff < 6) {
    if (!['day', 'week', 'month', 'quarter'].includes(granularity)) {
      return 'day';
    }

    return granularity;
  } else {
    return granularity;
  }
};

import { currencyFormat } from 'utils/number';

export const getTheme = isDark => theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral0: isDark ? '#2b2d2d' : theme.colors.neutral0,
    neutral80: isDark ? '#fff' : theme.colors.neutral80,
    neutral90: isDark ? '#fff' : theme.colors.neutral90,
    primary: isDark ? '#0071ea' : 'var(--blue)',
    primary25: isDark ? '#282929' : theme.colors.primary25,
    primary50: isDark ? '#282929' : theme.colors.primary50,
  },
});

export const getSortedOptions = (data, isSorted = true) => {
  if (!data) {
    return [];
  }

  return [...data]
    .sort((a, b) => {
      if (isSorted) {
        if ('cost' in a && 'cost' in b) {
          return b.cost - a.cost;
        } else {
          return a.label.localeCompare(b.label);
        }
      }

      return 0;
    })
    .map(item => ({
      ...item,
      label: 'cost' in item ? `${item.label} (${currencyFormat(item.cost || 0)})` : item.label,
    }));
};

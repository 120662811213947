import produce from 'immer';
import {
  LOAD_INITIAL_REQUEST,
  LOAD_FIRST_BUNCH_SUCCESS,
  LOAD_SECOND_BUNCH_SUCCESS,
  LOAD_THIRD_BUNCH_SUCCESS,
  LOAD_INITIAL_SUCCESS,
  LOAD_INITIAL_ERROR,
  LOAD_AUTO_SUGGESTION_DATA,
  LOAD_AUTO_SUGGESTION_DATA_SUCCESS,
  LOAD_AUTO_SUGGESTION_DATA_ERROR,
  CLEAR_SUGGESTION_DATA,
  SHOW_TOAST,
  REMOVE_TOAST,
  SET_OVERRIDE_TIMESTAMP,
  SET_CLIENT_EXPLORE,
  SET_CLIENT_HUBSPOT,
  SET_OVERRIDE_TIMESTAMP_SUCCESS,
  LOAD_POLICY_DATA_SUCCESS,
  LOAD_POLICY_DATA_ERROR,
  LOAD_CONFIGCAT_SUCCESS,
  UPDATE_POLICY_ALL_PROJECT_REQUEST,
  UPDATE_POLICY_PER_PROJECT_REQUEST,
  UPDATE_POLICY_ALL_PROJECT_ERROR,
  UPDATE_POLICY_PER_PROJECT_ERROR,
  GET_JIRA_CREDENTIALS_REQUEST,
  GET_JIRA_CREDENTIALS_SUCCESS,
  GET_JIRA_CREDENTIALS_ERROR,
  GET_JIRA_ISSUES_REQUEST,
  GET_JIRA_ISSUES_SUCCESS,
  GET_JIRA_ISSUES_ERROR,
  LOAD_JIRA_OAUTH2_DATA_REQUEST,
  LOAD_JIRA_OAUTH2_DATA_SUCCESS,
  LOAD_JIRA_OAUTH2_DATA_ERROR,
  LOAD_NEW_PROFILE_PIC,
  SET_THEME,
} from './constants';

export const initialState = {
  configCatAzure: null,
  loading: true,
  userLoading: false,
  clientLoading: true,
  projectsLoading: false,
  policyLoading: false,
  credStatusLoading: false,
  suggestionLoader: false,
  jiraIssuesLoading: false,
  jiraIssuesError: false,
  overrideTimeStamp: '',
  hasProject: false,
  userData: {},
  projectStatus: {},
  autoSuggestionData: [],
  jiraIssues: [],
  policyData: {},
  credStatus: 0,
  credStatusError: '',
  showToast: [],
  filter: {
    account: [],
    cloudPlatform: [
      { id: 'aws', value: 'AWS', cloudPlatform: 'aws' },
      { id: 'azure', value: 'Azure', cloudPlatform: 'azure' },
    ],
  },
  theme: 'light',
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    if (!action || !action.type) {
      return state;
    }

    switch (action.type) {
      case LOAD_CONFIGCAT_SUCCESS: {
        draft.configCatAzure = action.response['value'];

        break;
      }

      case LOAD_INITIAL_REQUEST:
        draft.loading = true;
        draft.userLoading = true;
        draft.clientLoading = true;
        draft.projectsLoading = true;

        break;

      case LOAD_FIRST_BUNCH_SUCCESS: {
        draft.userData = action.response;
        draft.userLoading = false;

        break;
      }

      case LOAD_SECOND_BUNCH_SUCCESS: {
        draft.userData = { ...state.userData, ...action.response };
        draft.clientLoading = false;

        break;
      }

      case LOAD_THIRD_BUNCH_SUCCESS: {
        const { projects, projectStatus } = action.response;
        const filteredProjects = !state.configCatAzure ? projects.filter(proj => proj?.cloud_type === 'aws') : projects;

        draft.projectStatus = projectStatus;
        draft.userData = { ...state.userData, ...action.response };
        draft.hasProject = Boolean(filteredProjects.length);
        draft.filter.account = filteredProjects.map(row => ({
          id: String(row.id),
          value: row.name,
          status: row.status,
          cloudPlatform: row?.cloud_type || 'aws',
          last_ingestion_datetime: row.billing?.last_ingestion_datetime,
        }));
        draft.projectsLoading = false;

        break;
      }

      case LOAD_INITIAL_SUCCESS:
        draft.userData.payment = action.response.payment;
        draft.loading = false;

        break;
      case LOAD_INITIAL_ERROR:
        draft.loading = false;

        break;
      case LOAD_NEW_PROFILE_PIC:
        draft.userData.profile.profile_picture = action.payload;

        break;
      case LOAD_AUTO_SUGGESTION_DATA:
        draft.suggestionLoader = true;

        break;
      case LOAD_AUTO_SUGGESTION_DATA_SUCCESS:
        draft.suggestionLoader = false;
        draft.autoSuggestionData = (action.response?.data || []).map(name => ({
          name,
        }));

        break;
      case LOAD_AUTO_SUGGESTION_DATA_ERROR:
        draft.suggestionLoader = false;

        break;
      case CLEAR_SUGGESTION_DATA:
        draft.loading = false;
        draft.autoSuggestionData = [];

        break;
      case SHOW_TOAST:
        draft.showToast.push({ ...action.payload, newToast: true });

        break;
      case REMOVE_TOAST:
        draft.showToast = [...draft.showToast.slice(0, action.pos), ...draft.showToast.slice(action.pos + 1)].map(
          toast => ({ ...toast, newToast: false }),
        );

        break;
      case SET_OVERRIDE_TIMESTAMP:
        draft.overrideTimeStamp = '';

        break;
      case SET_THEME:
        draft.theme = action.payload;

        break;
      case SET_CLIENT_EXPLORE:
        draft.userData.currentClient.explore = action.payload;

        break;
      case SET_CLIENT_HUBSPOT:
        draft.userData.profile.is_hubspot_covered = action.payload;

        break;
      case SET_OVERRIDE_TIMESTAMP_SUCCESS:
        draft.overrideTimeStamp = action.response;

        break;
      case LOAD_POLICY_DATA_SUCCESS:
        draft.policyData = action.response;
        draft.policyLoading = false;

        break;
      case LOAD_POLICY_DATA_ERROR:
        draft.policyData = {};
        draft.policyLoading = false;

        break;
      case UPDATE_POLICY_ALL_PROJECT_REQUEST:
        draft.policyLoading = true;

        break;
      case UPDATE_POLICY_ALL_PROJECT_ERROR:
        draft.policyLoading = false;

        break;
      case UPDATE_POLICY_PER_PROJECT_REQUEST:
        draft.policyLoading = true;

        break;
      case UPDATE_POLICY_PER_PROJECT_ERROR:
        draft.policyLoading = false;

        break;
      case GET_JIRA_CREDENTIALS_REQUEST:
        draft.credStatusLoading = true;
        draft.credStatusError = '';

        break;
      case GET_JIRA_CREDENTIALS_SUCCESS:
        draft.credStatusLoading = false;
        draft.credStatus = action.response;

        break;
      case GET_JIRA_CREDENTIALS_ERROR:
        draft.credStatusLoading = false;
        draft.credStatusError = action.error;

        break;
      case GET_JIRA_ISSUES_REQUEST:
        draft.jiraIssuesLoading = true;
        draft.jiraIssues = [];

        break;
      case GET_JIRA_ISSUES_SUCCESS:
        draft.jiraIssuesLoading = false;
        draft.jiraIssues = action.response;
        draft.jiraIssuesError = false;

        break;
      case GET_JIRA_ISSUES_ERROR:
        draft.jiraIssuesLoading = false;
        draft.jiraIssuesError = true;

        break;
      case LOAD_JIRA_OAUTH2_DATA_REQUEST:
        draft.jiraV2Loading = true;

        break;
      case LOAD_JIRA_OAUTH2_DATA_SUCCESS:
        draft.jiraV2Loading = false;
        draft.jiraOAuth2Data = action.response;

        break;
      case LOAD_JIRA_OAUTH2_DATA_ERROR:
        draft.jiraV2Loading = false;

        break;
    }
  });

export default appReducer;

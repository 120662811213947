import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_SEARCH_FILTER_REQUEST,
  LOAD_SEARCH_REQUEST,
  LOAD_SEARCH_RESOURCE_DETAIL_REQUEST,
  LOAD_SEARCH_CM_DATA,
} from './constants';
import {
  loadDataError,
  loadDataSuccess,
  loadResourceDataSuccess,
  loadResourceDataError,
  loadSearchFilterDataSuccess,
  loadSearchFilterDataError,
  loadCMDataSuccess,
  loadCMDataError,
} from './actions';
import { loadSearchData, loadSearchResourceData } from 'api/search';
import { api_condition_error, api_condition_success, globalShowNotification } from 'containers/App/saga';
import { showToastAction } from 'containers/App/actions';

export function* loadInitialData(action) {
  try {
    const res = yield call(loadSearchData, action.payload);

    if (api_condition_success(res.status)) {
      yield put(loadDataSuccess({ data: res.data, filters: action.payload }));
    } else if (api_condition_error(res.status)) {
      yield call(globalShowNotification, res);
      yield put(loadDataError());
    }
  } catch (err) {
    yield put(loadDataError());
    yield put(
      showToastAction({
        message: 'Failed to load search data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* loadCMData(action) {
  try {
    const res = yield call(loadSearchData, action.payload);

    if (api_condition_success(res.status)) {
      yield put(
        loadCMDataSuccess({
          data: res.data.aggregations,
          filters: action.payload,
        }),
      );
    } else if (api_condition_error(res.status)) {
      yield call(globalShowNotification, res);
      yield put(loadCMDataError());
    }
  } catch (err) {
    yield put(loadCMDataError());
    yield put(
      showToastAction({
        message: 'Failed to load change management data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* loadInitialFilterData(action) {
  try {
    const res = yield call(loadSearchData, action.payload);

    if (api_condition_success(res.status)) {
      yield put(loadSearchFilterDataSuccess(res.data));
    } else if (api_condition_error(res.status)) {
      yield call(globalShowNotification, res);
      yield put(loadSearchFilterDataError());
    }
  } catch (err) {
    yield put(loadSearchFilterDataError());
    yield put(
      showToastAction({
        message: 'Failed to load filter data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* loadInitialResourceData(action) {
  try {
    const res = yield call(
      loadSearchResourceData,
      action.payload,
      action.project_id,
      action.partner_scope_id,
      action.isSharesaveScheduler,
      action.resource_type,
    );

    if (api_condition_success(res.status)) {
      yield put(loadResourceDataSuccess(res.data));
    } else if (api_condition_error(res.status)) {
      yield call(globalShowNotification, res);
      yield put(loadResourceDataError());
    }
  } catch (err) {
    yield put(loadResourceDataError());
    yield put(
      showToastAction({
        message: 'Failed to load resource data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export default function* appUpdate() {
  yield takeLatest(LOAD_SEARCH_REQUEST, loadInitialData);
  yield takeLatest(LOAD_SEARCH_FILTER_REQUEST, loadInitialFilterData);
  yield takeLatest(LOAD_SEARCH_CM_DATA, loadCMData);
  yield takeLatest(LOAD_SEARCH_RESOURCE_DETAIL_REQUEST, loadInitialResourceData);
}

import React, { useRef, useEffect } from 'react';
import cx from 'classnames';
import { CloseIcon } from 'assets/images/common';
import { Anchor } from '../Custom';
import { useOnClickOutside } from 'utils/hooks';
import Loader from '../LoadingIndicator/Loader';

export const CustomSideModal = ({ ...props }) => {
  const {
    headerIcon = null,
    headerSummary = null,
    wrapperClass = '',
    title = '',
    children = null,
    onClose,
    loading,
    closable = true,
    tabChild = null,
    overflowY = 'auto',
  } = props;
  const ref = useRef();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      const sideModalClassesInDOM = Array.from(document.getElementsByClassName('np-slidePop show'));

      if (sideModalClassesInDOM.length > 1) {
        return;
      }

      document.body.style.overflow = 'unset';
    };
  }, []);

  const handleClose = () => {
    if (!closable) {
      return;
    }

    onClose();
  };

  useOnClickOutside(ref, handleClose, 'ReactModal__Body--open');

  return (
    <div className={`np-slidePop ${wrapperClass} show`}>
      <div ref={ref} className="np-slidePopWrap">
        <div className={cx('header', { 'np-summary-header': headerSummary })}>
          <h4>
            {headerIcon} {title}
          </h4>
          <div className="np-side-modal-summary">{headerSummary}</div>
          <Anchor
            data-testid="closeSlidePopModal"
            id={'closeSlidePop'}
            className="close-np-slidePop"
            title="Close"
            onClick={onClose}
          >
            <i className="np-svgIcon">
              <CloseIcon />
            </i>
          </Anchor>
        </div>
        {tabChild}
        <div className="np-slidePopBodyWrap" style={!loading ? { overflowY } : {}}>
          <div
            className={cx({
              'np-loading-body np-nops-loading': loading,
            })}
          >
            {loading && <Loader />}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

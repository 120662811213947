const cellStyle = {
  display: 'flex',
  'justify-content': 'flex-start',
  'align-items': 'center',
  cursor: 'pointer',
};

const actionCellStyle = {
  display: 'flex',
  'justify-content': 'flex-end',
  'align-items': 'center',
};

const issueCellStyle = params => {
  return { ...cellStyle, ...params.data.styles };
};

export const PILLARS_COLUMNS = [
  {
    field: 'ruleName',
    headerName: 'Rule name',
    filter: true,
    cellRenderer: 'fieldRenderer',
    flex: 1,
    minWidth: 320,
    autoHeight: true,
    cellStyle: { cursor: 'pointer' },
  },
  {
    field: 'difference',
    headerName: 'Comparision with last month',
    width: 180,
    filter: 'agNumberColumnFilter',
    cellRenderer: 'fieldRenderer',
    cellStyle,
  },
  {
    field: 'count',
    headerName: 'Issues',
    width: 120,
    filter: 'agNumberColumnFilter',
    cellRenderer: 'fieldRenderer',
    cellStyle: issueCellStyle,
  },
];

export const NEW_DETECTION = {
  field: 'newDetection',
  headerName: 'New detection since last week',
  width: 180,
  filter: 'agNumberColumnFilter',
  cellStyle,
};

export const RULES_ACTION = {
  headerName: '',
  field: 'action',
  cellRenderer: 'actionRenderer',
  width: 40,
  filter: false,
  sortable: false,
  type: 'rightAligned',
  cellStyle: { cursor: 'pointer', ...actionCellStyle },
};

export const Check_Box_ACTION = {
  headerName: '',
  field: 'checkbox_selection',
  cellRenderer: 'checkBoxRenderer',
  width: 60,
  filter: false,
  sortable: false,
  cellStyle: { cursor: 'pointer', ...cellStyle },
};

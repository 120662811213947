import ReactDOM from 'react-dom';
import moment from 'moment';
import React from 'react';
import uniq from 'lodash/uniq';
import truncate from 'lodash/truncate';
import { getResourceName, getResourceType, getTypeName, numberFix } from 'utils/helper';
import Tippy from '@tippyjs/react';
import { Anchor } from 'components/Custom';
import { LinkIcon } from 'assets/images/common';
import { RULES_DESCRIPTION_TEXT } from 'utils/RuleDetail';
export const getResourceData = (data, isDaily) => {
  if (!data?.length) {
    return [];
  }

  const resources = [];
  data.forEach(row => {
    const cost = numberFix(row?._sort_agg?.values?.summary?.value);

    if (!row?.arp_resource?.hits?.hits?.length) {
      return;
    }

    const rec = row.arp_resource.hits.hits[0];
    let type = '';

    if (rec?._source?.type) {
      type = getTypeName(rec._source.type);
    } else if (rec?._source?.item_id) {
      if (rec._source.item_id.startsWith('arn:')) {
        type = getTypeName(getResourceType(rec._source.item_id));
      }
    }

    const violations = uniq(
      (rec?._source?.violations || []).map(violation => RULES_DESCRIPTION_TEXT?.[violation.violation_type]?.name || ''),
    );
    resources.push({
      name: getResourceName(rec?._source?.name || row?.key),
      cost: cost,
      productName: rec?._source?.product || '',
      type,
      projectId: (rec?._index || '').split(':')[0].split('_')[1],
      id: rec?._id || 0,
      is_as: rec?._source?.is_as || false,
      is_ri: rec?._source?.is_ri || false,
      first_seen: rec?._source?.first_seen || '',
      tags: rec?._source?.tags_kv,
      operations: rec?._source?.cost?.operation,
      usagetype: rec?._source?.cost?.usagetype,
      cloudPlatform: rec?._source?.cloud_type || 'aws',
      cloud_type: rec?._source?.cloud_type || 'aws',
      violations,
      issue_count: violations.length,
    });
  });

  return resources;
};

export const getNonResourceData = (data, selectedFilter) => {
  const { operations = [], usageTypes = [] } = data;
  const newData = [...operations, ...usageTypes];

  if (selectedFilter === '') {
    return newData;
  } else if (selectedFilter === 'operations') {
    return newData.filter(item => item.type === 'Operations');
  } else if (selectedFilter === 'usageTypes') {
    return newData.filter(item => item.type === 'Usage Types');
  }
};

export const nameRenderer =
  (configCatAzure, handleRecord, isDemoMode) =>
  ({ data = {} }) => {
    const element = document.createElement('div');
    let keyName = data.name;
    keyName = keyName.replace(/ /g, '_').split(',')[0];
    keyName = keyName.replace(/\)/g, '');
    keyName = keyName.replace(/\(/g, '');

    const ico = configCatAzure ? `${data.cloud_type || 'aws'}Icon` : 'amazonIcon';
    const serviceIco = (data.productName || '').replace(/ /g, '_');

    const detailElement = (
      <div className="data np-resource-name-cost">
        <Tippy content={isDemoMode ? '*******' : data.name}>
          <Anchor
            className={'noDec'}
            onClick={() => {
              !isDemoMode && handleRecord(data);
            }}
          >
            <span className={`${ico} ${keyName} ${serviceIco}`} />
            <span className={isDemoMode ? 'demo-mode-black' : ''}>{truncate(data.name, { length: 30 })}</span>
            {!isDemoMode ? (
              <i className="icon-link">
                {' '}
                <LinkIcon />
              </i>
            ) : null}
          </Anchor>
        </Tippy>
        {data?.type ? <label className="icon-serviceName">({data.type})</label> : null}
        <label className="icon-serviceName">{data.productName}</label>
        {data?.is_ri || data?.is_as ? (
          <>
            <br />
            {data?.is_as && <small className="np-tag teal">Part of Autoscaling</small>}
            {data?.is_ri && <small className="np-tag blue">RI</small>}
          </>
        ) : null}
      </div>
    );
    ReactDOM.render(detailElement, element);

    return element;
  };

export const detailRenderer = ({ data = {} }) => {
  const element = document.createElement('div');
  const record = (data?.tags || []).find(item => item.key.includes('createdBy'));
  const value = record?.value.split(':');

  const detailElement = (
    <div className="data">
      {data?.first_seen && (
        <div className="np-tag">
          <b>First seen:</b> {moment(data.first_seen).format('MMM, DD YYYY')}
        </div>
      )}
      <br />
      {record && (
        <div className="np-tag">
          <b>Created by:</b> {value[value.length - 1]}
        </div>
      )}
    </div>
  );
  ReactDOM.render(detailElement, element);

  return element;
};

export const issueRenderer = ({ data = {} }) => {
  const element = document.createElement('div');

  const detailElement = (
    <div className="data">
      {data?.issue_count > 0 ? (
        <Tippy content={data.violations.join(', ')} placement="bottom">
          <div className="np-tag yellow outline">
            {data.issue_count} {data?.issue_count > 1 ? 'violations' : 'violation'}
          </div>
        </Tippy>
      ) : (
        <div className="np-tag yellow outline">No violation</div>
      )}
    </div>
  );
  ReactDOM.render(detailElement, element);

  return element;
};

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import cx from 'classnames';

import { DropDownArrowIcon } from 'assets/images/nOpsRule';

import { loadSearchResourceTrafficData, loadSearchResourceUsageData } from 'api/search';

import { numberFix } from 'utils/helper';

import LineChart from 'components/Charts/LineChart';
import MultiLineChart from 'components/Charts/LineChart/multiLineChart';

import { getFilteredDates } from 'containers/Cost/AWSResource/TableChart';
import { api_condition_error, api_condition_success } from 'containers/App/saga';

import { InfoIcon } from '../../icons';
import { byteMetrics, ec2Metrics, neptuneMetrics, rdsMetrics, s3Metrics } from '../constant';
import LoadingWrapper from 'components/ContentLoader/LoadingWrapper';
import { RowLoader } from 'components/ContentLoader/TableLoader';

const tab1 = [
  { key: 3, title: '1 week' },
  { key: 4, title: '2 week' },
  { key: 5, title: '3 months' },
];
const tab2 = [{ key: 1, title: 'Last Hour' }, { key: 2, title: 'Today' }, ...tab1];

export const TableChart = React.memo(
  ({ ...props }) => {
    const [selectedRange, setSelectedRange] = useState(2);
    const [metricOptions, setMetricOptions] = useState([]);
    const [type, setType] = useState('');
    const [metric, setMetric] = useState({});
    const [selectedM, setSelectedM] = useState(0);
    const [label, setLabel] = useState([]);
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
      if (!isEmpty(props.data)) {
        setInitialData();
      }
      // eslint-disable-next-line
    }, [props.data]);

    const handleSelectRange = key => () => {
      setSelectedRange(key);
    };

    const setInitialData = () => {
      if (!isEmpty(props.data)) {
        const key = props.data._source.type;
        let mOption = [],
          range;

        switch (key) {
          case 'rds':
            range = 3;
            mOption = rdsMetrics;

            break;
          case 'neptune':
            range = 3;
            mOption = neptuneMetrics;

            break;
          case 's3':
            range = 3;
            mOption = s3Metrics;

            break;
          case 'ec2':
            range = 3;
            mOption = ec2Metrics;

            break;
          default:
            break;
        }

        if (mOption.length) {
          setSelectedRange(range);
          setMetricOptions(mOption);
          setMetric(mOption[0]);
        }

        setType(key);
      }
    };

    const handleSetMetric = e => {
      setSelectedM(e.target.value);
      setMetric(metricOptions[e.target.value]);
    };

    useEffect(() => {
      loadChartData();
      // eslint-disable-next-line
    }, [type, selectedM, selectedRange]);

    const loadChartData = () => {
      let startDate = '';
      let endDate = '';
      let period = 0;

      if (selectedRange === 1) {
        startDate = moment().subtract(1, 'hours').utc();
        endDate = moment().utc();
        period = 300;
      } else if (selectedRange === 2) {
        startDate = moment().subtract(24, 'hours').utc();
        endDate = moment().utc();
        period = 600;
      }

      if (selectedRange === 3) {
        startDate = moment().subtract(7, 'days').utc();
        endDate = moment().subtract(1, 'days').utc();
        period = 3600 * 2;
      } else if (selectedRange === 4) {
        startDate = moment().subtract(14, 'days').utc();
        endDate = moment().subtract(1, 'days').utc();
        period = 3600 * 4;
      } else if (selectedRange === 5) {
        startDate = moment().utc().subtract(3, 'M').startOf('M');
        endDate = moment().subtract(1, 'M').endOf('M').utc();
        period = 24 * 3600;
      }

      setLoader(true);

      if (type === 'elb') {
        loadTrafficChart(new Date(startDate), new Date(endDate), period);
      } else if (['ec2', 'rds', 's3', 'neptune'].indexOf(type) > -1) {
        loadCharts(new Date(startDate), new Date(endDate), period);
      }
    };

    const loadTrafficChart = (sDate, eDate, period) => {
      const m = {
        value: 'ELB traffic',
        unit: 'Count',
      };
      const { data: rawData, isPartner = {} } = props;
      loadSearchResourceTrafficData(
        {
          resource_name: rawData._source.name,
          region: rawData._source.region,
          period: period,
          start_time: sDate,
          end_time: eDate,
        },
        isPartner?.partner_scope_id,
      )
        .then(data => {
          if (api_condition_success(data.status)) {
            let i, j, k;
            const labelsObject = {};
            const allData = [];

            const chartData = {},
              response = data.data;
            const keys = Object.keys(response);

            for (i = 0; i < keys.length; i++) {
              const rawData = response[keys[i]];

              for (k = 0; k < rawData.length; k++) {
                for (j = k; j < rawData.length; j++) {
                  if (rawData[k].Timestamp > rawData[j].Timestamp) {
                    rawData[j] = [rawData[k], (rawData[k] = rawData[j])][0]; // swap value
                  }
                }
              }

              chartData[keys[i]] = rawData;
              rawData.forEach(item => {
                labelsObject[item.Timestamp] = true;
              });
            }

            const allLabels = Object.keys(labelsObject).sort();

            for (i = 0; i < keys.length; i++) {
              let seriesData = [];

              const timestampArray = (chartData?.[keys?.[i]] || []).map(e => {
                return e.Timestamp;
              });

              for (k = 0; k < allLabels.length; k++) {
                const dataIndex = timestampArray.indexOf(allLabels[k]);
                dataIndex > -1 ? seriesData.push(chartData[keys[i]][dataIndex]['Statistics']) : seriesData.push(null);
              }

              seriesData = (seriesData || []).map(s => numberFix(s));
              allData.push({
                label: keys[i],
                data: seriesData,
              });
            }

            setLabel(allLabels);
            setData(allData);
            setLoader(false);
            setMetric(m);
          } else if (api_condition_error(data.status)) {
            setLoader(false);
          }
        })
        .catch(e => {
          setLoader(false);
        });
    };

    const loadCharts = (sDate, eDate, period) => {
      let resourceId;
      const { data: rawData, isPartner = {} } = props;

      switch (type) {
        case 'rds':
          resourceId = rawData._source.name;

          break;
        case 'neptune':
          resourceId = rawData._source.db_cluster_identifier;

          break;
        case 's3':
        case 'ec2':
          resourceId = rawData._source.item_id;

          break;
        default:
          break;
      }

      const filteredDates = getFilteredDates(moment(sDate), moment(eDate));

      loadSearchResourceUsageData(
        {
          resource_type: type,
          resource_id: resourceId,
          project_id: rawData._source.project.id,
          metric_name: metric.name,
          statistic: metric.statistics,
          period: period,
          region: rawData._source.region,
          start_time: sDate,
          end_time: eDate,
        },
        isPartner?.partner_scope_id,
      )
        .then(data => {
          if (api_condition_success(data.status)) {
            let series = [];
            const chartData = data.data;
            const metricName = data.config.params.metric_name;

            chartData.sort((a, b) => {
              return a.Timestamp > b.Timestamp ? 1 : -1;
            });
            filteredDates.forEach((filterDate, index) => {
              const data =
                (chartData || []).filter(chartInfo => {
                  if (!chartInfo.Timestamp) {
                    return false;
                  }

                  const date = moment(chartInfo.Timestamp.split('T')[0], 'YYYY-MM-DD');
                  const filterDateFormat = moment(filterDate, 'YYYY-MM-DD');

                  return selectedRange === 5
                    ? filterDateFormat.month() === date.month() && filterDateFormat.year() === date.year()
                    : filterDateFormat.isSame(date, 'day');
                }) || [];

              if (data.length) {
                data.forEach(val => {
                  let calcData = 0;
                  calcData = type === 'ec2' ? val.Average : val.statistic;

                  calcData = numberFix(byteMetrics.indexOf(metricName) > -1 ? calcData / 1024 / 1024 : calcData);

                  if (!series[index] && series[index] !== 0) {
                    series[index] = calcData;
                  } else {
                    series[index] += calcData;
                  }
                });
              } else {
                series[index] = 0;
              }

              series[index] = series[index] / (data?.length || 0);
            });

            series = (series || []).map(s => numberFix(s));
            setData(series);

            setLabel(filteredDates);
          }

          setLoader(false);
        })
        .catch(e => {
          setLoader(false);
        });
    };

    const con = ['ec2', 's3', 'rds', 'neptune'].indexOf(type) > -1;
    const tabs = type === 'elb' ? tab2 : tab1;

    return type === 'elb' || con ? (
      <div className="np-table2">
        <>
          <div className="title">
            <div className="np-titleIcon blue">
              <InfoIcon />
            </div>
            {type === 'elb' ? 'Resources' : type.toLocaleUpperCase()} Usage Graph
          </div>
          <div className="np-table2Actions">
            <div className="np-pageHead-actions">
              <ul className="np-tableTabs">
                {type !== 'elb' && metricOptions.length ? (
                  <li>
                    <div className="np-select-group">
                      <select value={selectedM} className="np-input" onChange={handleSetMetric}>
                        {(metricOptions || []).map((m, index) => (
                          <option value={index} key={m.name}>
                            {m.label}
                          </option>
                        ))}
                      </select>

                      <div className="np-caret">
                        <DropDownArrowIcon />
                      </div>
                    </div>
                  </li>
                ) : null}
                {(tabs || []).map(t => {
                  return (
                    <li key={t.key}>
                      <button
                        className={cx('np-button color-grey light', {
                          active: t.key === selectedRange,
                        })}
                        onClick={handleSelectRange(t.key)}
                      >
                        {t.title}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <LoadingWrapper
            isLoading={loader}
            customLoader={RowLoader}
            customLoadingClassName="np-resource-details-loading"
          >
            <div className="np-row">
              <div className="npxs-12">
                <div className="resourceInfoGraph">
                  {type === 'elb' ? (
                    <MultiLineChart
                      columns={label}
                      data={data}
                      label={metric.value}
                      yLabel={metric.unit}
                      height={80}
                      defaultFormat={'DD//MM - HH:mm:ss A'}
                    />
                  ) : (
                    <LineChart columns={label} data={data} label={metric.value} yLabel={metric.unit} height={7} />
                  )}
                </div>
              </div>
            </div>
          </LoadingWrapper>
        </>
      </div>
    ) : null;
  },
  (pp, np) => {
    isEqual(pp, np);
  },
);

import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { components } from 'react-select';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import CreatableSelect from 'react-select/creatable';
import { SearchIcon } from 'assets/images';
import { makeSelectTheme } from 'containers/App/selectors';
import { getTheme, getSortedOptions } from './helper';

const { Control } = components;

const SearchControl = ({ children, ...props }) => {
  return (
    <Control {...props}>
      {props.menuIsOpen ? (
        <i className="single-select-search-icon">
          <SearchIcon />
        </i>
      ) : null}
      {children}
    </Control>
  );
};

const MultiCreatableSelectDropDown = ({
  defaultData,
  isMulti = true,
  extraProps,
  onChange,
  type = '',
  placeHolder,
  data,
  isSorted = true,
  isLoading = false,
  isDisabled = false,
  isClearable = true,
  menuIsOpen = undefined,
  closeOnSelect = false,
  theme,
  styles = {},
  menuPortalTarget = null,
  wrapperClass = '',
}) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedValues, setSelectedValues] = useState(defaultData);

  useEffect(() => {
    setSelectedValues([...defaultData]);
  }, [defaultData]);

  const handleOnInputChange = newValue => {
    setInputValue(newValue);
  };

  const handleOnChange = selected => {
    if (!isMulti) {
      setSelectedValues(selected);
      onChange(selected, type, extraProps);

      return;
    }

    const data = (selected || []).filter(select =>
      typeof select?.value === 'string' ? select?.value?.trim() : select?.value,
    );
    setSelectedValues(data);
    onChange(data, type, extraProps);
  };

  const handleKeyDown = event => {
    if (!inputValue) {
      return;
    }

    if (!['Enter', 'Tab'].includes(event.key)) {
      return;
    }

    const nextSelectedValues = isMulti
      ? [...selectedValues, { label: inputValue, value: inputValue }]
      : { label: inputValue, value: inputValue };
    setInputValue('');
    handleOnChange(nextSelectedValues);
    event.preventDefault();
  };

  return (
    <CreatableSelect
      id={type}
      className={`basic-multi-select ${wrapperClass}`}
      classNamePrefix="select"
      inputValue={inputValue}
      value={!isEmpty(selectedValues) ? selectedValues : null}
      options={getSortedOptions(data, isSorted)}
      components={{ Control: SearchControl }}
      onInputChange={handleOnInputChange}
      onChange={handleOnChange}
      onKeyDown={handleKeyDown}
      placeholder={placeHolder}
      isLoading={isLoading}
      isDisabled={isDisabled}
      menuIsOpen={menuIsOpen}
      isClearable={isClearable}
      isMulti={isMulti}
      theme={getTheme(theme === 'dark')}
      menuPortalTarget={menuPortalTarget}
      closeMenuOnSelect={closeOnSelect || !isMulti}
      styles={{ ...styles }}
    />
  );
};

const mapStateToProps = createStructuredSelector({ theme: makeSelectTheme() });
const withConnect = connect(mapStateToProps, {});
export default compose(withConnect)(MultiCreatableSelectDropDown);

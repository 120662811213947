import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { CloseIcon } from 'assets/images/modal';
import { customStyles } from 'utils/common';
import { Anchor } from 'components/Custom';
import Loader from 'components/LoadingIndicator/Loader';
import 'styles/np-modal.css';

export const CustomModal = ({ ...props }) => {
  const {
    showModal = false,
    handleToggleModal,
    child = null,
    closeBtn = true,
    title = '',
    footerData = {},
    loading = false,
    mainClass = '',
    headerClass = '',
    footerClass = '',
    bodyClass = '',
    overlayClass = '',
    tabChild = null,
    headerIcon = null,
  } = props;

  const handleOnClick = () => {
    footerData.onClick();
  };

  const handleCancelClick = () => {
    onRequestCancel();

    if (typeof handleToggleModal === 'function') {
      handleToggleModal();
    }
  };

  const onRequestCancel = () => {
    document.body.className = '';
  };

  const renderFooter = () => {
    switch (footerData.type) {
      case 'yesNo':
        return (
          <>
            <button id={'modalYes'} className={`np-button blue light ${footerData.className}`} onClick={handleOnClick}>
              Yes
            </button>
            <button id={'modalNo'} className="np-button light" onClick={handleCancelClick}>
              No
            </button>
          </>
        );
      case 'confirmCancel':
        return (
          <>
            <button
              id={'modalConfirm'}
              className={`np-button blue light ${footerData.className}`}
              onClick={handleOnClick}
            >
              Confirm
            </button>
            <button id={'modalCancel'} className="np-button light" onClick={handleCancelClick}>
              Cancel
            </button>
          </>
        );
      case 'saveCancel':
        return (
          <>
            <button
              id={'modalConfirm'}
              className={`np-button blue light ${footerData.className}`}
              onClick={handleOnClick}
            >
              Save
            </button>
            <button id={'modalCancel'} className="np-button light" onClick={handleCancelClick}>
              Cancel
            </button>
          </>
        );
      case 'cancel':
        return (
          <button id={'modalClose'} className="np-button light" onClick={handleCancelClick}>
            Close
          </button>
        );
      case 'custom':
        return (footerData?.custom || []).map((data, index) => {
          if (!data || data?.show === 'hide') {
            return null;
          }

          return (
            <button
              {...data}
              key={index}
              id={`modal${data.text.replace(/ /g, '')}`}
              className={`np-button light ${data.className}`}
              data-testid={data?.['data-testid'] || ''}
            >
              {data.text}
            </button>
          );
        });
      case 'custom-component':
        return footerData?.component;
      default:
        return null;
    }
  };

  const bodyMainClass = classNames({
    'npModal-body': true,
    'np-nops-loading': loading,
    [bodyClass]: Boolean(bodyClass),
  });

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel="onRequestClose Example"
      onRequestClose={handleCancelClick}
      onRequestCancel={onRequestCancel}
      className={'modal-class ' + mainClass}
      style={customStyles}
      overlayClassName={'modal-overlay ' + overlayClass}
      appElement={document.getElementById('root')}
    >
      {title ? (
        <div id="title" className={'npModal-header ' + headerClass}>
          <h3>
            {headerIcon} {title}
          </h3>
          {closeBtn && !loading ? (
            <Anchor id={'closeModal'} onClick={handleCancelClick} className="close-modal" data-testid="closeModal">
              <i className="np-svgIcon">
                <CloseIcon />
              </i>
            </Anchor>
          ) : null}
        </div>
      ) : null}

      {tabChild}

      <div className={bodyMainClass}>
        {loading && <Loader />}
        {child || props.children}
      </div>

      {!isEmpty(footerData) ? (
        <div className={'npModal-footer ' + footerClass}>
          {footerData.description || null}
          {renderFooter()}
        </div>
      ) : null}
    </ReactModal>
  );
};

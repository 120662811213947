import axiosObj from './config';

export function loadHistoryData() {
  return axiosObj({
    method: 'GET',
    url: '/c/admin/chargeback_history/',
  });
}

export function getClusterStatus(payload) {
  return axiosObj({
    method: 'GET',
    url: '/svc/k8s_cost/cluster_status',
    params: payload,
  });
}

export function loadCostData(payload) {
  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: payload,
  });
}

export function loadCostCachedData(payload) {
  return axiosObj({
    method: 'GET',
    url: '/c/ac/cached/',
    params: payload,
  });
}

export function loadESQueryData(payload) {
  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: payload,
  });
}

export function loadESDocument(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/ac/query/${id}`,
  });
}

export function loadCTEventDetail(eventId) {
  return axiosObj({
    method: 'GET',
    url: `/ct/event/${eventId}/`,
  });
}

export function loadBudgetData(partner_scope_id, workload_rec) {
  const payload = {
    method: 'GET',
    url: '/c/admin/budget/',
    params: {
      workload_name: workload_rec.name,
      client: workload_rec.client_id,
    },
  };

  if (partner_scope_id) {
    payload.params['partner_scope_id'] = partner_scope_id;
  }

  return axiosObj(payload);
}

export function loadChargebackData(payload) {
  return axiosObj({
    method: 'GET',
    url: '/c/admin/budget/?is_chargeback=1',
  });
}

export function loadBudgetDetails(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/admin/budget/${id}/`,
  });
}

export function createHistoryData(payload) {
  return axiosObj({
    method: 'POST',
    url: '/c/admin/chargeback_history/',
    data: payload.params,
  });
}

export function createBudgetData(payload, partner_scope_id) {
  let params = {
    method: 'POST',
    url: '/c/admin/budget/',
    data: payload,
  };

  if (partner_scope_id) {
    params = { ...params, params: { partner_scope_id } };
  }

  return axiosObj(params);
}

export function updateHistoryData(payload) {
  return axiosObj({
    method: 'PATCH',
    url: `/c/admin/chargeback_history/${payload.id}/`,
    data: payload.params,
  });
}

export function updateBudgetData(payload, partner_scope_id) {
  let params = {
    method: 'PATCH',
    url: `/c/admin/budget/${payload.id}/`,
    data: payload.params,
    params: { partner_scope_id },
  };

  if (partner_scope_id) {
    params = { ...params, params: { partner_scope_id } };
  }

  return axiosObj(params);
}

export function deleteBudgetData(id, partner_scope_id) {
  let params = {
    method: 'DELETE',
    url: `/c/admin/budget/${id}/`,
  };

  if (partner_scope_id) {
    params = { ...params, params: { partner_scope_id } };
  }

  return axiosObj(params);
}

export function loadCompareCostData(payload) {
  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: payload,
  });
}

export const getMRCData = () => {
  return axiosObj({
    method: 'GET',
    url: `/c/ac/cached`,
    params: {
      parameters: {
        aggregations: 'arp_mrc_3mo',
      },
    },
  });
};

export const getOpUtData = (url, enableAzure = false) => {
  const url_segment = enableAzure ? 'billing-type-usagetypes-op-auto-complete' : 'usagetypes-op-auto-complete';

  return axiosObj({
    method: 'GET',
    url: `/c/ac/${url_segment}?${url}`,
  });
};

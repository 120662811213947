import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { customStyles } from 'utils/common';
import { handleGlobalNotificationFunction } from 'utils/helper';
import { renameAWSAccount } from 'api/settings';
import { CloseIcon } from 'assets/images/modal';
import { Anchor } from 'components/Custom';
import { showToastAction, loadInitial } from 'containers/App/actions';
import { api_condition_error, api_condition_success } from 'containers/App/saga';

const RenameAWSAccount = ({ data, closeModal, action, showToastAction, loadInitial, noInitialLoad = false }) => {
  const [name, setName] = useState('');
  useEffect(() => {
    setName(data?.name || '');
  }, [data]);

  const handleRename = async () => {
    const res = await renameAWSAccount({
      id: data.id,
      param: { name },
    });

    if (api_condition_success(res.status)) {
      !noInitialLoad && loadInitial();
      showToastAction({
        message: 'Account name updated',
        type: 'success',
        show: true,
      });
      typeof action === 'function' && action(res.data);
    } else if (api_condition_error(res.status)) {
      closeModal();
      handleGlobalNotificationFunction(res, showToastAction);
    }

    closeModal();
  };

  const onRequestCancel = () => {
    document.body.className = '';
  };

  return (
    <ReactModal
      isOpen={true}
      contentLabel="onRequestClose Example"
      className="modal-class"
      style={customStyles}
      overlayClassName="modal-overlay"
      onRequestCancel={onRequestCancel}
      appElement={document.getElementById('root')}
    >
      <div className="npModal-header">
        <h3>Rename Cloud Account</h3>
        <Anchor id={'close-modal'} onClick={closeModal} className="close-modal">
          <i className="np-svgIcon">
            <CloseIcon />
          </i>
        </Anchor>
      </div>

      <div className="npModal-body">
        <div className="np-input-group">
          <input
            type="text"
            name="name"
            id={'searchInput'}
            className="np-input search-input-filter"
            autoComplete="off"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
      </div>

      <div className="npModal-footer">
        <button id={'confirm'} className="np-button blue light" onClick={handleRename}>
          Confirm
        </button>
        <button id={'cancel'} className="np-button light" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </ReactModal>
  );
};

export default connect(null, {
  showToastAction,
  loadInitial,
})(RenameAWSAccount);

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { getAllCountersData } from 'api/global';
import { pivotAggregation, calcCloudPlatformData } from 'utils/helper';
import { ALL_CLOUD_PLATFORMS } from 'utils/constants';

export const initialState = {
  loading: false,
  loadedAWSOnlyData: false,
  loadedCloudData: false,
  cloudPlatform: ALL_CLOUD_PLATFORMS,
  tagResources: [],
  tagResourcesCloud: [],
  cloudInformationTags: [],
  allAutoScaling: [],
  defaultFilter: {},
  defaultFilterCloud: {},
  vpcNames: [],
  vpcNamesCloud: [],
  isCalled: false,
};

const SLICE_NAME = 'globalFilters';

//async CALLS
export const loadInitial = createAsyncThunk(`${SLICE_NAME}/getAllCountersData`, async payload => {
  const response = await getAllCountersData(payload);

  return {
    data: response.data.aggregations,
    isPartner: payload?.isPartner || false,
  };
});

const filterSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: {
    [loadInitial.pending]: state => {
      state.loading = true;
    },
    [loadInitial.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isCalled = !payload.isPartner;

      const data = payload.data;

      if (isEmpty(data)) {
        return;
      }

      if (data?.violations_counts_per_project_cloud_type) {
        state.loadedCloudData = true;
      }

      if (data?.violations_counts_per_project) {
        state.loadedAWSOnlyData = true;
      }

      const violations_counts_per_project = pivotAggregation(
        data,
        'violations_counts_per_project_cloud_type',
        'violations_counts_per_project',
      );

      const violations_counts_per_region = pivotAggregation(
        data,
        'violations_counts_per_region_cloud_type',
        'violations_counts_per_region',
      );

      const violations_counts_per_vpc = pivotAggregation(
        data,
        'violations_counts_per_vpc_cloud_type',
        'violations_counts_per_vpc',
      );
      // Rules Filter
      state.defaultFilter = {
        violations_counts_per_project: data?.violations_counts_per_project?.buckets || [],
        violations_counts_per_region: data?.violations_counts_per_region?.buckets || [],
        violations_counts_per_vpc: data?.violations_counts_per_vpc?.buckets || [],
      };

      const defaultFilterCloud = {
        violations_counts_per_project,
        violations_counts_per_region,
        violations_counts_per_vpc,
      };

      // count all violations per cloud type
      defaultFilterCloud.cloud_data = calcCloudPlatformData(data, [
        'violations_counts_per_region_cloud_type',
        'violations_counts_per_vpc_cloud_type',
        'violations_counts_per_project_cloud_type',
      ]);

      state.defaultFilterCloud = defaultFilterCloud;

      // VPC Filter
      state.vpcNames =
        (data?.vpc_names?.vpc_names_list?.buckets || []).map(data => ({
          key: data.key,
          projectId: data.vpc_name_data.hits.hits[0]._index.split(':')[0].split('_')[1],
          name: data.vpc_name_data.hits.hits[0]._source.name,
        })) || [];

      // VPC Filter
      state.vpcNamesCloud = data?.vpc_names_cloud_type
        ? (data.vpc_names_cloud_type?.buckets || []).flatMap(b =>
            (b.vpc_names?.vpc_names_list?.buckets || []).map(data => ({
              key: data.key,
              name: data.vpc_name_data.hits.hits[0]._source.name,
              cloudPlatform: b.key,
            })),
          )
        : [];

      // Tag resource
      state.tagResources =
        // data?.arp_resource_tag_key_list?.filter?.terms?.buckets || [];
        (data?.arp_resource_tag_key_list?.filter?.terms?.buckets || []).map(v => ({
          ...v,
          cloudPlatform: 'aws',
        }));

      state.tagResourcesCloud = data?.tag_key_list_per_billing_type
        ? (data.tag_key_list_per_billing_type?.buckets || []).flatMap(b =>
            (b.arp_resource_tag_key_list?.filter?.terms?.buckets || []).map(v => ({
              ...v,
              cloudPlatform: b.key,
            })),
          )
        : [];

      // Cloudinformation Tags
      state.cloudInformationTags = orderBy(
        data?.cf_stacks?.cf_stacks_terms?.buckets || [],
        [d => (d?.key || '').toLowerCase()],
        'asc',
      );

      // Autoscaling
      const allData = data?.autoscaling_group_names_cloud_type
        ? (data.autoscaling_group_names_cloud_type?.buckets || []).flatMap(b =>
            (b?.autoscaling_groups?.autoscaling_group_names?.buckets || []).map(v => ({
              ...v,
              cloudPlatform: b.key,
            })),
          )
        : (data?.autoscaling_groups?.autoscaling_group_names?.buckets || []).map(v => ({
            ...v,
            cloudPlatform: 'aws',
          }));

      state.allAutoScaling = orderBy(allData, [d => (d?.key || '').toLowerCase()], 'asc');
    },
    [loadInitial.rejected]: state => {
      state.loading = false;
    },
  },
});

export default filterSlice.reducer;

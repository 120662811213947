import React, { PureComponent } from 'react';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { getPercentage } from 'utils/number';
import CostCard from 'components/CostCard';
import moment from 'moment';
import { getDateRangeField } from './getDateRangeField';
import { COST_TYPE } from 'utils/constants';

export const Col = ({ children }) => <div className="nplg-3 npmd-6 npsm-12 npxs-12">{children}</div>;
Col.propTypes = {
  children: PropTypes.node,
};

const NON_RESOURCES_DEFAULT = Symbol();

class SummaryCost extends PureComponent {
  mustShowNonResources() {
    //non resources tooltip data is currently computed only on tabs Cloud Accounts and Regions
    // on Non Resources tab the tooltip is hidden by setting nonResourcesValue to null
    return [COST_TYPE.CLOUD_ACCOUNTS, COST_TYPE.REGIONS, COST_TYPE.NON_RESOURCES].includes(this.props.selectedTab);
  }

  //for non-resources the summary value is prefixed with 'non_resources'
  getSpendSummaryValue(type) {
    if (this.props.selectedTab === COST_TYPE.NON_RESOURCES && type !== NON_RESOURCES_DEFAULT) {
      return 'non_resources.';
    } else if (this.props.selectedTab === COST_TYPE.NON_RESOURCES && type === NON_RESOURCES_DEFAULT) {
      return 'non_resources_';
    }

    return '';
  }

  getSpendData(period = 'day') {
    const value = get(
      this.props.data.arp_spend_summary,
      `arp_spend_summary_${period}.summary.${this.getSpendSummaryValue(NON_RESOURCES_DEFAULT)}value`,
      0,
    );

    const prevValue = get(
      this.props.data.arp_spend_summary,
      `arp_spend_summary_${period}_delta.summary.${this.getSpendSummaryValue(NON_RESOURCES_DEFAULT)}value`,
      0,
    );

    const aws = get(
      this.props.data.arp_spend_summary,
      `arp_spend_summary_${period}.summary.${this.getSpendSummaryValue(NON_RESOURCES_DEFAULT)}aws`,
      0,
    );

    const azure = get(
      this.props.data.arp_spend_summary,
      `arp_spend_summary_${period}.summary.${this.getSpendSummaryValue(NON_RESOURCES_DEFAULT)}azure`,
      0,
    );

    const granularCredits = this.mustShowNonResources()
      ? get(this.props.summaryCreditsData, `arp_credits_summary_${period}.summary.value`, 0)
      : 0;
    let nonResourcesValue = this.mustShowNonResources()
      ? get(this.props.data.arp_spend_summary, `arp_spend_summary_${period}.summary.non_resources_value`, 0)
      : 0;
    const clouds = { aws, azure };

    if (this.props.selectedTab === COST_TYPE.NON_RESOURCES) {
      nonResourcesValue = null; //in order to hide the tooltip for non resources tab
    }

    return {
      value,
      prevValue,
      percentage: getPercentage(value, prevValue),
      clouds,
      nonResourcesValue,
      granularCredits,
    };
  }

  getWeekSpendData(period) {
    const value = get(
      this.props.data.arp_spend_summary_history,
      `current${period ? '_day' : ''}.${this.getSpendSummaryValue()}summary.value`,
      0,
    );

    const prevValue = get(
      this.props.data.arp_spend_summary_history,
      `previous${period ? '_day' : ''}.${this.getSpendSummaryValue()}summary.value`,
      0,
    );

    const aws = get(
      this.props.data.arp_spend_summary_history,
      `current${period ? '_day' : ''}.${this.getSpendSummaryValue()}summary.aws`,
      0,
    );

    const azure = get(
      this.props.data.arp_spend_summary_history,
      `current${period ? '_day' : ''}.${this.getSpendSummaryValue()}summary.azure`,
      0,
    );

    const granularCredits = this.mustShowNonResources()
      ? get(this.props.summaryCreditsData, `arp_credits_summary${period ? '_day' : '_total'}.summary.value`, 0)
      : 0;
    let nonResourcesValue = this.mustShowNonResources()
      ? get(this.props.data.arp_spend_summary_history, `current${period ? '_day' : ''}.non_resources.summary.value`, 0)
      : 0;

    if (this.props.selectedTab === COST_TYPE.NON_RESOURCES) {
      nonResourcesValue = null; //in order to hide the tooltip for non resources tab
    }

    const clouds = { aws, azure };

    return {
      value,
      prevValue,
      clouds,
      percentage: getPercentage(value, prevValue),
      nonResourcesValue,
      granularCredits,
    };
  }

  getMonthSpendData() {
    const value = get(
      this.props.data.arp_spend_summary_history_month,
      `current.${this.getSpendSummaryValue()}summary.value`,
      0,
    );

    const prevValue = get(
      this.props.data.arp_spend_summary_history_month,
      `previous.${this.getSpendSummaryValue()}summary.value`,
      0,
    );

    const aws = get(
      this.props.data.arp_spend_summary_history_month,
      `current.${this.getSpendSummaryValue()}summary.aws`,
      0,
    );

    const azure = get(
      this.props.data.arp_spend_summary_history_month,
      `current.${this.getSpendSummaryValue()}summary.azure`,
      0,
    );

    const granularCredits = this.mustShowNonResources()
      ? get(this.props.summaryCreditsData, `arp_credits_summary_total.summary.value`, 0)
      : 0;
    let nonResourcesValue = this.mustShowNonResources()
      ? get(this.props.data.arp_spend_summary_history_month, 'current.non_resources.summary.value', 0)
      : 0;

    if (this.props.selectedTab === COST_TYPE.NON_RESOURCES) {
      nonResourcesValue = null; //in order to hide the tooltip for non resources tab
    }

    const clouds = { aws, azure };

    return {
      value,
      prevValue,
      clouds,
      percentage: getPercentage(value, prevValue),
      nonResourcesValue,
      granularCredits,
    };
  }

  getDateDifference = () => {
    const { startDate, endDate } = this.props;

    return Math.floor(endDate.diff(startDate, 'days'));
  };

  getCard = (title, description, spend) => {
    const {
      loading,
      selectedTab,
      isMultipleCurrency = false,
      azure_billing_currency = 'USD',
      isAWSSelected,
    } = this.props;
    // Show tooltip only on Cloud Accounts and Regions
    const showTooltip = [COST_TYPE.CLOUD_ACCOUNTS, COST_TYPE.REGIONS].includes(selectedTab);

    return (
      <Col>
        <CostCard
          loading={loading}
          title={title}
          description={description}
          showTooltip={showTooltip}
          {...spend}
          configCatAzure={this.props.configCatAzure}
          isMultipleCurrency={isMultipleCurrency}
          isAWSSelected={isAWSSelected}
          azure_billing_currency={azure_billing_currency}
        />
      </Col>
    );
  };

  getAllCards = () => {
    const {
      estimatedSpend,
      isShowForecast,
      startDate,
      endDate,
      summaryCreditsData,
      isMultipleCurrency,
      isAWSSelected,
    } = this.props;

    const estimatedData = {
      value: estimatedSpend,
    };
    let diff = 0;
    const type = getDateRangeField({ startDate, endDate });

    const credits = {
      value: summaryCreditsData?.arp_credits_summary_total?.summary.value,
      displayCredits: this.mustShowNonResources(),
      handleExcludeCredits: this.props.handleExcludeCredits,
      includeCredit: this.props.includeCredit,
    };
    const showCredit = this.mustShowNonResources() && (!isMultipleCurrency || (isMultipleCurrency && isAWSSelected));

    switch (type) {
      case 'mtd': {
        diff = this.getDateDifference();

        const lastDaySpend = this.getSpendData('day');
        const lastWeekSpend = this.getSpendData('week');
        const lastMonthSpend = this.getSpendData('mtd');

        return (
          <>
            {this.getCard('Yesterday’s total spend', 'than previous day', {
              ...lastDaySpend,
            })}
            {this.getCard(
              `Last ${diff === 13 ? '2 ' : ''}week's spend`,
              `than previous ${diff === 13 ? '2 ' : ''}week`,
              { ...lastWeekSpend },
            )}
            {this.getCard('Month to date spend', 'than last month to date', {
              ...lastMonthSpend,
            })}
            {isShowForecast && this.getCard('Projected cost', 'next 30 days', estimatedData)}
            {showCredit ? this.getCard('Credits', '', credits) : null}
          </>
        );
      }

      case '1mo': {
        const sDate = cloneDeep(startDate);
        const currentTitle = `${moment(sDate).format('MMMM, YYYY')} spend`;

        const prevTitle = `than previous
            ${moment(sDate).subtract(1, 'M').format('MMMM, YYYY')} spend`;
        const perMonthData = this.getMonthSpendData();

        return (
          <>
            {this.getCard(currentTitle, prevTitle, { ...perMonthData })}
            {showCredit ? this.getCard('Credits', '', credits) : null}
          </>
        );
      }

      case '3mo':
      case '6mo': {
        diff = Math.floor(endDate.diff(startDate, 'M'));

        const monthData = this.getMonthSpendData();

        return (
          <>
            {this.getCard(
              `Past ${type === '3mo' ? '3' : '6'} Months Spend`,
              `than previous ${type === '3mo' ? '3' : '6'} Months Spend`,
              { ...monthData },
            )}
            {showCredit ? this.getCard('Credits', '', credits) : null}
          </>
        );
      }

      case 'weeks': {
        diff = this.getDateDifference();

        const daySpend = this.getWeekSpendData('day');
        const weekSpend = this.getWeekSpendData('');

        return (
          <>
            {this.getCard('Yesterday’s total spend', 'than previous day', {
              ...daySpend,
            })}
            {this.getCard(
              `Last ${diff === 13 ? '2 ' : ''}week's spend`,
              `than previous ${diff === 13 ? '2 ' : ''}week`,
              { ...weekSpend },
            )}
            {showCredit ? this.getCard('Credits', '', credits) : ''}
          </>
        );
      }

      default: {
        const defaultData = this.getWeekSpendData('');

        return (
          <>
            {this.getCard('Total spend', 'than previous spend', {
              ...defaultData,
            })}
            {showCredit ? this.getCard('Credits', '', credits) : ''}
          </>
        );
      }
    }
  };

  render() {
    return this.getAllCards();
  }
}

SummaryCost.propTypes = {
  data: PropTypes.shape({
    arp_spend_summary: PropTypes.shape(),
    total_cost: PropTypes.shape(),
  }),
};

export default SummaryCost;

import React from 'react';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import { getPercentage } from 'utils/number';
import { COST_TYPE } from 'utils/constants';
import PercentageCell from 'components/NPTable/PercentageCell';
import { getFilteredDates } from '../AWSResource/TableChart';
import { changeDateToStringFormat, TODAY, YESTERDAY, FILTER_DATE_FORMAT } from 'utils/filter';
import { currencyFormatterByCode } from 'utils/currencySymbol';

const mapCloudPlatform = data => {
  const newData = cloneDeep(data);

  const cloudCM = {
    arp_cm_accounts: {
      buckets: [],
      doc_count_error_upper_bound: 0,
      sum_other_doc_count: 0,
    },
    arp_cm_operation: {
      arp_cm_data: {
        buckets: [],
        doc_count_error_upper_bound: 0,
        sum_other_doc_count: 0,
      },
      doc_count: 0,
    },
    arp_cm_products: {
      buckets: [],
      doc_count_error_upper_bound: 0,
      sum_other_doc_count: 0,
    },
    arp_cm_regions: {
      buckets: [],
      doc_count_error_upper_bound: 0,
      sum_other_doc_count: 0,
    },
    arp_cm_usagetype: {
      arp_cm_data: {
        buckets: [],
        doc_count_error_upper_bound: 0,
        sum_other_doc_count: 0,
      },
      doc_count: 0,
    },
  };

  (newData.buckets || []).forEach(cl => {
    const cloudPL = cl.key;

    (cl.arp_cm_accounts?.buckets || []).forEach(x => {
      x.cloudPlatform = cloudPL;
      cloudCM.arp_cm_accounts.buckets.push(x);
    });
    (cl.arp_cm_operation?.arp_cm_data?.buckets || []).forEach(x => {
      x.cloudPlatform = cloudPL;
      cloudCM.arp_cm_operation.arp_cm_data.buckets.push(x);
    });
    (cl.arp_cm_products?.buckets || []).forEach(x => {
      x.cloudPlatform = cloudPL;
      cloudCM.arp_cm_products.buckets.push(x);
    });
    (cl.arp_cm_regions?.buckets || []).forEach(x => {
      x.cloudPlatform = cloudPL;
      cloudCM.arp_cm_regions.buckets.push(x);
    });
    (cl.arp_cm_usagetype?.arp_cm_data?.buckets || []).forEach(x => {
      x.cloudPlatform = cloudPL;
      cloudCM.arp_cm_usagetype.arp_cm_data.buckets.push(x);
    });
  });

  return cloudCM;
};

export const getDate = key => {
  return key.split('T')[0];
};

export const findRec = (filterDates, date) => {
  return (filterDates || []).findIndex(x => x.key === date);
};

const getProjectName = (key, projects) => {
  const rec = (projects || []).find(x => x.id === +key);

  return rec ? { name: rec.name, id: rec.id } : '';
};

const getFilterDateDailyData = (type, dailyData, filterDates, name, id, cloudPlatform) => {
  dailyData.forEach(daily_date => {
    const date = getDate(daily_date.key_as_string);
    const index = findRec(filterDates, date);
    const percent = getPercentage(daily_date.sum.value, daily_date.avg.value);
    index > -1 &&
      //(percent < -30 || percent > 30) &&
      daily_date.sum.value >= 1 &&
      filterDates[index].data.push({
        type: type,
        name: name,
        cost: daily_date.sum.value || 0,
        costDiff: daily_date.sum.value - daily_date.avg.value,
        percent: percent,
        id: id || name,
        startDate: filterDates[index].date,
        endDate: filterDates[index].date,
        startDate_props: changeDateToStringFormat(filterDates[index].key),
        endDate_props: changeDateToStringFormat(filterDates[index].key),
        cloudPlatform,
      });
  });

  return filterDates;
};

export const setInitialDailyData = (projects, data, startDate, endDate) => {
  if (isEmpty(data)) {
    return [];
  }

  let {
    arp_cm_accounts = {},
    arp_cm_operation = {},
    arp_cm_products = {},
    arp_cm_regions = {},
    arp_cm_usagetype = {},
  } = data;
  const { arp_billing_type_change_management = {} } = data;

  if (Object.keys(arp_billing_type_change_management).length) {
    const resCl = mapCloudPlatform(arp_billing_type_change_management);
    ({ arp_cm_accounts, arp_cm_operation, arp_cm_products, arp_cm_regions, arp_cm_usagetype } = resCl);
  }

  if (
    !arp_cm_accounts?.buckets?.length &&
    !arp_cm_operation?.arp_cm_data?.buckets?.length &&
    !arp_cm_products?.buckets?.length &&
    !arp_cm_regions?.buckets?.length &&
    !arp_cm_usagetype?.arp_cm_data?.buckets?.length
  ) {
    return [];
  }

  let filterDates = getFilteredDates(startDate, endDate) || [];
  const dateFormat = 'ddd, MMM DD, YYYY';
  filterDates = (filterDates || []).map(x => ({
    key: x,
    data: [],
    date: moment(x, FILTER_DATE_FORMAT).format(dateFormat),
    page: 1,
    records: 10,
  }));

  arp_cm_accounts.buckets.forEach(a => {
    const dailyData = a?.arp_cm_cost_data?.arp_cm_cost_results?.daily_data?.buckets || [];
    const projectName = getProjectName(a.key, projects);
    filterDates = getFilterDateDailyData(
      COST_TYPE.AWS_ACCOUNTS,
      dailyData,
      filterDates,
      projectName.name,
      projectName.id,
      a.cloudPlatform,
    );
  });

  arp_cm_products.buckets.forEach(o => {
    const dailyData = o?.arp_cm_cost_data?.arp_cm_cost_results?.daily_data?.buckets || [];
    filterDates = getFilterDateDailyData(COST_TYPE.AWS_PRODUCTS, dailyData, filterDates, o.key, o.key, o.cloudPlatform);
  });

  arp_cm_regions.buckets.forEach(o => {
    const dailyData = o?.arp_cm_cost_data?.arp_cm_cost_results?.daily_data?.buckets || [];
    filterDates = getFilterDateDailyData(COST_TYPE.REGIONS, dailyData, filterDates, o.key, o.key, o.cloudPlatform);
  });

  arp_cm_operation.arp_cm_data.buckets.forEach(o => {
    const dailyData = o?.arp_cm_cost_results?.daily_data?.buckets || [];
    filterDates = getFilterDateDailyData(COST_TYPE.OPERATIONS, dailyData, filterDates, o.key, o.key, o.cloudPlatform);
  });

  arp_cm_usagetype.arp_cm_data.buckets.forEach(o => {
    const dailyData = o?.arp_cm_cost_results?.daily_data?.buckets || [];
    filterDates = getFilterDateDailyData(COST_TYPE.USAGE_TYPES, dailyData, filterDates, o.key, o.key, o.cloudPlatform);
  });

  filterDates = orderBy(filterDates, 'key', 'desc');

  const collapse = [];
  filterDates.forEach((x, index) => {
    collapse[index] = x.key;
  });

  return { filterDates, collapse };
};

const getFilterDateMonthData = (type, dailyData, filterDates, endDate, name = '', id = '', cloudPlatform) => {
  dailyData.forEach(monthly_date => {
    const date = getDate(monthly_date.key_as_string);

    for (let i = 0, len = filterDates.length; i < len - 1; i++) {
      const mtdStartDate = moment(filterDates[i].key, 'YYYY-MM-DD').set({
        date: 1,
      });
      const mtdEndDate = moment(filterDates[i].key, 'YYYY-MM-DD').endOf('M');
      filterDates[i].date =
        moment(mtdStartDate).format('MMM DD, YYYY') + ' - ' + moment(mtdEndDate).format('MMM DD, YYYY');

      if (moment(date, 'YYYY-MM-DD').isSame(moment(filterDates[i].key, 'YYYY-MM-DD'), 'month')) {
        if (
          moment(date, 'YYYY-MM-DD').isBetween(
            changeDateToStringFormat(mtdStartDate),
            changeDateToStringFormat(mtdEndDate),
            null,
            '[]',
          )
        ) {
          const percent = getPercentage(monthly_date.sum.value, monthly_date.avg.value);

          if (
            // (percent < -30 || percent > 30) &&
            monthly_date.sum.value >= 1
          ) {
            filterDates[i].data.push({
              type: type,
              name: name,
              id: id || name,
              cost: monthly_date.sum.value || 0,
              costDiff: monthly_date.sum.value - monthly_date.avg.value,
              percent: percent,
              startDate: moment(mtdStartDate).format('MMM DD, YYYY'),
              endDate: moment(mtdEndDate).format('MMM DD, YYYY'),
              startDate_props: changeDateToStringFormat(mtdStartDate),
              endDate_props: changeDateToStringFormat(mtdEndDate),
              cloudPlatform,
            });
          }
        }

        break;
      }
    }
  });

  return filterDates;
};

const getMTDMonthData = (type, dailyData, filterDates, endDate, name = '', id = '', cloudPlatform) => {
  dailyData.forEach(monthly_date => {
    const date = getDate(monthly_date.key_as_string);
    const mtdStartDate = moment(filterDates.key, 'YYYY-MM-DD').set({ date: 1 });
    const mtdEndDate = moment(endDate, 'YYYY-MM-DD');
    filterDates.date = moment(mtdStartDate).format('MMM DD, YYYY') + ' - ' + moment(mtdEndDate).format('MMM DD, YYYY');

    if (
      moment(date, 'YYYY-MM-DD').isBetween(
        changeDateToStringFormat(mtdStartDate),
        changeDateToStringFormat(mtdEndDate),
        null,
        '[]',
      )
    ) {
      const percent = getPercentage(monthly_date.sum.value, monthly_date.avg.value);

      if (
        //(percent < -30 || percent > 30) &&
        monthly_date.sum.value >= 1
      ) {
        filterDates.data &&
          filterDates.data.push({
            type: type,
            name: name,
            cost: monthly_date.sum.value || 0,
            costDiff: monthly_date.sum.value - monthly_date.avg.value,
            percent: percent,
            startDate: moment(mtdStartDate).format('MMM DD, YYYY'),
            endDate: moment(mtdEndDate).format('MMM DD, YYYY'),
            id: id || name,
            startDate_props: changeDateToStringFormat(date),
            endDate_props: changeDateToStringFormat(endDate),
            cloudPlatform,
          });
      }
    }
  });

  return filterDates;
};

export const setInitialMonthlyData = (projects, data, startDate, endDate) => {
  if (!data.monthly && !data.mtd) {
    return [];
  }

  const { arp_billing_type_change_management: monthly_arp_billing_type_change_management = {} } = data.monthly;
  const { arp_billing_type_change_management: mtd_arp_billing_type_change_management = {} } = data.mtd;
  let {
    arp_cm_accounts: monthly_arp_cm_accounts = {},
    arp_cm_operation: monthly_arp_cm_operation = {},
    arp_cm_products: monthly_arp_cm_products = {},
    arp_cm_regions: monthly_arp_cm_regions = {},
    arp_cm_usagetype: monthly_arp_cm_usagetype = {},
  } = data.monthly;
  let {
    arp_cm_accounts: mtd_arp_cm_accounts = {},
    arp_cm_operation: mtd_arp_cm_operation = {},
    arp_cm_products: mtd_arp_cm_products = {},
    arp_cm_regions: mtd_arp_cm_regions = {},
    arp_cm_usagetype: mtd_arp_cm_usagetype = {},
  } = data.mtd;

  if (Object.keys(mtd_arp_billing_type_change_management).length) {
    const resCloud_mtd = mapCloudPlatform(mtd_arp_billing_type_change_management);
    ({
      arp_cm_accounts: mtd_arp_cm_accounts,
      arp_cm_operation: mtd_arp_cm_operation,
      arp_cm_products: mtd_arp_cm_products,
      arp_cm_regions: mtd_arp_cm_regions,
      arp_cm_usagetype: mtd_arp_cm_usagetype,
    } = resCloud_mtd);

    const resCloud_monthly = mapCloudPlatform(monthly_arp_billing_type_change_management);
    ({
      arp_cm_accounts: monthly_arp_cm_accounts,
      arp_cm_operation: monthly_arp_cm_operation,
      arp_cm_products: monthly_arp_cm_products,
      arp_cm_regions: monthly_arp_cm_regions,
      arp_cm_usagetype: monthly_arp_cm_usagetype,
    } = resCloud_monthly);
  }

  if (
    !monthly_arp_cm_accounts?.buckets?.length &&
    !monthly_arp_cm_products?.buckets?.length &&
    !monthly_arp_cm_regions?.buckets?.length &&
    !monthly_arp_cm_operation?.arp_cm_data?.buckets?.length &&
    !monthly_arp_cm_usagetype?.arp_cm_data?.buckets?.length &&
    !mtd_arp_cm_accounts?.buckets?.length &&
    !mtd_arp_cm_products?.buckets?.length &&
    !mtd_arp_cm_regions?.buckets?.length &&
    !mtd_arp_cm_operation?.arp_cm_data?.buckets?.length &&
    !mtd_arp_cm_usagetype?.arp_cm_data?.buckets?.length
  ) {
    return [];
  }

  let filterDates = getFilteredDates(startDate, endDate) || [];
  filterDates = (filterDates || []).map(x => ({
    key: x,
    data: [],
    date: '',
    page: 1,
    records: 10,
  }));

  monthly_arp_cm_accounts.buckets.forEach(a => {
    const dailyData = a?.arp_cm_cost_data?.arp_cm_cost_results?.daily_data?.buckets || [];
    const projectName = getProjectName(a.key, projects);
    filterDates = getFilterDateMonthData(
      COST_TYPE.AWS_ACCOUNTS,
      dailyData,
      filterDates,
      endDate,
      projectName.name,
      projectName.id,
      a.cloudPlatform,
    );
  });

  monthly_arp_cm_products.buckets.forEach(o => {
    const dailyData = o?.arp_cm_cost_data?.arp_cm_cost_results?.daily_data?.buckets || [];
    filterDates = getFilterDateMonthData(
      COST_TYPE.AWS_PRODUCTS,
      dailyData,
      filterDates,
      endDate,
      o.key,
      o.key,
      o.cloudPlatform,
    );
  });

  monthly_arp_cm_regions.buckets.forEach(o => {
    const dailyData = o?.arp_cm_cost_data?.arp_cm_cost_results?.daily_data?.buckets || [];
    filterDates = getFilterDateMonthData(
      COST_TYPE.REGIONS,
      dailyData,
      filterDates,
      endDate,
      o.key,
      o.key,
      o.cloudPlatform,
    );
  });

  monthly_arp_cm_operation.arp_cm_data.buckets.forEach(o => {
    const dailyData = o?.arp_cm_cost_results?.daily_data?.buckets || [];
    filterDates = getFilterDateMonthData(
      COST_TYPE.OPERATIONS,
      dailyData,
      filterDates,
      endDate,
      o.key,
      o.key,
      o.cloudPlatform,
    );
  });

  monthly_arp_cm_usagetype.arp_cm_data.buckets.forEach(o => {
    const dailyData = o?.arp_cm_cost_results?.daily_data?.buckets || [];
    filterDates = getFilterDateMonthData(
      COST_TYPE.USAGE_TYPES,
      dailyData,
      filterDates,
      endDate,
      o.key,
      o.key,
      o.cloudPlatform,
    );
  });

  mtd_arp_cm_accounts.buckets.forEach(a => {
    const dailyData = a?.arp_cm_cost_data?.arp_cm_cost_results?.daily_data?.buckets || [];
    const projectName = getProjectName(a.key, projects);
    filterDates[filterDates.length - 1] = getMTDMonthData(
      COST_TYPE.AWS_ACCOUNTS,
      dailyData,
      filterDates[filterDates.length - 1],
      endDate,
      projectName.name,
      projectName.id,
      a.cloudPlatform,
    );
  });

  mtd_arp_cm_products.buckets.forEach(o => {
    const dailyData = o?.arp_cm_cost_data?.arp_cm_cost_results?.daily_data?.buckets || [];
    filterDates[filterDates.length - 1] = getMTDMonthData(
      COST_TYPE.AWS_PRODUCTS,
      dailyData,
      filterDates[filterDates.length - 1],
      endDate,
      o.key,
      o.key,
      o.cloudPlatform,
    );
  });

  mtd_arp_cm_regions.buckets.forEach(o => {
    const dailyData = o?.arp_cm_cost_data?.arp_cm_cost_results?.daily_data?.buckets || [];
    filterDates[filterDates.length - 1] = getMTDMonthData(
      COST_TYPE.REGIONS,
      dailyData,
      filterDates[filterDates.length - 1],
      endDate,
      o.key,
      o.key,
      o.cloudPlatform,
    );
  });

  mtd_arp_cm_operation.arp_cm_data.buckets.forEach(o => {
    const dailyData = o?.arp_cm_cost_results?.daily_data?.buckets || [];
    filterDates[filterDates.length - 1] = getMTDMonthData(
      COST_TYPE.OPERATIONS,
      dailyData,
      filterDates[filterDates.length - 1],
      endDate,
      o.key,
      o.key,
      o.cloudPlatform,
    );
  });

  mtd_arp_cm_usagetype.arp_cm_data.buckets.forEach(o => {
    const dailyData = o?.arp_cm_cost_results?.daily_data?.buckets || [];
    filterDates[filterDates.length - 1] = getMTDMonthData(
      COST_TYPE.USAGE_TYPES,
      dailyData,
      filterDates[filterDates.length - 1],
      endDate,
      o.key,
      o.key,
      o.cloudPlatform,
    );
  });

  filterDates = orderBy(filterDates, 'key', 'desc');

  const collapse = [];
  filterDates.forEach((x, index) => {
    collapse[index] = x.key;
  });

  return { filterDates, collapse };
};

const getFilterDateWeekData = (type, dailyData, filterDates, name = '', id = '', cloudPlatform) => {
  dailyData.forEach(weekly_date => {
    const date = getDate(weekly_date.key_as_string);
    const eDate = moment(date, 'YYYY-MM-DD').add(6, 'days');

    const mtdEndDate = !moment(eDate).isSameOrAfter(TODAY)
      ? eDate.format('MMM DD, YYYY')
      : YESTERDAY.format('MMM DD, YYYY');
    const mtdStartDate = moment(date, 'YYYY-MM-DD').format('MMM DD, YYYY');

    const dataToPush = {
      type: type,
      name: name,
      id: id || name,
      cost: weekly_date.sum.value || 0,
      costDiff: weekly_date.sum.value - weekly_date.avg.value,
      percent: getPercentage(weekly_date.sum.value, weekly_date.avg.value),
      startDate: mtdStartDate,
      endDate: mtdEndDate,
      startDate_props: changeDateToStringFormat(date),
      endDate_props: changeDateToStringFormat(eDate),
      cloudPlatform,
    };
    //const percent = getPercentage(weekly_date.sum.value, weekly_date.avg.value);
    const index = filterDates.findIndex(f => f.key === date);
    const formattedDate = mtdStartDate + ' - ' + mtdEndDate;

    if (
      //(percent < -30 || percent > 30) &&
      weekly_date.sum.value >= 1
    ) {
      if (index !== -1) {
        filterDates[index].date = formattedDate;
        filterDates[index].data.push(dataToPush);
      } else {
        filterDates.push({
          key: date,
          data: [dataToPush],
          date: formattedDate,
          page: 1,
          records: 10,
        });
      }
    }
  });

  return filterDates;
};

export const setInitialWeeklyData = (projects, data, endDate) => {
  const { arp_billing_type_change_management = {} } = data;
  let {
    arp_cm_accounts = {},
    arp_cm_operation = {},
    arp_cm_products = {},
    arp_cm_regions = {},
    arp_cm_usagetype = {},
  } = data;

  if (Object.keys(arp_billing_type_change_management).length) {
    const resCl = mapCloudPlatform(arp_billing_type_change_management);
    ({ arp_cm_accounts, arp_cm_operation, arp_cm_products, arp_cm_regions, arp_cm_usagetype } = resCl);
  }

  if (
    !arp_cm_accounts?.buckets?.length &&
    !arp_cm_operation?.arp_cm_data?.buckets?.length &&
    !arp_cm_products?.buckets?.length &&
    !arp_cm_regions?.buckets?.length &&
    !arp_cm_usagetype?.arp_cm_data?.buckets?.length
  ) {
    return [];
  }

  let filterDates = [];

  arp_cm_accounts.buckets.forEach(a => {
    const dailyData = a?.arp_cm_cost_data?.arp_cm_cost_results?.daily_data?.buckets || [];
    const projectName = getProjectName(a.key, projects);
    filterDates = getFilterDateWeekData(
      COST_TYPE.AWS_ACCOUNTS,
      dailyData,
      filterDates,
      projectName.name,
      projectName.id,
      a.cloudPlatform,
    );
  });

  arp_cm_products.buckets.forEach(o => {
    const dailyData = o?.arp_cm_cost_data?.arp_cm_cost_results?.daily_data?.buckets || [];
    filterDates = getFilterDateWeekData(COST_TYPE.AWS_PRODUCTS, dailyData, filterDates, o.key, o.key, o.cloudPlatform);
  });

  arp_cm_regions.buckets.forEach(o => {
    const dailyData = o?.arp_cm_cost_data?.arp_cm_cost_results?.daily_data?.buckets || [];
    filterDates = getFilterDateWeekData(COST_TYPE.REGIONS, dailyData, filterDates, o.key, o.key, o.cloudPlatform);
  });

  arp_cm_operation.arp_cm_data.buckets.forEach(o => {
    const dailyData = o?.arp_cm_cost_results?.daily_data?.buckets || [];
    filterDates = getFilterDateWeekData(COST_TYPE.OPERATIONS, dailyData, filterDates, o.key, o.key, o.cloudPlatform);
  });

  arp_cm_usagetype.arp_cm_data.buckets.forEach(o => {
    const dailyData = o?.arp_cm_cost_results?.daily_data?.buckets || [];
    filterDates = getFilterDateWeekData(COST_TYPE.USAGE_TYPES, dailyData, filterDates, o.key, o.key, o.cloudPlatform);
  });

  filterDates = orderBy(filterDates, 'key', 'desc');

  const collapse = [];
  filterDates.forEach((x, index) => {
    collapse[index] = x.key;
  });

  return { filterDates, collapse };
};

export const getTextToShow = (
  data,
  selectedTab,
  azure_billing_currency = 'USD',
  isMultipleCurrency = false,
  isAWSSelected = true,
) => {
  const percent = PercentageCell(data.percent);

  const key =
    selectedTab === 'Daily'
      ? ' daily '
      : selectedTab === 'Weekly'
        ? ' weekly '
        : selectedTab === 'Monthly'
          ? ' monthly '
          : '';
  const keyOrder = data.costDiff > 0 ? 'increased' : 'decreased';

  const currencyCodeFormat = value =>
    currencyFormatterByCode(isMultipleCurrency, isAWSSelected, value, azure_billing_currency);

  switch (data.type) {
    case COST_TYPE.AWS_ACCOUNTS: {
      const isDemoMode = sessionStorage.getItem('demo-mode') === 'on';

      return (
        <div>
          <span className={isDemoMode ? 'demo-mode-black' : ''}>{data.name}</span>, <b>Cloud Account's</b> {key} cost{' '}
          {keyOrder} by
          <b> {currencyCodeFormat(Math.abs(data.costDiff))}</b>({percent}), the new cost is
          <b> {currencyCodeFormat(data.cost)}</b>
        </div>
      );
    }

    case COST_TYPE.AWS_PRODUCTS:
      return (
        <div>
          {data.name}, <b>Cloud Service's</b> {key} cost {keyOrder} by
          <b> {currencyCodeFormat(Math.abs(data.costDiff))}</b>({percent}), the new cost is
          <b> {currencyCodeFormat(data.cost)}</b>
        </div>
      );
    case COST_TYPE.REGIONS:
      return (
        <div>
          {data.name}, <b>Region's</b> {key} cost {keyOrder} by
          <b> {currencyCodeFormat(Math.abs(data.costDiff))}</b>({percent}), the new cost is
          <b> {currencyCodeFormat(data.cost)}</b>
        </div>
      );
    case COST_TYPE.OPERATIONS:
      return (
        <div>
          {data.name}, <b>Operation's</b> {key} cost {keyOrder} by
          <b> {currencyCodeFormat(Math.abs(data.costDiff))}</b>({percent}), the new cost is
          <b> {currencyCodeFormat(data.cost)}</b>
        </div>
      );
    case COST_TYPE.USAGE_TYPES:
      return (
        <div>
          {data.name}, <b>Usage Type's</b> {key} cost {keyOrder} by
          <b> {currencyCodeFormat(Math.abs(data.costDiff))}</b>({percent}), the new cost is
          <b> {currencyCodeFormat(data.cost)}</b>
        </div>
      );
    default:
      break;
  }
};

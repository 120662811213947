import forIn from 'lodash/forIn';
import isEmpty from 'lodash/isEmpty';

export const getURLParams = (params, apiMigration) => {
  if (isEmpty(params)) {
    return {};
  }

  const paramsOption = new URLSearchParams();
  forIn(params, (value, key) => {
    if (
      [
        'selectedRange',
        'usage_chart',
        'unallocatedDetail',
        'isShowbackView',
        'saveDateRange',
        'excludeIds',
        'viewBy',
      ].includes(key) ||
      (apiMigration && ['include_allocation_rule_id', 'distribution_rule_id', 'allocation_rule_id'].includes(key))
    ) {
      return;
    }

    if (['string', 'boolean', 'number'].includes(typeof value)) {
      paramsOption.append(key, value);
    }

    if (typeof value === 'object' && value?.length) {
      if (['tag_keys', 'tag_keys_and'].includes(key)) {
        value.forEach(item => {
          const itemValue = item.value === 'null' ? '' : item.value;
          const itemTagValue = item.tag_value === 'null' ? '' : item.tag_value;

          if ((item?.type && !item.tag_value) || (item?.contain && !item.tag_key)) {
            if (item?.contain === 'like' || item?.contain === 'not_like') {
              paramsOption.append(`${item.contain}_${key}`, itemValue);
            } else {
              paramsOption.append(item.type !== 'Include' ? `exclude_${key}` : key, itemValue);
            }
          } else {
            const newKey = key === 'tag_keys' ? 'tag' : 'tag_and';

            if (item?.contain === 'like' || item?.contain === 'not_like') {
              paramsOption.append(`${item.contain}_${newKey}`, JSON.stringify({ [itemValue]: itemTagValue }));
            } else {
              paramsOption.append(
                item.type !== 'Include' ? `exclude_${newKey}` : newKey,
                JSON.stringify({ [itemValue]: itemTagValue }),
              );
            }
          }
        });
      } else if (['label_keys', 'label_keys_and'].includes(key)) {
        value.forEach(item => {
          const itemValue = item.value === 'null' ? '' : item.value;
          const itemLabelValue = item.label_value === 'null' ? '' : item.label_value;

          if ((item?.type && !item.label_value) || (item?.contain && !item.label_key)) {
            if (item?.contain === 'like' || item?.contain === 'not_like') {
              paramsOption.append(`${item.contain}_${key}`, itemValue);
            } else {
              paramsOption.append(item.type !== 'Include' ? `exclude_${key}` : key, itemValue);
            }
          } else {
            const newKey = key === 'label_keys' ? 'label' : 'label_and';

            if (item?.contain === 'like' || item?.contain === 'not_like') {
              paramsOption.append(`${item.contain}_${newKey}`, JSON.stringify({ [itemValue]: itemLabelValue }));
            } else {
              paramsOption.append(
                item.type !== 'Include' ? `exclude_${newKey}` : newKey,
                JSON.stringify({ [itemValue]: itemLabelValue }),
              );
            }
          }
        });
      } else if (['cost_allocation_tag_keys', 'cost_allocation_tag_keys_and'].includes(key)) {
        value.forEach(item => {
          const itemValue = item.value === 'null' ? '' : item.value;
          const itemLabelValue = item.cost_allocation_tag_value === 'null' ? '' : item.cost_allocation_tag_value;

          if ((item?.type && !item.cost_allocation_tag_value) || (item?.contain && !item.cost_allocation_tag_keys)) {
            if (item?.contain === 'like' || item?.contain === 'not_like') {
              paramsOption.append(`${item.contain}_${key}`, itemValue);
            } else {
              paramsOption.append(item.type !== 'Include' ? `exclude_${key}` : key, itemValue);
            }
          } else {
            const newKey = key === 'cost_allocation_tag_keys' ? 'cost_allocation_tag' : 'cost_allocation_tag_and';

            if (item?.contain === 'like' || item?.contain === 'not_like') {
              paramsOption.append(`${item.contain}_${newKey}`, JSON.stringify({ [itemValue]: itemLabelValue }));
            } else {
              paramsOption.append(
                item.type !== 'Include' ? `exclude_${newKey}` : newKey,
                JSON.stringify({ [itemValue]: itemLabelValue }),
              );
            }
          }
        });
      } else if (key === 'showback_value') {
        value.forEach(item => {
          const itemValue = item.value === 'Unallocated' ? '' : item.value;
          paramsOption.append(item.type !== 'Include' ? `exclude_${key}` : key, itemValue);
        });
      } else {
        value.forEach(item => {
          const itemValue = item.value === 'null' ? '' : item.value;

          if (item?.contain === 'like' || item?.contain === 'not_like') {
            paramsOption.append(`${item.contain}_${key}`, itemValue);
          } else {
            paramsOption.append(item.type !== 'Include' ? `exclude_${key}` : key, itemValue);
          }
        });
      }
    }
  });

  return paramsOption;
};

export const getComplexUrlParams = params => {
  if (!params || typeof params !== 'object' || Array.isArray(params)) {
    return new URLSearchParams();
  }

  const paramsOption = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (['string', 'boolean', 'number'].includes(typeof value)) {
      paramsOption.append(key, value);

      return;
    }

    if (Array.isArray(value)) {
      value.forEach(item => paramsOption.append(key, item));
    }
  });

  return paramsOption;
};

export const getFormData = (params, apiMigration) => {
  const formData = new FormData();

  if (isEmpty(params)) {
    return formData;
  }

  forIn(params, (value, key) => {
    if (
      [
        'selectedRange',
        'usage_chart',
        'unallocatedDetail',
        'isShowbackView',
        'saveDateRange',
        'excludeIds',
        'viewBy',
      ].includes(key) ||
      (apiMigration && ['include_allocation_rule_id', 'distribution_rule_id', 'allocation_rule_id'].includes(key))
    ) {
      return;
    }

    if (['string', 'boolean', 'number'].includes(typeof value)) {
      formData.append(key, value);
    }

    if (typeof value === 'object' && value?.length) {
      if (['tag_keys', 'tag_keys_and'].includes(key)) {
        value.forEach(item => {
          const itemValue = item.value === 'null' ? '' : item.value;
          const itemTagValue = item.tag_value === 'null' ? '' : item.tag_value;

          if ((item?.type && !itemTagValue) || (item?.contain && !item.tag_key)) {
            if (item?.contain === 'like' || item?.contain === 'not_like') {
              formData.append(`${item.contain}_${key}`, itemValue);
            } else {
              formData.append(item.type !== 'Include' ? `exclude_${key}` : key, itemValue);
            }
          } else {
            const newKey = key === 'tag_keys' ? 'tag' : 'tag_and';

            if (item?.contain === 'like' || item?.contain === 'not_like') {
              formData.append(`${item.contain}_${newKey}`, JSON.stringify({ [itemValue]: itemTagValue }));
            } else {
              formData.append(
                item.type !== 'Include' ? `exclude_${newKey}` : newKey,
                JSON.stringify({ [itemValue]: itemTagValue }),
              );
            }
          }
        });
      } else if (['label_keys', 'label_keys_and'].includes(key)) {
        value.forEach(item => {
          const itemValue = item.value === 'null' ? '' : item.value;
          const itemLabelValue = item.label_value === 'null' ? '' : item.label_value;

          if ((item?.type && !itemLabelValue) || (item?.contain && !item.label_key)) {
            if (item?.contain === 'like' || item?.contain === 'not_like') {
              formData.append(`${item.contain}_${key}`, itemValue);
            } else {
              formData.append(item.type !== 'Include' ? `exclude_${key}` : key, itemValue);
            }
          } else {
            const newKey = key === 'label_keys' ? 'label' : 'label_and';

            if (item?.contain === 'like' || item?.contain === 'not_like') {
              formData.append(`${item.contain}_${newKey}`, JSON.stringify({ [itemValue]: itemLabelValue }));
            } else {
              formData.append(
                item.type !== 'Include' ? `exclude_${newKey}` : newKey,
                JSON.stringify({ [itemValue]: itemLabelValue }),
              );
            }
          }
        });
      } else if (['cost_allocation_tag_keys', 'cost_allocation_tag_keys_and'].includes(key)) {
        value.forEach(item => {
          const itemValue = item.value === 'null' ? '' : item.value;
          const itemLabelValue = item.cost_allocation_tag_value === 'null' ? '' : item.cost_allocation_tag_value;

          if ((item?.type && !itemLabelValue) || (item?.contain && !item.cost_allocation_tag_key)) {
            if (item?.contain === 'like' || item?.contain === 'not_like') {
              formData.append(`${item.contain}_${key}`, itemValue);
            } else {
              formData.append(item.type !== 'Include' ? `exclude_${key}` : key, itemValue);
            }
          } else {
            const newKey = key === 'cost_allocation_tag_keys' ? 'cost_allocation_tag' : 'cost_allocation_tag_and';

            if (item?.contain === 'like' || item?.contain === 'not_like') {
              formData.append(`${item.contain}_${newKey}`, JSON.stringify({ [itemValue]: itemLabelValue }));
            } else {
              formData.append(
                item.type !== 'Include' ? `exclude_${newKey}` : newKey,
                JSON.stringify({ [itemValue]: itemLabelValue }),
              );
            }
          }
        });
      } else if (key === 'showback_value') {
        value.forEach(item => {
          const itemValue = item.value === 'Unallocated' ? '' : item.value;
          formData.append(item.type !== 'Include' ? `exclude_${key}` : key, itemValue);
        });
      } else {
        value.forEach(item => {
          const itemValue = item.value === 'null' ? '' : item.value;

          if (item?.contain === 'like' || item?.contain === 'not_like') {
            formData.append(`${item.contain}_${key}`, itemValue);
          } else {
            formData.append(item.type !== 'Include' ? `exclude_${key}` : key, itemValue);
          }
        });
      }
    }
  });

  return formData;
};

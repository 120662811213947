import React from 'react';
import {
  AsgEngineIcon,
  AwsInventoryIcon,
  AWSMapIcon,
  BillingAccountIcon,
  ChargebackIcon,
  CISReportIcon,
  CloudResourcesCostIcon,
  CmEngineIcon,
  CustomDashboardIcon,
  ReportIcon,
  DashboardIcon,
  DataExplorerIcon,
  HelpDocsIcon,
  HippaReportIcon,
  InstancesIcon,
  NavSecurityIcon,
  NKSIcon,
  NopsNewIcon,
  RightsizingIcon,
  RiUsageIcon,
  RuleChangesIcon,
  SchedulerIcon,
  Soc2ReportIcon,
  StorageIcon,
  SupportChatIcon,
  WarReportsIcon,
} from 'assets/images';
import { OperationNameIcon } from 'assets/images/cost';
import { PAGE_SUBTITLE, PAGE_TITLE, ROUTES_PATH } from 'utils/constants';
import { hasWorkloadV1 } from 'utils/common';
import { getEnv, PRODUCTION_ENV } from 'utils/env';

export const CUSTOM_MESSAGES = {
  set_jira_error: 'Failed to integrate Jira OAuth2.',
  access_link_error: 'Jira OAuth2 link is not configured or Invalid!',
  delete_jira: 'Successfully deleted Jira OAuth2 settings.',
  delete_jira_error: 'Failed to delete Jira OAuth2 settings!',
  set_jira_workspace_error: 'Failed to set the selected Jira Workspace',
};

const env = getEnv();
export const securityUrl = env === PRODUCTION_ENV ? '/v3/security-dashboard/45' : '/v3/security-dashboard/4845';
export const hipaaUrl = env === PRODUCTION_ENV ? '/v3/hipaa-dashboard/49' : '/v3/hipaa-dashboard/6768';
export const soc2Url = env === PRODUCTION_ENV ? '/v3/soc2-dashboard/52' : '/v3/soc2-dashboard/7115';
export const cisUrl = env === PRODUCTION_ENV ? '/v3/cis-dashboard/1337' : '/v3/cis-dashboard/10329';

export const bcDashboardLinks = [
  {
    path: '/v3/usage-report/dashboard/',
    title: 'Usage Dashboard',
    description: 'Visual summary of key metrics and data, designed for quick analysis and decision-making.',
    icon: <DashboardIcon />,
  },
  {
    path: '/v3/dashboards/',
    title: 'Dashboards',
    description:
      'Customised dashboard tailors key metrics and visuals to specific needs, offering personalized insights.',
    icon: <DashboardIcon />,
    isBCPlus: true,
  },
];
export const routes = {
  dashboardLinks: [
    {
      path: '/v3/usage-report/dashboard/',
      title: 'Dashboard',
      icon: <DashboardIcon />,
    },
  ],
  showbackLinks: [
    {
      path: '/v3/cloud-resource-cost/',
      title: 'Cost Analysis',
      description: 'Deeper analysis of cost trends over time by Showbacks.',
      icon: <CloudResourcesCostIcon />,
    },
    {
      path: '/v3/cost/showbacks/',
      title: 'Cost Allocation',
      description: 'Give Business Context to every dollar of AWS spend via Showbacks.',
      icon: <BillingAccountIcon />,
    },
    {
      path: `/v3/cost/chargebacks-center/`,
      title: 'Chargebacks Center',
      description: 'Enforce accountability for Cloud costs by charging them back to teams and projects.',
      icon: <ChargebackIcon />,
    },
    {
      path: '/v3/cost/tag-explorer/',
      title: 'Tag Explorer',
      description: 'Explore your tag competency across all of clouds to maintain hygiene.',
      icon: <OperationNameIcon />,
    },
    {
      path: '/v3/cost/reserved/',
      title: 'Commitment Inventory',
      description: 'View and manage RI planning, RI recommendation, RI Usage and savings plan recommendation.',
      icon: <RiUsageIcon />,
    },
    {
      isTarget: true,
    },
    {
      path: '/v3/cloud-resource-cost-api/',
      title: 'Cost Analysis',
      description: 'Deeper analysis of cost trends over time by Showbacks.',
      icon: <CloudResourcesCostIcon />,
      isBCPlus: true,
    },
    {
      path: '/v3/cost/showbacks-api/',
      title: 'Cost Allocation',
      description: 'Give Business Context to every dollar of AWS spend via Showbacks.',
      icon: <BillingAccountIcon />,
      isBCPlus: true,
    },
    {
      path: '/v3/reports-api/',
      title: 'Cost Analysis Reports',
      description: 'Manage your cost analysis reports and set notifications on the go.',
      icon: <ReportIcon />,
      svgClass: 'svg-transparent',
      isBCPlus: true,
    },
    {
      path: '/v3/targets-api/',
      title: 'Cost Analysis Targets',
      description: 'Manage your cost targets',
      icon: <ChargebackIcon />,
      isBCPlus: true,
      svgClass: 'svg-transparent',
      isTarget: true,
    },
    {
      path: '/v3/graphql-ide/',
      title: 'Data Explorer',
      description: 'Query your cloud resources with the help of nOps Data Explorer powered by GraphQL.',
      icon: <DataExplorerIcon />,
    },
    {
      path: '/v3/search/',
      title: 'Cloud Inventory',
      description: 'Query, filter and navigate all of your entire Cloud accounts and resources in one place.',
      icon: <AwsInventoryIcon />,
    },
  ],
  nSwitchLinks: [
    {
      path: '/v3/essentials/summary/',
      title: PAGE_TITLE.ESSENTIALS_SHARESAVE,
      description: PAGE_SUBTITLE.ESSENTIALS_SHARESAVE,
      icon: <BillingAccountIcon />,
      isNew: false,
    },
    {
      path: ROUTES_PATH.RIGHTSIZING_DASHBOARD,
      title: 'Rightsizing',
      description: 'Optimizing resources and expenses to align with current needs efficiently',
      icon: <RightsizingIcon />,
      isNew: false,
    },
    {
      path: ROUTES_PATH.SCHEDULER_DASHBOARD,
      title: 'Scheduler',
      description: 'Automates AWS resource scheduling, optimizes costs efficiently',
      icon: <SchedulerIcon />,
    },
    {
      path: ROUTES_PATH.IDLE_RESOURCES_DASHBOARD,
      title: 'Idle Resources',
      description: 'Regularly reviewing and stopping resources based on usage patterns',
      icon: <InstancesIcon />,
      isNew: false,
    },
    {
      path: ROUTES_PATH.STORAGE_DASHBOARD,
      title: 'Storage',
      description: 'One-click automation for Storage Optimization using EventBridge or Storage Changeset',
      icon: <StorageIcon />,
      isNew: false,
    },
  ],
  computeCopilotLinks: [
    {
      path: '/v3/compute-copilot/summary/',
      title: PAGE_TITLE.COMPUTE_COPILOT_SHARESAVE,
      description: PAGE_SUBTITLE.COMPUTE_COPILOT_SHARESAVE,
      icon: <BillingAccountIcon />,
      isNew: false,
    },
    {
      path: ROUTES_PATH.CM_DASHBOARD,
      title: PAGE_TITLE.CM,
      description: PAGE_SUBTITLE.CM,
      icon: <CmEngineIcon className="fill-none" />,
      isNew: false,
    },
    {
      path: ROUTES_PATH.ASG_DASHBOARD,
      title: 'Auto Scaling Groups',
      description: 'Configure your Auto Scaling Groups and reduce your costs with Compute Copilot.',
      icon: <AsgEngineIcon className="fill-none" />,
    },
    {
      path: ROUTES_PATH.EKS_DASHBOARD,
      title: 'EKS',
      description: 'Configure your EKS Clusters and reduce your costs with Compute Copilot.',
      icon: <NKSIcon />,
    },
  ],
  workloadLinks: profile => {
    const workload = [
      {
        path: '/v3/workload-v2/',
        title: 'WAFR',
        description:
          "Create workloads to group resources, get Bird's-eye view of your workload VPCs in diagram and start assessments or see reports.",
        icon: <WarReportsIcon />,
      },
      {
        path: '/v3/war/',
        title: 'WAFR Report',
        description:
          'Search, group and customize Well-Architected Framework Review findings into a report you can download and share.',
        icon: <WarReportsIcon />,
      },
      {
        path: '/v3/cost/map-migrated/',
        title: 'AWS MAP',
        description:
          'Track Cost and Usage Report (CUR) based on the migrated resources to get migrated workloads’ spend and generate appropriate credits.',
        icon: <AWSMapIcon />,
      },
      {
        path: '/v3/rules/',
        title: 'nOps Rules',
        description: 'Measure, navigate and control cost changes to prevent runaway costs and avoid cloud bill shock.',
        icon: <NopsNewIcon />,
      },
      {
        path: '/v3/rules/?tab=Change%20Management',
        title: 'nOps Rules Change Management',
        description: 'Create and manage nOps rules that automatically track changes across your cloud.',
        icon: <RuleChangesIcon />,
      },
      {
        path: '/v3/dashboard/list/',
        title: 'Custom Dashboard',
        description:
          'Build your own Well-Architected dashboard to focus on and share what’s important for you to communicate to your team.',
        icon: <CustomDashboardIcon />,
      },
    ];

    if (hasWorkloadV1(profile?.email)) {
      return [
        {
          path: '/v3/workload/',
          title: 'Workloads v1',
          description: 'Create workloads to group resources and start assessments or see reports',
          icon: <WarReportsIcon />,
        },
        ...workload,
      ];
    }

    return workload;
  },
  complianceLinks: [
    {
      path: soc2Url,
      title: 'SOC 2 Readiness Report',
      description: 'Check the SOC2 readiness and violations of your AWS resources and download an Excel report.',
      icon: <Soc2ReportIcon />,
    },
    {
      path: hipaaUrl,
      title: 'HIPAA Readiness Report',
      description: 'Use this dashboard to work towards HIPAA compliance on your AWS accounts and download a report.',
      icon: <HippaReportIcon />,
    },
    {
      path: cisUrl,
      title: 'CIS Readiness Report',
      description:
        'Live dashboard and downloadable report of security industry CIS 7.1 standard applied to your AWS resources.',
      icon: <CISReportIcon />,
    },
    {
      path: securityUrl,
      title: 'Security Report',
      description: 'The security pillar focuses on protecting information & systems. ',
      icon: <NavSecurityIcon />,
    },
  ],
  helpCenter: [
    {
      to: 'https://help.nops.io/',
      title: 'Go to Help Docs',
      icon: <HelpDocsIcon />,
    },
    {
      to: '',
      title: 'Start Chat with Support',
      icon: <SupportChatIcon />,
      customHandler: () => {
        window.Intercom('showNewMessage', 'Contact nOps Support: ');
      },
    },
  ],
};

export const rbacWithBCPlus = {
  dashboardLinks: [
    {
      path: '/v3/dashboards/',
      title: 'Dashboards',
      icon: <DashboardIcon />,
      isBCPlus: true,
    },
  ],
  showbackLinks: [
    {
      path: '/v3/cloud-resource-cost-api/',
      title: 'Cost Analysis',
      description: 'Deeper analysis of cost trends over time by Showbacks.',
      icon: <CloudResourcesCostIcon />,
      isBCPlus: true,
    },
    {
      path: '/v3/cost/showbacks-api/',
      title: 'Cost Allocation',
      description: 'Give Business Context to every dollar of AWS spend via Showbacks.',
      icon: <BillingAccountIcon />,
      isBCPlus: true,
    },
    {
      path: '/v3/reports-api/',
      title: 'Cost Analysis Reports',
      description: 'Manage your cost analysis reports and set notifications on the go.',
      icon: <ChargebackIcon />,
      svgClass: 'svg-transparent',
      isBCPlus: true,
    },
  ],
};

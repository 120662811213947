import axiosObj from './config';

export function loadOverrideHistory(id, partner_scope_id) {
  let url = `/c/ac/overridden-violations/${id}/history/`;

  if (partner_scope_id) {
    url = url + `?partner_scope_id=${partner_scope_id}`;
  }

  return axiosObj({
    method: 'GET',
    url: url,
  });
}

export function loadSearchData(payload) {
  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters: payload,
    },
  });
}

export function loadCSVData(payload) {
  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: payload,
    responseType: 'blob',
  });
}

export function loadSearchResourceData(
  param,
  project_id,
  partner_scope_id,
  isSharesaveScheduler,
  resource_type = null,
) {
  let url = `/c/ac/query/${param}`;

  if (partner_scope_id && project_id) {
    url = url + `?partner_scope_id=${partner_scope_id}&project_ids=${project_id}`;
  }

  if (isSharesaveScheduler) {
    url = url + '?instance_id_filtering=true';
  }

  if (isSharesaveScheduler && resource_type) {
    url = url + `&type=${resource_type}`;
  }

  return axiosObj({
    method: 'GET',
    url,
  });
}

export function loadSearchResourceUsageData(payload, partner_scope_id) {
  let url = '/c/resources/usage/';

  if (partner_scope_id) {
    url = url + `?partner_scope_id=${partner_scope_id}`;
  }

  return axiosObj({
    method: 'GET',
    url,
    params: payload,
  });
}

export function loadSearchResourceTrafficData(payload, partner_scope_id) {
  let url = '/c/resources/elb/traffic';

  if (partner_scope_id) {
    url = url + `?partner_scope_id=${partner_scope_id}`;
  }

  return axiosObj({
    method: 'GET',
    url,
    params: payload,
  });
}

export const loadNatGateWayData = (projectId, params) => {
  return axiosObj({
    method: 'GET',
    url: `/c/flow-logs/${projectId}/network-interface-flow_logs/`,
    params,
  });
};

export const getRecommendationGraph = params => {
  return axiosObj({
    method: 'GET',
    url: `/svc/k8s_cost/recommendations_instance_graph`,
    params,
  });
};

export const getRDSRecommendationGraph = params => {
  return axiosObj({
    method: 'GET',
    url: '/svc/k8s_cost/recommendation_graph',
    params,
  });
};

export const getAutoScalingGraph = params => {
  return axiosObj({
    method: 'GET',
    url: `/svc/k8s_cost/scheduler_dynamic_recommendations_autoscaler_graph`,
    params,
  });
};

export const getAutoScalingGraphV2 = params => {
  return axiosObj({
    method: 'GET',
    url: `/svc/k8s_cost/scheduler_dynamic_recommendations_autoscaler_graph_v2`,
    params,
  });
};

export const getInstanceRecommendations = params => {
  return axiosObj({
    method: 'GET',
    url: `/svc/k8s_cost/instance_recommendation_intervals`,
    params,
  });
};

export const getRDSInstanceRecommendations = params => {
  return axiosObj({
    method: 'GET',
    url: `/svc/k8s_cost/rds_scheduler_instance_graph`,
    params,
  });
};

import { call, put, takeLatest } from 'redux-saga/effects';
import { LOAD_CUSTOM_RULE_REQUEST } from './constants';
import { loadCustomRulesSuccess, loadCustomRulesError } from './actions';
import { getAllRules } from 'api/settings';
import { api_condition_error, api_condition_success, globalShowNotification } from 'containers/App/saga';
import { showToastAction } from 'containers/App/actions';

export function* getRulesData() {
  try {
    const summary = yield call(getAllRules);

    if (api_condition_success(summary.status)) {
      yield put(loadCustomRulesSuccess(summary.data));
    } else if (api_condition_error(summary.status)) {
      yield call(globalShowNotification, summary);
      yield put(loadCustomRulesError());
    }
  } catch (err) {
    yield put(loadCustomRulesError());
    yield put(
      showToastAction({
        message: 'Failed to load custom rules.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export default function* appUpdate() {
  yield takeLatest(LOAD_CUSTOM_RULE_REQUEST, getRulesData);
}

import produce from 'immer';
import {
  LOAD_SEARCH_REQUEST,
  LOAD_SEARCH_SUCCESS,
  LOAD_SEARCH_ERROR,
  LOAD_SEARCH_RESOURCE_DETAIL_REQUEST,
  LOAD_SEARCH_RESOURCE_DETAIL_SUCCESS,
  LOAD_SEARCH_RESOURCE_DETAIL_ERROR,
  LOAD_SEARCH_FILTER_REQUEST,
  LOAD_SEARCH_FILTER_SUCCESS,
  LOAD_SEARCH_FILTER_ERROR,
  LOAD_SEARCH_CM_DATA,
  LOAD_SEARCH_CM_DATA_SUCCESS,
  LOAD_SEARCH_CM_DATA_ERROR,
} from './constants';

export const initialState = {
  loading: false,
  searchData: [],
  warSummary: {},
  searchCounters: {},
  searchCountersCloud: {},
  resourcesDetailData: {},
  rLoading: false,
  defaultSearchCounters: {},
  defaultSearchCountersCloud: {},
  filters: {},
  changes: [],
  cmFilters: {},
  cmLoading: false,
};

const getSearchCounters = aggs => {
  const searchCountersCloud = {
    active: {},
    is_as: {},
    is_ri: {},
    multi_az: {},
    products: {},
    projects: {},
    rds_engine: {},
    regions: {},
  };
  Object.keys(searchCountersCloud).forEach(key => {
    searchCountersCloud[key] = {
      buckets: [
        ...(aggs?.search_counters_cloud_type?.buckets || []).flatMap(cloudBucket => [
          ...(cloudBucket?.search_counters[key]?.buckets || []).map(counterBucket => ({
            ...counterBucket,
            cloudPlatform: cloudBucket.key,
          })),
        ]),
      ],
    };
  });
  searchCountersCloud.cloud_data = (aggs?.search_counters_cloud_type?.buckets || []).map(cloudBucket => ({
    key: cloudBucket.key,
    doc_count: cloudBucket.doc_count,
  }));

  return searchCountersCloud;
};

/* eslint-disable default-case, no-param-reassign */
const homeReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_SEARCH_REQUEST:
        draft.loading = true;
        draft.searchData = [];
        draft.summaryData = {};
        draft.searchCounters = {};
        draft.searchCountersCloud = {};
        draft.filters = {};

        break;
      case LOAD_SEARCH_SUCCESS:
        {
          draft.loading = false;
          draft.filters = action.response?.filters;

          const aggs = action?.response?.data?.aggregations;

          if (aggs?.search_results_list_cloud_type) {
            const search_results_list = (aggs.search_results_list_cloud_type?.buckets || []).flatMap(cloudBucket =>
              (cloudBucket?.search_results_list?.hits?.hits || []).map(data => ({
                ...data,
                cloudPlatform: cloudBucket.key,
              })),
            );
            draft.searchData = {
              data: search_results_list || [],
              total: action?.response?.data?.total || 0,
            };
          } else {
            draft.searchData = {
              data: aggs?.search_results_list?.hits?.hits || [],
              total: action?.response?.data?.total || 0,
            };
          }

          draft.warSummary = aggs?.search_war_summary || {};
          draft.searchCounters = aggs?.search_counters || {};

          if (aggs?.search_counters_cloud_type) {
            draft.searchCountersCloud = getSearchCounters(aggs);
          }
        }

        break;
      case LOAD_SEARCH_ERROR:
        draft.filters = {};
        draft.loading = false;

        break;
      case LOAD_SEARCH_CM_DATA:
        draft.cmLoading = true;
        draft.changes = [];
        draft.cmFilters = {};

        break;
      case LOAD_SEARCH_CM_DATA_SUCCESS:
        draft.cmLoading = false;

        if (action?.response?.data?.violations_changes_cloud_type) {
          const buckets = [];
          (action?.response?.data?.violations_changes_cloud_type?.buckets || []).forEach(cloud => {
            (cloud.violations_changes?.violations_changes_results?.change_log?.buckets || []).forEach(vc => {
              buckets.push({ ...vc, cloudPlatform: cloud.key });
            });
          });

          if (action?.response?.filters?.aggregations) {
            action.response.filters.aggregations = 'violations_changes';
          }

          draft.changes = buckets;
        } else {
          draft.changes =
            action?.response?.data?.violations_changes?.violations_changes_results?.change_log?.buckets || [];
        }

        draft.cmFilters = action?.response?.filters || {};

        break;
      case LOAD_SEARCH_CM_DATA_ERROR:
        draft.cmLoading = false;
        draft.changes = [];
        draft.cmFilters = {};

        break;
      case LOAD_SEARCH_FILTER_REQUEST:
        draft.defaultSearchCounters = {};
        draft.defaultSearchCountersCloud = {};

        break;
      case LOAD_SEARCH_FILTER_SUCCESS:
        if (action?.response?.aggregations?.search_counters_cloud_type) {
          draft.defaultSearchCountersCloud = getSearchCounters(action?.response?.aggregations);
        } else {
          draft.defaultSearchCounters = action?.response?.aggregations?.search_counters;
        }

        break;
      case LOAD_SEARCH_FILTER_ERROR:
        break;
      case LOAD_SEARCH_RESOURCE_DETAIL_REQUEST:
        draft.rLoading = true;
        draft.resourcesDetailData = {};

        break;
      case LOAD_SEARCH_RESOURCE_DETAIL_SUCCESS:
        draft.resourcesDetailData = [...(action?.response?.hits?.hits || [])];
        draft.rLoading = false;

        break;
      case LOAD_SEARCH_RESOURCE_DETAIL_ERROR:
        draft.rLoading = false;

        break;
    }
  });

export default homeReducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCMRulesData, getFilterData, getOverwriteRule, getRulesData } from 'api/rule';
import { numberFix } from 'utils/helper';
import { getEIPData, updatedEIPCost } from './helper/common';
import { getRulesDetail } from '../utils';
import { setInitialViolationData } from './helper/setViolationData';
import { extractViolationChanges } from 'utils/helper';
import isEmpty from 'lodash/isEmpty';
import { RULES_DESCRIPTION_TEXT } from 'utils/RuleDetail';

export const initialState = {
  rulesMapping: {},
  counters: {},
  countersCloud: {},
  rule_key: '',
  rule_service: '',
  loading: false,
  is_cost: false,
  countData: {
    count: 0,
    lastCount: 0,
  },
  costData: {
    cost: 0,
    lastCost: 0,
    nextCost: 0,
  },
  eipDetails: {
    eipCost: 0,
    eipData: [],
  },
  cmLoading: false,
  changeDataDetail: {},
  activeSorter: 'all',
  activeResourceSorter: 'active',
  selectedMonthFilter: 'months_1',
  showModal: {},
  payload: {},
  activeSubTab: { key: 'ebs_unattached', value: 'Unattached EBS', type: 'ebs' },
  overrideLoading: false,
  overridePayload: {},
  overrideData: [],
};

const SLICE_NAME = 'RULE_DETAILS';

export const loadRuleDetailData = createAsyncThunk(
  `${SLICE_NAME}/loadRuleDetailData`,
  async (payload, { getState }) => {
    const {
      data: { rule = {}, range = {}, customParams = {}, hideAzure, historyRule = {} } = {},
      rule_key,
      rule_service,
      is_cost,
      projects,
      payload: filterPayload,
      configCatAzure,
    } = payload;

    const rules_response = await getRulesData({
      ...rule,
      range,
      customParams,
      configCatAzure,
      hideAzure,
    });

    const counters_response = await getFilterData({
      params: historyRule,
      range,
      configCatAzure,
      hideAzure,
    });
    let eip_response = { eipData: [], eipCost: 0 };

    if (rule_key === 'unused_resources' && rule_service === 'eip') {
      getEIPData().then(res => {
        if (res.status === 200) {
          eip_response = {
            eipData: res.eipData,
            eipCost: numberFix(res.eipCost),
          };
        }
      });
    }

    const aggs = counters_response?.data?.aggregations || {};

    const violations_counts_per_project_cloud_type = aggs?.violations_counts_per_project_cloud_type
      ? {
          buckets: (aggs?.violations_counts_per_project_cloud_type?.buckets || []).flatMap(b =>
            b.violations_counts_per_project?.buckets.map(v => ({
              ...v,
              cloudPlatform: b.key,
            })),
          ),
        }
      : [];

    const violations_counts_per_region_cloud_type = aggs?.violations_counts_per_region_cloud_type
      ? {
          buckets: (aggs?.violations_counts_per_region_cloud_type?.buckets || []).flatMap(b =>
            b.violations_counts_per_region?.buckets.map(v => ({
              ...v,
              cloudPlatform: b.key,
            })),
          ),
        }
      : [];

    const violations_counts_per_vpc_cloud_type = aggs?.violations_counts_per_vpc_cloud_type
      ? {
          buckets: (aggs.violations_counts_per_vpc_cloud_type?.buckets || []).flatMap(b =>
            b.violations_counts_per_vpc?.buckets.map(v => ({
              ...v,
              cloudPlatform: b.key,
            })),
          ),
        }
      : [];

    const violations_counts_history_per_service_type_cloud_type =
      aggs?.violations_counts_history_per_service_type_cloud_type
        ? {
            buckets: (aggs.violations_counts_history_per_service_type_cloud_type?.buckets || []).flatMap(b =>
              (b.violations_counts_history_per_type?.buckets || []).map(v => ({
                ...v,
                cloudPlatform: b.key,
              })),
            ),
          }
        : [];

    const response = {
      rule_key,
      rule_service,
      projects,
      is_cost,
      eip_response,
      filterPayload,
    };

    if (
      aggs.violations_counts_per_region_cloud_type ||
      aggs.violations_counts_per_project_cloud_type ||
      aggs.violations_counts_per_vpc_cloud_type
    ) {
      // count violations per cloud type to enable/disable cloud platform filter
      const getDocCountForKey = (buckets = [], key) => {
        const foundBucket = buckets.find(f => (f?.cloudPlatform || '').toString() === key);

        return foundBucket?.doc_count || 0;
      };

      const cloud_data = [
        { key: 'aws', doc_count: 0 },
        { key: 'azure', doc_count: 0 },
      ].map(el => ({
        key: el.key,
        doc_count:
          getDocCountForKey(violations_counts_per_region_cloud_type?.buckets || [], el.key) +
          getDocCountForKey(violations_counts_per_vpc_cloud_type?.buckets || [], el.key) +
          getDocCountForKey(violations_counts_per_project_cloud_type?.buckets || [], el.key),
      }));

      response.countersCloud = {
        violations_counts_per_region: violations_counts_per_region_cloud_type,
        violations_counts_per_project: violations_counts_per_project_cloud_type,
        violations_counts_per_vpc: violations_counts_per_vpc_cloud_type,
        violations_counts_history_per_type: violations_counts_history_per_service_type_cloud_type,
        cloud_data,
      };
    } else {
      response.counters = aggs;
    }

    const ruleDetail =
      rules_response?.data?.aggregations?.violations?.violations_details?.violation_type?.buckets || [];
    const rulesMapping = getRulesDetail(RULES_DESCRIPTION_TEXT, rule_key, rule_service);
    const state = getState()[SLICE_NAME];

    const setData = await setInitialViolationData({
      ruleDetail,
      projects,
      counters: !isEmpty(response.counters) ? response.counters : response.countersCloud,
      rulesMapping,
      rule_key,
      rule_service,
      selectedMonthFilter: state.selectedMonthFilter,
    });

    const fixFilter = state => {
      if (!response.filterPayload?.[state]) {
        response.filterPayload[state] = [];
        response.filterPayload[`${state}_values`] = [];
      }
    };

    ['accountState', 'regionState', 'cloudPlatformState'].forEach(stateKey => fixFilter(stateKey));

    return { ...response, dataset: setData };
  },
);

export const loadCMRuleDetailDataRequest = createAsyncThunk(
  `${SLICE_NAME}/loadCMRuleDetailDataRequest`,
  async payload => {
    const { rule = {}, range = {}, customParams = {}, configCatAzure = false } = payload;

    const response = await getCMRulesData({
      ...rule,
      range: range,
      customParams,
      configCatAzure,
    });

    return response.data;
  },
);

export const loadOverrideDataRequest = createAsyncThunk(`${SLICE_NAME}/getOverwriteRule`, async payload => {
  const response = await getOverwriteRule(
    {
      ...payload.payload,
      ignore_inactive: false,
    },
    payload?.partner_scope_id,
  );
  typeof payload.callback === 'function' && payload.callback();

  return { data: response.data, payload: payload.payload };
});

const ruleDetailSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setActiveSorter: (state, { payload }) => {
      state.activeSorter = payload;
    },
    setActiveResourceSorter: (state, { payload }) => {
      state.activeResourceSorter = payload;
    },
    setSelectedMonthFilter: (state, { payload }) => {
      state.selectedMonthFilter = payload;
    },
    setShowModal: (state, { payload }) => {
      state.showModal = payload;
    },
    setActiveSubTab: (state, { payload }) => {
      state.activeSubTab = payload;
    },
  },
  extraReducers: {
    [loadRuleDetailData.pending]: (state, action) => {
      state.loading = true;
      state.overridePayload = {};
    },
    [loadRuleDetailData.fulfilled]: (state, { payload }) => {
      const { rule_key, rule_service, counters, countersCloud, eip_response, filterPayload, is_cost, dataset } =
        payload;

      const lastCost = (dataset?.rulesMapping?.[rule_key]?.[rule_service]?.data || []).reduce(
        (a, b) => a + (b?.cost || 0),
        0,
      );

      state.rule_key = rule_key;
      state.rule_service = rule_service;
      state.is_cost = is_cost;
      state.activeSubTab = is_cost
        ? rule_key === 'unused_azure_resources'
          ? { key: 'disk_storage', value: 'Disk Storage', type: 'disk_storage' }
          : state.activeSubTab
        : state.activeSubTab;
      state.counters = counters;
      state.countersCloud = countersCloud;
      state.costData = { lastCost };
      state.rulesMapping = dataset?.rulesMapping || {};
      state.countData = dataset?.counts || {};
      state.loading = false;
      state.payload = filterPayload;
      state.eipDetails = {
        eipData: eip_response.eipData,
        eipCost: updatedEIPCost(eip_response.eipData, state.payload.regionState),
      };
    },
    [loadRuleDetailData.rejected]: state => {
      state.loading = false;
      state.rulesMapping = {
        data: [],
      };
    },
    [loadCMRuleDetailDataRequest.pending]: state => {
      state.cmLoading = true;
    },
    [loadCMRuleDetailDataRequest.fulfilled]: (state, { payload }) => {
      state.cmLoading = false;

      const violations_changes_results = payload?.aggregations?.violations_changes_cloud_type
        ? extractViolationChanges(payload?.aggregations?.violations_changes_cloud_type)
        : payload?.aggregations?.violations_changes?.violations_changes_results;
      state.changeDataDetail = violations_changes_results?.change_log?.buckets || [];
    },
    [loadCMRuleDetailDataRequest.rejected]: state => {
      state.cmLoading = false;
    },
    [loadOverrideDataRequest.pending]: state => {
      state.overrideLoading = true;
      state.overrideData = [];
    },
    [loadOverrideDataRequest.fulfilled]: (state, { payload }) => {
      state.overrideLoading = false;
      state.overrideData = payload.data || [];
      state.overridePayload = payload.payload || {};
    },
    [loadOverrideDataRequest.rejected]: state => {
      state.overrideLoading = false;
    },
  },
});

export const { setActiveResourceSorter, setActiveSorter, setSelectedMonthFilter, setShowModal, setActiveSubTab } =
  ruleDetailSlice.actions;
export default ruleDetailSlice.reducer;

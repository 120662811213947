import React from 'react';
import { Anchor } from 'components/Custom';
import { LinkIcon } from 'assets/images/common';
import { CheckedIcon } from 'assets/images';
import Tippy from '@tippyjs/react';
import { currencyValueFormatterWithNoValue } from 'containers/Cost/TagExplorer/helper';
import { NpTags } from 'assets/images/figma-imgs';

export const NEW_TYPES = [
  { value: 'other', name: 'Select Resources' },
  { value: 'attached', name: 'Attached to Schedule' },
];

export const TYPES = [
  { value: 'attached', name: 'Attached to Schedule' },
  { value: 'other', name: 'Select Resources' },
];

export const RESOURCE_TYPES = [
  { value: 'EC2', name: 'Amazon Elastic Compute Cloud' },
  { value: 'RDS', name: 'Amazon Relational Database Service' },
  {
    value: 'Auto Scaling Group',
    name: 'Auto Scaling Group',
  },
  {
    value: 'RDS Cluster',
    name: 'Amazon Relational Database Service - Cluster',
  },
  {
    value: 'EKS Cluster',
    name: 'Amazon Elastic Kubernetes Service - Cluster',
  },
];

export const DefaultFilterValue = {
  value: '',
  name: 'All',
};

export const AttachedColumns = headerCheckbox => {
  return [
    {
      field: 'resource_info',
      headerName: 'Resource Info',
      cellRenderer: 'resourceNameRenderer',
      headerCheckboxSelection: headerCheckbox,
      checkboxSelection: true,
      minWidth: 600,
    },
    {
      field: 'resource_type',
      headerName: 'Resource Type',
      minWidth: 180,
    },
    {
      field: 'savings',
      sortable: true,
      sort: 'desc',
      sortingOrder: ['desc', 'asc'],
      headerName: 'Monthly Savings',
      filter: 'agNumberColumnFilter',
      valueFormatter: currencyValueFormatterWithNoValue,
      minWidth: 160,
    },
    {
      field: 'average_monthly_cost',
      headerName: 'Average Monthly Cost',
      filter: 'agNumberColumnFilter',
      valueFormatter: currencyValueFormatterWithNoValue,
      minWidth: 180,
    },
    {
      field: 'schedules',
      headerName: 'Schedule',
      headerClass: 'tt-none',
      cellRenderer: 'schedulesRenderer',
      minWidth: 160,
    },
    {
      field: 'region',
      headerName: 'Region',
      minWidth: 120,
    },
    // {
    //   field: 'tags',
    //   headerName: 'Tags',
    //   cellRenderer: 'tagsRenderer',
    //   minWidth: 360,
    // },
  ];
};

export const Columns = (type, headerCheckbox) => {
  const COLUMNS = [
    {
      field: 'resource_info',
      headerName: 'Resource Info',
      cellRenderer: 'resourceNameRenderer',
      headerCheckboxSelection: headerCheckbox,
      checkboxSelection: true,
      minWidth: 600,
    },
    {
      field: 'resource_type',
      headerName: 'Resource Type',
      minWidth: 180,
    },
    {
      field: 'savings',
      headerName: 'Monthly Savings',
      filter: 'agNumberColumnFilter',
      valueFormatter: currencyValueFormatterWithNoValue,
      minWidth: 160,
    },
    {
      field: 'average_monthly_cost',
      headerName: 'Average Monthly Cost',
      filter: 'agNumberColumnFilter',
      valueFormatter: currencyValueFormatterWithNoValue,
      minWidth: 180,
    },
    {
      field: 'schedules',
      headerName: 'Schedule',
      headerClass: 'tt-none',
      cellRenderer: 'schedulesRenderer',
      minWidth: 160,
    },
    {
      field: 'region',
      headerName: 'Region',
      minWidth: 120,
    },
    {
      field: 'desiredSize',
      headerName: 'Desired Capacity',
      minWidth: 120,
    },
    {
      field: 'minSize',
      headerName: 'Min Capacity',
      minWidth: 120,
    },
    {
      field: 'maxSize',
      headerName: 'Max Capacity',
      minWidth: 120,
    },
    // {
    //   field: 'tags',
    //   headerName: 'Tags',
    //   cellRenderer: 'tagsRenderer',
    //   minWidth: 360,
    // },
  ];

  if (type === 'Auto Scaling Group') {
    return COLUMNS.filter(col => !['cost'].includes(col.field));
  }

  if (type === 'EKS Node Group') {
    return COLUMNS.filter(col => !['region', 'cost'].includes(col.field));
  }

  if (type === 'EKS Cluster') {
    COLUMNS[0] = {
      field: 'resource_info',
      headerName: 'EKS Cluster Name',
      cellRenderer: 'resourceNameRenderer',
      minWidth: 300,
    };
    COLUMNS.unshift({
      field: 'expand_collapse',
      headerName: '',
      cellRenderer: 'agGroupCellRenderer',
      minWidth: 180,
      sortable: false,
      suppressMenu: true,
    });

    return COLUMNS.filter(col => !['desiredSize', 'minSize', 'maxSize', 'cost'].includes(col.field));
  }

  return COLUMNS.filter(col => !['desiredSize', 'minSize', 'maxSize'].includes(col.field));
};

export const resourceNameRenderer = handler => params => {
  const data = params?.data || {};
  const isDemoMode = sessionStorage.getItem('demo-mode') === 'on';

  return isDemoMode ? (
    <div className={`data ${isDemoMode ? 'demo-mode-black' : ''}`}>
      {data.name || data.item_id} {data.type ? '(' + data.type + ')' : null}
    </div>
  ) : (
    <div className="data">
      <div className="d-flex flex-aic">
        {data.isAttached && data.manually_added === false ? ( //eslint-disable-line
          <div className="np-cardIcon wh-18 mR5">
            <NpTags color="#0071EA" />
          </div>
        ) : null}
        <Anchor
          className="noDec resource-name"
          onClick={() => {
            handler(params);
          }}
        >
          {data.name || data.item_id} {data.type ? '(' + data.type + ')' : null}
          <i className="icon-link">
            {' '}
            <LinkIcon />
          </i>
        </Anchor>
      </div>
      <small style={{ paddingLeft: '0' }}>Resource id: {data.item_id}</small>
    </div>
  );
};

export const tagsRenderer = params => {
  const data = params?.data || {};
  const isDemoMode = sessionStorage.getItem('demo-mode') === 'on';

  return <div className={`data ${isDemoMode ? 'demo-mode-black' : ''}`}>{data.tags || ''}</div>;
};

export const issuesRenderer = params => {
  const data = params?.data || {};

  return data?.issues > 0 ? (
    <div className="np-tag yellow outline">
      {data.issues} {data?.issues > 1 ? 'violations' : 'violation'}
    </div>
  ) : (
    <div className="np-tag teal outline">No violation</div>
  );
};

export const schedulesRenderer = schedules => params => {
  const data = params?.data || {};
  const schedulesTotal = data?.res_schedule_ids?.length;
  const isDemoMode = sessionStorage.getItem('demo-mode') === 'on';

  return schedulesTotal > 0 ? (
    isDemoMode ? (
      <div className="np-tag teal outline">
        {schedulesTotal} {`Schedule${schedulesTotal > 1 ? 's' : ''}`}
      </div>
    ) : (
      <Tippy
        content={
          <ul className="np-linked-nswitches">
            {(data?.res_schedule_ids || []).map(id => (
              <li key={id}>
                <b>{'Schedule: '}</b>
                {schedules.find(item => item.id === id)?.name || ''}
              </li>
            ))}
          </ul>
        }
        placement="right"
      >
        <div className="np-tag teal outline cursor-pointer">
          {schedulesTotal} {`Schedule${schedulesTotal > 1 ? 's' : ''}`}
        </div>
      </Tippy>
    )
  ) : (
    <div
      style={{
        textDecoration: schedulesTotal ? 'none' : 'underline',
        fontWeight: schedulesTotal ? '500' : 'normal',
      }}
    >
      No Schedules
    </div>
  );
};

export const attachedRenderer = handler => params => {
  const data = params?.data || {};

  return (
    <div className="d-flex flex-aic" style={{ gap: '4px' }}>
      <CheckedIcon width={18} height={18} />
      {data.delete ? (
        <button
          className="np-button light round"
          onClick={() => {
            handler(params);
          }}
        >
          Remove Resource
        </button>
      ) : null}
    </div>
  );
};

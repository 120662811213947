/* istanbul ignore file */

import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { getEnv, UAT_ENV, UAT2_ENV } from 'utils/env';
import { PROD } from 'utils/metaEnv';

const env = getEnv();
const UAT_SENTRY_KEY = 'https://d4d043be0abe416a972decd014ab09d0@sentry.io/173230';
const UAT2_SENTRY_KEY = 'https://31ebcd589b31477c9f48bd100b518912@sentry.io/4505545628581888';
const PRODUCTION_SENTRY_KEY = 'https://95c498e42fe24d40b56a4352ccc1a4ba@sentry.io/173432';

if (PROD) {
  let dsn = PRODUCTION_SENTRY_KEY;

  if (env === UAT_ENV) {
    dsn = UAT_SENTRY_KEY;
  } else if (env === UAT2_ENV) {
    dsn = UAT2_SENTRY_KEY;
  }

  Sentry.init({
    dsn,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    beforeSend: event => {
      // filter out UnhandledRejection errors that have no information
      if (
        event !== undefined &&
        event.exception !== undefined &&
        event.exception.values !== undefined &&
        event.exception.values.length === 1
      ) {
        const ev = event.exception.values[0];

        const deny =
          ev.type === 'UnhandledRejection' && ev.value.includes('Non-Error promise rejection captured with value');

        if (deny) {
          return null;
        }
      }

      return event;
    },
  });
}

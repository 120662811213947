import forIn from 'lodash/forIn';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { v1 as uuidv1 } from 'uuid';
import { numberFix, addMonthFilter, getResourceName } from 'utils/helper';
import { getProjectName, getRuleData, getViolationIndex } from 'containers/nOpsRules/utils';
import { getLink } from 'containers/Search/Detail/helper';
import { getViolationIndexForFirstSeenData } from '../../utils/helper';
import { getCloudResourceCostForInventory } from 'api/cloud-resource-cost';

const getCount = (historyCountData, rule_key) => {
  const historyViolationsBuckets =
    historyCountData?.violations_counts_history_per_type?.violations_details?.violations_counts_history_type?.buckets ||
    [];
  const countBucket = historyViolationsBuckets.find(x => x.key === rule_key);

  return countBucket?.violations_counts_history_resources?.doc_count || 0;
};

const getStatus = state => {
  return state === 'running' ? 'Started' : state === 'stopped' ? 'Stopped' : state === 'terminated' ? 'Terminated' : '';
};

export const setInitialViolationData = async ({ ...props }) => {
  let { rulesMapping } = props;

  const {
    ruleDetail = [],
    projects = [],
    counters = {},
    rule_key,
    rule_service,
    selectedMonthFilter = 'months_1',
  } = props;
  const rulesData = ruleDetail.find(violation => violation.key === rule_key);
  const violationsBuckets = rulesData?.results?.resource?.buckets || [];
  const countersHistoryBuckets = counters?.violations_counts_history_per_type?.buckets || [];
  const ruleData = getRuleData(violationsBuckets, rule_service);

  if (!violationsBuckets?.length || !ruleData?.length) {
    return {
      counts: {
        count: 0,
        lastCount: 0,
      },
      rulesMapping,
    };
  }

  let lastCount = 0;

  if (rule_service !== 'all') {
    const historyCountData = countersHistoryBuckets.find(x => x.key === rule_service);
    lastCount = getCount(historyCountData, rule_key);
  } else {
    countersHistoryBuckets.forEach(historyData => {
      lastCount += getCount(historyData, rule_key);
    });
  }

  const count = rulesData?.results?.doc_count || 0;

  const { displayData, count: final_count } = rule_data_loop(
    ruleData,
    rule_key,
    rule_service,
    selectedMonthFilter,
    projects,
    count,
  );
  const resource_ids = displayData.map(item => item.item_id).filter(item => Boolean(item));
  const apiStack = [];

  for (let i = 0; i < resource_ids.length / 1000; i++) {
    apiStack.push(
      getCloudResourceCostForInventory({
        resource_id: resource_ids.slice(i * 1000, (i + 1) * 1000),
        granularity: 'day',
      }),
    );
  }

  let allResult = {};
  const responseInfo = await Promise.all(apiStack);
  responseInfo.forEach(item => {
    allResult = { ...allResult, ...item.data };
  });

  rulesMapping = {
    [rule_key]: {
      ...(rulesMapping?.[rule_key] || {}),
      [rule_service]: {
        ...(rulesMapping?.[rule_key]?.[rule_service] || {}),
        data: [...displayData],
      },
    },
  };

  rulesMapping[rule_key][rule_service].data = [...displayData].map(item => ({
    ...item,
    cost: allResult?.[item.item_id]?.total || 0,
    row_id: uuidv1(),
  }));

  return {
    counts: { count: final_count, lastCount },
    rulesMapping,
  };
};

const parseString = string => {
  if (!string || !string.length) {
    return string;
  }

  return string.split('/').pop();
};

const parseStatuses = array => {
  if (!Array.isArray(array) || !array.length) {
    return null;
  }

  const state = array.find(elem => elem.startsWith('PowerState'));

  return state.split('/').pop() || null;
};

const parseBool = bool => {
  if (bool === true) {
    return 'on';
  } else if (bool === false) {
    return 'off';
  } else {
    return '---';
  }
};

export const rule_data_loop = (ruleData, rule_key, rule_service, selectedMonthFilter, projects, count = 0) => {
  const displayData = [];
  ruleData.forEach((violationsData, index) => {
    const {
      _index,
      _id,
      _source: {
        violations = [],
        violations_dates_available = [],
        cost: { dates_available: { lte = '' } = {} } = {},
        ...rest
      },
    } = violationsData;
    const violationIndex = getViolationIndex(violations, rule_key);
    const violationFirstSeenIndex = getViolationIndexForFirstSeenData(violations_dates_available, rule_key);
    const filterType = selectedMonthFilter || 'months_1';
    let detailData = {},
      firstSeenData = {};
    const condition = addMonthFilter(rule_service, rule_key);

    if (!condition) {
      detailData = violations[violationIndex];
      firstSeenData = violations_dates_available[violationFirstSeenIndex];
    } else {
      detailData = violations.find(
        violation =>
          violation?.violation_type === rule_key &&
          (violation?.period === filterType || violation?.violation_subtype === filterType),
      );
      firstSeenData = violations_dates_available.find(
        violation =>
          violation?.violation_type === rule_key &&
          (violation?.period === filterType || violation?.violation_subtype === filterType),
      );
    }

    const projectName = getProjectName(_index, projects);

    if (!detailData) {
      count -= 1;

      return null;
    }

    if (detailData?.compliant) {
      count -= 1;
    }

    const commonData = {
      id: _id + index,
      project: projectName?.name || '---',
      projectId: projectName.id,
      compliant: detailData?.compliant,
      status: detailData?.overwrite || false,
      mainId: _id,
      tags_kv: rest?.tags_kv || [],
      item_id: rest?.item_id,
      name:
        rest?.name ||
        detailData?.UserName ||
        detailData?.user_name ||
        rest?.user_name ||
        getResourceName(rest?.item_id) ||
        detailData?.volume_id,
      region: rest?.region || '---',
      type: rest?.type || '---',
      resourceId: rest?.item_id,
      cost: numberFix(rest?.current_month_cost || 0),
      volumne_id: rest?.item_id,
      is_ri: rest?.is_ri,
      is_as: rest?.is_as,
      awsLink: getLink(violationsData),
      detectedDate: firstSeenData
        ? moment(firstSeenData.first_seen, 'YYYY-MM-DD').format('DD, MMM YYYY')
        : moment.utc().format('DD, MMM YYYY'),
      overrideInfo: {},
      expand: '',
      managed_by: parseString(rest?.managed_by) || '---',
      sku_name: rest?.sku_name || '---',
      disk_size_gb: rest?.disk_size_gb || '---',
      ip_address: rest?.ip_address || '---',
      vm_id: rest?.vm_id || '---',
      zone_redundant: (rest?.zone_redundant === false ? 'off' : rest?.zone_redundant) || '---',
      containers: rest?.containers?.length || 0,
      blob_encryption: parseBool(rest?.blob_encryption),
      file_encryption: parseBool(rest?.file_encryption),
      require_infrastructure_encryption: parseBool(rest?.require_infrastructure_encryption),
      virtual_machine: parseString(rest?.virtual_machine?.id || '') || '---',
      disk_state: parseString(rest?.disk_state || '') || '---',
      kind: rest?.kind || '---',
      allow_blob_public_access: parseBool(rest?.allow_blob_public_access) || '---',
      virtual_machine_scale_set: parseBool(rest?.virtual_machine_scale_set) || '---',
    };
    let timeStamp = 0;

    if (rest?.state_change_time) {
      timeStamp = parseInt(rest?.state_change_time);
    }

    let addData = {
      ...commonData,
    };

    switch (rule_key) {
      // Security rules
      case 'check_resource_encryption':
        switch (rule_service) {
          case 'rds':
          case 's3':
            addData = {
              ...addData,
              name: rest?.name || '---', //dbInstance
            };

            break;
          default:
            break;
        }

        break;
      case 'policy_attached_users':
        forIn(detailData, (vValue, vKey) => {
          const data = {
            ...commonData,
            createDate: detailData?.CreateDate
              ? moment(detailData?.CreateDate).format('MMM Do YYYY, h:mm:ss a')
              : '---',
          };

          switch (vKey) {
            case 'policies':
              vValue.forEach((pValue, index) => {
                const iamUserData = {
                  ...data,
                  id: _id + ':policy:' + index,
                  policyName: pValue?.PolicyName || '---',
                  policyArn: getResourceName(pValue?.PolicyArn) || '---',
                  attachedPolicy: 'Yes',
                  policyType: 'Directly attached policy',
                };
                addData = { ...addData, ...iamUserData };
              });

              break;
            case 'admin_access_policies':
              vValue.forEach((pValue, index) => {
                const iamUserData = {
                  ...data,
                  id: _id + ':admin_access_policies' + index,
                  policyName: pValue?.PolicyName || '---',
                  policyArn: getResourceName(pValue?.PolicyArn) || '---',
                  adminPolicy: 'Yes',
                  policyType: 'Admin access policies of user',
                };
                addData = { ...addData, ...iamUserData };
              });

              break;
            case 'inline_policies':
              vValue.forEach((pValue, index) => {
                const iamUserData = {
                  ...data,
                  id: _id + ':inline_policies' + index,
                  policyName: pValue || '---',
                  inlinePolicy: 'Yes',
                  policyType: 'Inline policy of user',
                };
                addData = { ...addData, ...iamUserData };
              });

              break;
            default:
              break;
          }
        });

        break;
      case 'public_subnet':
        addData = {
          ...addData,
          port: detailData?.ports.join(',') || '---',
          subnet: detailData?.subnet_ids || '---',
        };

        break;
      case 's3_bucket_security':
        addData = {
          ...addData,
          name: rest?.name || '---',
          publicWriteAccess: !detailData?.not_public_write ? 'Available' : 'Disable',
          publicReadAccess: !detailData?.not_public_read ? 'Available' : 'Disable',
          loggingEnabled: detailData?.logging_enabled ? 'Yes' : 'No',
          serverSideEncryption: detailData?.server_side_encryption_enabled ? 'Yes' : 'No',
          versioningEnabled: detailData?.versioning_enabled ? 'Yes' : 'No',
        };

        break;
      case 'cloudtrail_log_validation_enabled':
        addData = {
          ...addData,
          compliance: 'Non-Compliant',
        };

        break;
      case 'check_root_user_mfa':
        addData = {
          ...addData,
          ruleName: 'Root MFA enabled',
          compliance: detailData?.compliant ? 'Compliant' : 'Non-Compliant',
        };

        break;
      case 'inactive_keys':
        addData = {
          ...addData,
          accessKey: detailData?.access_key_id || '---',
          lastUsed: detailData?.access_key_last_used
            ? moment(detailData.access_key_last_used).format('MMM D YYYY  h:mm:ss a')
            : 'Never',
          created: detailData?.create_date ? moment(detailData.create_date).format('MMM D YYYY  h:mm:ss a') : '',
        };

        break;
      case 'dynamodb_limit':
        addData = {
          ...addData,
          type: detailData?.type,
          name: detailData?.table_name,
          maxUsedReadRequest: numberFix(detailData?.max_read_request),
          maxUsedAvgReadRequestPerSecond: numberFix(detailData?.max_read_avg),
          maxUsedWriteRequest: numberFix(detailData?.max_write_request),
          maxUsedAvgWriteRequestPerSecond: numberFix(detailData?.max_write_avg),
          readCapacity: detailData?.read_capacity_units,
          writeCapacity: detailData?.write_capacity_units,
        };

        break;
      case 'strong_password_policy':
        addData = {
          ...addData,
          reasons: (detailData?.reasons || []).join(', '),
        };

        break;
      case 'cloudtrail_s3_protection':
        addData = {
          ...addData,
          mfa_delete: detailData?.mfa_delete || '---',
          versioning: detailData?.versioning || '---',
        };

        break;
      case 'check_azure_policy':
        addData = {
          ...addData,
          non_compliant_policies: detailData?.non_compliant_policies || '---',
        };

        break;
      case 'azure_public_subnet':
        addData = {
          ...addData,
          public_network_access: detailData?.public_network_access || '---',
        };

        break;
      case 'azure_strong_password_policy':
        addData = {
          ...addData,
          number_of_issues: detailData?.number_of_issues || '---',
          reason: detailData?.reason[0]?.passwordPolicies || '---',
        };

        break;
      case 'check_azure_security_center':
        addData = {
          ...addData,
          MCAS: parseBool(detailData?.MCAS) || '---',
          WDATP: parseBool(detailData?.WDATP) || '---',
          Sentinel: parseBool(detailData?.Sentinel) || '---',
        };

        break;

      // Cost rules
      case 's3_underutilized':
        addData = {
          ...addData,
          requests_count: detailData?.requests_count || 0,
          network_mbytes: detailData?.network_mbytes || 0,
        };

        break;
      case 'efs_low_utilization':
        addData = {
          ...addData,
          ioUsagePercents: numberFix(detailData?.io_usage_percents) || 0,
          total_io: detailData?.total_io <= 0 ? 'Unavailable' : numberFix(detailData?.total_io || 0),
          performance_mode: detailData?.performance_mode,
          read_iops: detailData?.read_iops <= 0 ? 'Unavailable' : numberFix(detailData?.read_iops || 0),
          write_iops: detailData?.write_iops <= 0 ? 'Unavailable' : numberFix(detailData?.write_iops || 0),
        };

        break;
      case 'ecs_low_utilization':
        addData = {
          ...addData,
          cpu_utilization: detailData?.cpu_utilization || 0,
          memory_utilization: detailData?.memory_utilization || 0,
        };

        break;
      case 'instance_recommendation_rightsizing':
      case 'instance_recommendation':
        addData = {
          ...addData,
          currentConfig: detailData?.old_type || '---',
          suggestedConfig: detailData?.new_type || '---',
          currentCost: numberFix(detailData?.current_30d_cost || 0),
          newCost: numberFix(detailData?.possible_30d_cost || 0),
          details: detailData?.details || '---',
          violation_type: detailData?.violation_type || '---',
          instance_type: rest?.instance_type,
          saving:
            rest?.type === 'rds'
              ? numberFix(detailData?.monthly_saving || 0)
              : numberFix(detailData?.update_monthly_change || 0),
          idle: detailData?.details?.['cpu_average'] ? numberFix(100 - detailData.details['cpu_average']) : 0,
        };

        break;
      case 'dynamodb_low_utilization':
        addData = {
          ...addData,
          readUsagePercents: numberFix(detailData?.read_usage_percents) || 0,
          writeUsagePercents: numberFix(detailData?.write_usage_percents) || 0,
          tabSizeMBytes: numberFix(detailData?.tab_size_mbytes) || 0,
        };

        break;
      case 'log_group_underutilized':
        {
          let newName = rest?.name || rest?.item_id;

          if (newName) {
            let len = '';

            if (newName.includes('/')) {
              len = newName.split('/');
            } else {
              len = newName.split(':');
            }

            newName = len[len.length - 1];
          }

          addData = {
            ...addData,
            name: newName,
            log_events_bandwidth_kbytes: numberFix(detailData?.log_events_bandwidth_kbytes || 0) || 0,
          };
        }

        break;
      case 'iops_performance':
        addData = {
          ...addData,
          actual_iops: detailData?.actual_iops,
          capacity: numberFix(detailData?.size || 0),
          uStatus: detailData?.status || '---',
          provisionIOPS: detailData?.iops || 0,
          iopsLastDay: detailData?.actual_iops_day || 0,
          iopsLastWeek: detailData?.actual_iops_week || 0,
          estimatedMonthlySaving: numberFix(detailData?.estimated_saving_month || 0),
          useWeeks: detailData?.use_weeks || 0,
          useDays: detailData?.use_days || 0,
        };

        break;
      case 'unused_resources':
        addData = {
          ...addData,
          reason: ['all', 'ebs'].includes(rule_service)
            ? detailData.reason === 'ebs_unattached'
              ? 'Unattached EBS'
              : 'Stopped EBS'
            : '',
          public_ip: rest?.public_ip || '',
        };

        break;
      case 'underutilized_resources':
        addData = {
          ...addData,
          ipAddress: rest.ip_address,
          uStatus: getStatus(rest.state),
          timestamp: timeStamp,
          type: rest.instance_type,
          cpu: detailData?.cpu_604800 || 0,
          sStatus: rest.item_id ? 'Public' : 'Private',
        };

        break;
      case 'ec2_low_network_utilization':
        addData = {
          ...addData,
          lastSeen: !rest?.active ? lte : '',
          ipAddress: rest?.ip_address,
          uStatus: getStatus(rest.state),
          timestamp: timeStamp,
          type: rest?.instance_type,
          sStatus: rest?.item_id,
        };

        break;
      case 'file_service_underutilized':
        addData = {
          ...addData,
          write_iops: detailData?.write_iops <= 0 ? 'Unavailable' : numberFix(detailData?.write_iops || 0),
          read_iops: detailData?.read_iops <= 0 ? 'Unavailable' : numberFix(detailData?.read_iops || 0),
        };

        break;
      case 'storage_account_underutilized':
        addData = {
          ...addData,
          total_io: detailData?.total_io <= 0 ? 'Unavailable' : numberFix(detailData?.total_io || 0),
          read_iops: detailData?.read_iops <= 0 ? 'Unavailable' : numberFix(detailData?.read_iops || 0),
        };

        break;
      case 'underutilized_azure_resources':
        addData = {
          ...addData,
          product: detailData?.product,
          vm_id: rest.vm_id,
          cpu_604800: detailData?.cpu_604800 || 0,
        };

        break;
      case 'azure_iops_performance':
        addData = {
          ...addData,
          estimated_saving: detailData?.estimated_saving,
          read_iops: detailData?.read_iops || 0,
          write_iops: detailData?.write_iops || 0,
        };

        break;
      case 'unused_azure_resources':
        forIn(detailData, (vValue, vKey) => {
          const data = {
            ...commonData,
            createDate: detailData?.CreateDate ? moment(detailData.CreateDate).format('MMM Do YYYY, h:mm:ss a') : '---',
          };

          switch (vKey) {
            case 'ip_configuration':
              addData = {
                ...addData,
                ...data,
                ip_configuration: detailData.ip_configuration ?? 'none',
              };

              break;
            case 'ip_address':
              addData = {
                ...addData,
                ip_address: rest?.ip_address ?? 'none',
              };

              break;
            case 'statuses':
              addData = {
                ...addData,
                statuses: parseStatuses(vValue) || '---',
              };

              break;
            case 'network_interfaces':
              addData = {
                ...addData,
                data,
              };

              break;
            default:
              break;
          }
        });

        break;
      case 'vm_low_network_utilization':
        addData = {
          ...addData,
          network_in: detailData?.network_in ? detailData.network_in.toFixed(2) : 0,
          network_out: detailData?.network_out ?? 0,
        };

        break;
      case 'cosmosdb_low_utilization':
        addData = {
          ...addData,
          total_request_units: detailData?.total_request_units || 0,
          provisioned_throughput: detailData?.provisioned_throughput || 0,
        };

        break;
      case 'cosmosdb_limit':
        addData = {
          ...addData,
          total_request: detailData?.total_request || 0,
          violation_date: detailData?.violation_date || '---',
          violation_subtype: detailData?.violation_subtype || '---',
        };

        break;
      case 'azure_instance_recommendation':
        addData = {
          ...addData,
          solution: detailData?.solution || '---',
          impacted_value: detailData?.impacted_value || '---',
          impact: detailData?.impact || '---',
          result: detailData?.result || '---',
          old_type: detailData?.old_type || '---',
          new_type: detailData?.new_type || '---',
          current_30d_cost: numberFix(detailData?.current_30d_cost || 0),
        };

        break;
      // Reliability Rules
      case 'dynamodb_continuos_backup':
        addData = {
          ...addData,
          name: detailData?.TableName,
          pointStatus: detailData?.PointInTimeRecoveryStatus === 'DISABLED' ? 'Disabled' : 'Enabled',
          backUpStatus: detailData?.ContinuousBackupsStatus === 'DISABLED' ? 'Disabled' : 'Enabled',
        };

        break;
      case 'rds_backup_policy':
        addData = {
          ...addData,
          type: rest?.db_instance_class || '---',
          size: rest?.size || '---',
          multiAZDBStatus: rest?.multi_az ? 'Enable' : 'Disable',
        };

        break;
      case 'rds_instances_without_multiaz':
        addData = {
          ...addData,
          type: rest?.db_instance_class || '---',
          size: numberFix(rest?.size || 0),
          engine: rest?.engine || '---',
          multiAZDBStatus: rest?.multi_az ? 'Enable' : 'Disable',
        };

        break;
      case 'container_instance_underutilized':
        addData = {
          ...addData,
          container_cpu_millicores_10_percent: numberFix(detailData?.container_cpu_millicores_10_percent) || 0,
          container_memory_10_percent: numberFix(detailData?.container_memory_10_percent) || 0,
          utilization_millicores: numberFix(detailData?.utilization_millicores, 4) || 0,
          utilization_bytes: numberFix(detailData?.utilization_bytes) || 0,
        };

        break;

      // Operation Rules
      case 'vpc_cidr':
        {
          const overlapsList = [];

          if (detailData?.overlaps) {
            detailData.overlaps.forEach(x => overlapsList.push(x.vpc_name ? x.vpc_name : x.vpc_id));
          }

          addData = {
            ...addData,
            vpcCIDR: detailData?.vpc_cidr,
            overlaps: overlapsList ? overlapsList.join(',') : '',
          };
        }

        break;
      case 'check_empty_tags':
        addData = {
          ...addData,
          instanceType: rest?.instance_type || '---',
          ipAddress: rest?.ip_address || '---',
        };

        break;
      case 'azure_scheduled_events':
        addData = {
          ...addData,
          data: detailData?.data || '---',
        };

        break;

      // Performance Rules
      case 'redshift_low_node_utilization':
        addData = {
          ...addData,
          cpuUtilization: numberFix(detailData?.cpu_utilization || 0),
          diskUtilization: numberFix(detailData?.disk_utilization || 0),
          nodeType: detailData?.node_type || '---',
          readIOPS:
            detailData?.read_iops_utilization <= 0
              ? 'Unavailable'
              : numberFix(detailData?.read_iops_utilization || 0) || 0,
          writeIOPS:
            detailData?.write_iops_utilization <= 0
              ? 'Unavailable'
              : numberFix(detailData?.write_iops_utilization || 0) || 0,
        };

        break;
      case 'azure_sql_pool_low_utilization':
        addData = {
          ...addData,
          cpu_utilization: numberFix(detailData?.cpu_utilization || 0),
          disk_utilization: numberFix(detailData?.disk_utilization || 0),
          period: detailData?.period || '---',
          read_write_iops_utilization:
            detailData?.read_write_iops_utilization <= 0
              ? 'Unavailable'
              : numberFix(detailData?.read_write_iops_utilization || 0) || 0,
        };

        break;
      default:
        break;
    }

    displayData.push(addData);
  });

  return {
    displayData: orderBy(displayData, [data => moment(data.detectedDate, 'DD, MMM YYYY')], 'desc'),
    count,
  };
};

import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import HorizontalChart from 'components/Charts/HorizontalChart';
import CustomHorizontalChart from 'components/Charts/HorizontalChartCustomLegend';
import { ReliabilityPillarIcon } from 'assets/images/pillars';

export const ReliabilityChart = React.memo(
  ({ ...props }) => {
    const [azData, setAZData] = useState([]);
    const [itData, setITData] = useState([]);
    const { data = {}, configCatAzure = false } = props;

    useEffect(() => {
      if (!isEmpty(data)) {
        if (data?.ec2_availability_zones?.length) {
          const nData = [];
          data.ec2_availability_zones.forEach(e => {
            nData.push({
              label: `${e.key} (${e.doc_count})`,
              data: [e.doc_count],
              cloudPlatform: e.cloudPlatform,
            });
          });
          setAZData(nData);
        }

        if (data?.ec2_types?.length) {
          const nData = [];
          data.ec2_types.forEach(e => {
            nData.push({
              label: `${e.key} (${e.doc_count})`,
              data: [e.doc_count],
              cloudPlatform: e.cloudPlatform,
            });
          });
          setITData(nData);
        }
      }
    }, [data]);

    const renderElement = (data, title, label) => (
      <div className="np-card np-cardGraph">
        <div className="title">
          <div className="np-cardIcon blue">
            <ReliabilityPillarIcon />
          </div>
          {title}
        </div>

        <div className="graph_container">
          {configCatAzure ? (
            <CustomHorizontalChart dataSet={data} labels={label} showXAxes={false} showYAxes={false} />
          ) : (
            <HorizontalChart dataSet={data} labels={label} showXAxes={false} showYAxes={false} />
          )}
        </div>
      </div>
    );

    return (
      <>
        {azData.length
          ? renderElement(azData, 'Cloud Networking distribution by availability zone', 'Availability zone')
          : null}
        {itData.length ? renderElement(itData, 'Resource distribution by instance types', 'Instance types') : null}
      </>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data),
);

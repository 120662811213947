/* istanbul ignore file */

import axios from 'axios';
import * as Sentry from '@sentry/react';
import { getErrorMessage } from 'utils/helper';
import { DEV } from 'utils/metaEnv';
import Cookies from 'js-cookie';

axios.defaults.baseURL = DEV ? '/api' : '';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

export const axiosInstance = createAxiosInstance(logout);

function createAxiosInstance(unauthorizedCallback) {
  return axios.create({
    validateStatus: status => {
      const unauthorizedStatus = 401;

      if (status === unauthorizedStatus) {
        unauthorizedCallback();
      }

      return (status >= 200 && status < 400) || status >= 400;
    },
  });
}

export function logout() {
  Cookies.remove('client_mtd_data');
  request({
    method: 'POST',
    url: '/auth/logout/',
  }).then(() => {
    window.location = '/';
  });
}

const IMPLY_TARGETS = ['/c/cost_page/', '/c/showback/', '/c/admin/budget/', '/c/admin/chargeback_history/'];

export default function request(params) {
  // Temporary param solution for Imply
  if (params.method === 'GET' && IMPLY_TARGETS.some(target => params.url.includes(target))) {
    if (params.params instanceof URLSearchParams) {
      params.params.append('imply_client', true);
    } else {
      params.params = { ...params.params, imply_client: true };
    }
  }
  // -----------------------------------------------

  const token_key = Cookies.get('token_key');

  if (token_key) {
    params.headers = {
      ...params.headers,
      'X-Nops-Token-Key': token_key,
    };
  }
  // -----------------------------------------------

  return axiosInstance({
    ...params,
  }).then(res => {
    if (window.requestCounter === undefined) {
      window.requestCounter = 0;
    }

    window.requestCounter += 1;

    if (res.status >= 400) {
      Sentry.captureException(new Error(getErrorMessage(res)));
    }

    return res;
  });
}

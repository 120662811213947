import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { loadNatGateWayData } from 'api/search';
import classNames from 'classnames';
import { CostPillarIcon } from 'assets/images/pillars';
import MultiLineChart from 'components/Charts/LineChart/multiLineChart';
import Loader from 'components/LoadingIndicator/Loader';
import { CalendarIcon, SwitchAccount } from 'assets/images';
import { DropDownArrowIcon } from 'assets/images/nOpsRule';
import { TableLoader } from 'components/ContentLoader/TableLoader';
import { AgGridReact } from 'ag-grid-react';
import LoadingWrapper from 'components/ContentLoader/LoadingWrapper';
import { COLUMN } from './constant';
import { AG_GRID_DEFAULT_CONFIG } from 'utils/constants';
import orderBy from 'lodash/orderBy';
import ScrollControls from 'components/ScrollControls';
import { numberFix } from 'utils/helper';
import moment from 'moment';

const resourcesTypes = [
  { value: 'all', title: 'All' },
  { value: 'usage_types', title: 'Usage Types' },
  { value: 'operations', title: 'Operations' },
];

const resources = [
  { value: 50, title: 'Top 50' },
  { value: 75, title: 'Top 75' },
  { value: 100, title: 'Top 100' },
];

export const CostHistory = React.memo(
  ({ ...props }) => {
    const [chartColumn, setColumn] = useState(props.activeInterval.columns);
    const [showData, setShowData] = useState([]);
    const [selectedResource, setSelectedResource] = useState(50);
    const [chartData, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedResourceType, setSelectedResourceType] = useState('all');
    const [loader, setLoader] = useState(false);
    const isNatGateWay = props?.data?._source?.type === 'nat';

    useEffect(() => {
      if (!isEmpty(props.costChartData)) {
        const columns = props.activeInterval.columns;

        const usageTypeData = Object.keys(props?.costChartData?.usageType || {}).map(item => ({
          item_type: item,
          granular: props.costChartData.usageType[item].granular,
          total: props.costChartData.usageType[item].total,
        }));

        const operationData = Object.keys(props?.costChartData?.operation || {}).map(item => ({
          item_type: item,
          granular: props.costChartData.operation[item].granular,
          total: props.costChartData.operation[item].total,
        }));
        let chartList = [],
          allData = [...usageTypeData, ...operationData];

        if (selectedResourceType === 'usage_types') {
          allData = [...usageTypeData];
        }

        if (selectedResourceType === 'operations') {
          allData = [...operationData];
        }

        let date_format = 'MMM-D-YYYY';

        if ([4, 5].includes(props.activeInterval.value)) {
          date_format = 'YYYY-MM';
        }

        if (allData?.length) {
          allData.forEach(item => {
            const data = {};
            columns.forEach(col => {
              data[moment(col.key).format(date_format)] =
                (data?.[moment(col.key).format(date_format)] || 0) + numberFix(item.granular[col.key] || 0, 2);
            });

            if (isEmpty(data) || item.total < 0.5) {
              return;
            }

            chartList.push({
              label: item.item_type,
              data: data,
            });
          });
        }

        chartList = (chartList || []).filter(item => !isEmpty(item.data));
        setLoader(false);
        setData(chartList);
      } else {
        setLoader(false);
      }
      // eslint-disable-next-line
    }, [props.data, props.costChartData, props.activeInterval, selectedResourceType]);

    useEffect(() => {
      if (!props.networkInterfaceData?.length) {
        return;
      }

      setLoading(true);
      getData();
    }, [props.networkInterfaceData, selectedResource]); // eslint-disable-line

    const getData = async () => {
      const paramsOption = new URLSearchParams();
      props.networkInterfaceData.forEach(item => {
        paramsOption.append('network_interface_id', item.NetworkInterfaceId);
      });
      paramsOption.append('limit', selectedResource);

      const response = await loadNatGateWayData(props?.data?._source?.project?.id, paramsOption);
      setShowData(orderBy(response?.data?.flow_logs, ['gb'], 'desc'));
      setLoading(false);
    };

    const handleActiveTab = value => {
      if (props.activeInterval.value !== value) {
        setLoader(true);
        setColumn(props.INTERVAL[value - 1].columns);
        props.setActiveInterval(props.INTERVAL[value - 1]);
      }
    };

    const getChartDetail = () => {
      const dataObject = {};

      if (!chartData?.length) {
        return [];
      }

      chartData.forEach(row => {
        dataObject[row.label] = row.data;
      });

      let date_format = 'MMM-D-YYYY';

      if ([4, 5].includes(props.activeInterval.value)) {
        date_format = 'YYYY-MM';
      }

      return chartData.map(row => {
        const costArray = chartColumn.map(column =>
          (dataObject[row.label][moment(column.key).format(date_format)] || 0).toFixed(2),
        );

        return {
          label: row.label,
          data: costArray,
        };
      });
    };

    const dataToShow = getChartDetail();

    return (
      <>
        <div className="resource-details-expand-action">
          <div className="np-input-group np-input-icon  np-input-clear" />

          <div className="d-flex resource-details-expand-action-right">
            <div className="np-select-group">
              <select
                className="np-input"
                value={selectedResourceType}
                onChange={({ target: { value } }) => setSelectedResourceType(value)}
              >
                {resourcesTypes.map(type => (
                  <option key={type.value} value={type.value}>
                    {type.title}
                  </option>
                ))}
              </select>

              <div className="np-caret">
                <DropDownArrowIcon />
              </div>
            </div>
            {isNatGateWay && showData?.length > 0 && (
              <>
                <div className="np-select-group">
                  <select
                    className="np-input"
                    value={selectedResource}
                    onChange={({ target: { value } }) => setSelectedResource(value)}
                  >
                    {resources.map(resource => (
                      <option key={resource.value} value={resource.value}>
                        {resource.title}
                      </option>
                    ))}
                  </select>

                  <div className="np-caret">
                    <DropDownArrowIcon />
                  </div>
                </div>
              </>
            )}
            <div className="np-select-group">
              <select
                className="np-input np-input-date"
                value={props.activeInterval.value}
                onChange={({ target: { value } }) => handleActiveTab(+value)}
              >
                {props.INTERVAL.map(tab => (
                  <option key={tab.value} value={tab.value}>
                    {tab.title}
                  </option>
                ))}
              </select>

              <div className="np-date-icon np-date-icon-res-details">
                <CalendarIcon />
              </div>

              <div className="np-caret fill-white">
                <DropDownArrowIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="np-row">
          <div
            className={classNames('npxs-12', {
              'np-nops-loading': loader || props.loading,
            })}
          >
            <Loader />
            {!chartColumn?.length ? null : (
              <div className="np-table2">
                <div className="title">
                  <div className="np-titleIcon blue">
                    <CostPillarIcon />
                  </div>
                  Resource Spend History
                </div>

                <div className="np-row">
                  <div className="npxs-12">
                    <div className="resourceInfoGraph">
                      {dataToShow?.length ? (
                        <MultiLineChart
                          columns={chartColumn.map(col => col.key)}
                          data={dataToShow}
                          label={'Cost Spend'}
                          unit={'$'}
                          defaultFormat={[4, 5].includes(props.activeInterval.value) ? 'YYYY-MM' : 'MMM-D-YYYY'}
                        />
                      ) : (
                        'No data available!'
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isNatGateWay && (
              <div className="np-table2">
                <div className="title">
                  <div className="np-titleIcon teal">
                    <SwitchAccount />
                  </div>
                  Network Interface Flow Logs
                </div>

                <LoadingWrapper isLoading={loading} customLoader={TableLoader}>
                  <div className="np-table2-wrapper cost-history">
                    <ScrollControls />
                    <div className="ag-theme-alpine" style={{ width: '100%', padding: '20px' }}>
                      <AgGridReact
                        suppressContextMenu={true}
                        columnDefs={COLUMN}
                        defaultColDef={{
                          ...AG_GRID_DEFAULT_CONFIG,
                          autoHeight: false,
                        }}
                        headerHeight={80}
                        rowData={showData}
                        pagination
                        paginationPageSize={10}
                        domLayout={'autoHeight'}
                      />
                    </div>
                  </div>
                </LoadingWrapper>
              </div>
            )}
          </div>
        </div>
      </>
    );
  },
  (pp, np) => {
    return isEqual(pp, np);
  },
);

export const DEV_ENV = 'development';
export const UAT_ENV = 'uat';
export const UAT2_ENV = 'uat2';
export const PRODUCTION_ENV = 'production';

export const UAT_URI = 'https://uat.nops.io';
export const UAT2_URI = 'https://uat2.nops.io';
export const PRODUCTION_URI = 'https://app.nops.io';

export function getEnv() {
  const currentURL = window.location.href;

  if (currentURL.indexOf(UAT2_URI) > -1) {
    return UAT2_ENV;
  }

  if (currentURL.indexOf(UAT_URI) > -1) {
    return UAT_ENV;
  }

  if (currentURL.indexOf(PRODUCTION_URI) > -1) {
    return PRODUCTION_ENV;
  }

  return DEV_ENV;
}

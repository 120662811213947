import forIn from 'lodash/forIn';
import reject from 'lodash/reject';
export const RULES_PILLAR = {
  SECURITY: 'Security',
  COST: 'Cost',
  OPERATIONS: 'Operations',
  RELIABILITY: 'Reliability',
  PERFORMANCE: 'Performance',
  SUSTAINABILITY: 'Sustainability',
  CM: 'Change Management',
};

export const setRuleDetail = payload => {
  const commonCount = {
    count: 0,
    lastCount: 0,
    cost: 0,
    lastCost: 0,
  };
  let detail = {};
  forIn(payload, (ruleDetail, ruleKey) => {
    detail[ruleKey] = { ...ruleDetail };
    ruleDetail.service.forEach(service => {
      let column = ruleDetail[service].column;
      column = reject(column, col => ['action', 'expand'].includes(col.field));
      detail = {
        ...detail,
        [ruleKey]: {
          ...detail[ruleKey],
          [service]: {
            ...ruleDetail[service],
            ...commonCount,
            column,
          },
        },
      };
    });
  });

  return detail;
};

export const RULE_DETAILS_MAPPING = payload => setRuleDetail(payload);

import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

export const getDateRangeField = ({ startDate, endDate }) => {
  const newStartDate = cloneDeep(moment(startDate, 'YYYY-MM-DD'));
  const newEndDate = cloneDeep(moment(endDate, 'YYYY-MM-DD'));

  if (
    (newStartDate.isSame(moment.utc().set('date', 1), 'day') &&
      newEndDate.isSame(
        moment.utc().isSame(moment.utc().subtract(1, 'days'), 'month')
          ? moment.utc().subtract(1, 'days')
          : moment.utc().set('date', 1),
        'day',
      )) ||
    (newStartDate.isSame(moment.utc().subtract(30, 'days'), 'day') &&
      newEndDate.isSame(moment.utc().subtract(1, 'days'), 'day')) ||
    (newStartDate.isSame(moment.utc().set('date', 1), 'day') &&
      Math.floor(newEndDate.diff(newStartDate, 'days')) === 13) ||
    (newStartDate.isSame(moment.utc().set('date', 1), 'day') && Math.floor(newEndDate.diff(newStartDate, 'days')) === 6)
  ) {
    return 'mtd';
  } else if (
    newStartDate.isSame(moment(newStartDate).startOf('M'), 'day') &&
    newEndDate.isSame(moment(newStartDate).endOf('M'), 'day')
  ) {
    return '1mo';
  } else if (
    newStartDate.isSame(moment.utc().subtract(3, 'M').startOf('M'), 'day') &&
    newEndDate.isSame(moment.utc().subtract(1, 'M').endOf('M'), 'day')
  ) {
    return '3mo';
  } else if (
    newStartDate.isSame(moment.utc().subtract(6, 'M').startOf('M'), 'day') &&
    newEndDate.isSame(moment.utc().subtract(1, 'M').endOf('M'), 'day')
  ) {
    return '6mo';
  } else if (
    (newStartDate.isSame(moment.utc().subtract(7, 'days'), 'day') &&
      newEndDate.isSame(moment.utc().subtract(1, 'days'), 'day')) ||
    (newStartDate.isSame(moment.utc().subtract(14, 'days'), 'day') &&
      newEndDate.isSame(moment.utc().subtract(1, 'days'), 'day'))
  ) {
    return 'weeks';
  } else {
    return 'default';
  }
};

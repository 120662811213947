import axiosObj from './config';

export const GRAPHQL_API = ({ data = {} }) => {
  const url = '/svc/hasura/v1/graphql';

  return axiosObj({
    method: 'POST',
    url: url,
    data,
  });
};

export const GRAPHQL_API_WITH_HEADER = ({ data = {}, id }) => {
  const url = '/svc/hasura/v1/graphql';

  return axiosObj({
    // headers: {
    //   'X-Hasura-Project-Id': id,
    // },
    method: 'POST',
    url: url,
    data,
  });
};

export const getGraphQlSavedAPIs = (params = {}) => {
  return axiosObj({
    method: 'GET',
    url: '/c/v2/graphql-query/',
    params,
  });
};

export const saveGraphQLAPI = data => {
  return axiosObj({
    method: 'POST',
    url: '/c/v2/graphql-query/',
    data,
  });
};

export const editGraphQLAPI = data => {
  return axiosObj({
    method: 'PATCH',
    url: `/c/v2/graphql-query/${data.id}/`,
    data,
  });
};

export const deleteGraphQLAPI = id => {
  return axiosObj({
    method: 'DELETE',
    url: `/c/v2/graphql-query/${id}/`,
  });
};

export const saveFavoriteQueryGraphQLAPI = (data, id) => {
  return axiosObj({
    method: 'POST',
    url: `/c/v2/graphql-query/${id}/set_favorite/`,
    data,
  });
};

import { useEffect } from 'react';

export const useOnClickOutside = (ref, handler, customClassName) => {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      const targetClass = event?.target?.classList?.value || '';
      const flag = event?.currentTarget?.body?.className === customClassName;

      if (!ref.current || ref.current.contains(event.target) || flag || targetClass.includes('select__option')) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, customClassName]);
};

import React, { Component } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import isString from 'lodash/isString';
import { DownCaretIcon } from 'assets/images/common';
import { SearchIcon } from 'assets/images';
import NoSearchImg from 'assets/images/news-feed-icons/no-search.svg';
import { DropdownItem } from './SimpleDropdown';

class SearchSelectDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      headerTitle: props.title,
      icon: props.icon,
      search: '',
      data: [...props.list] || [],
    };
    this.close = this.close.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { listOpen } = this.state;

    if (!isEqual(this.props.list, prevProps.list)) {
      this.setState({ data: [...this.props.list] });
    }

    if (this.props.title !== prevProps.title) {
      this.setState({ headerTitle: this.props.title });
    }

    setTimeout(() => {
      if (listOpen) {
        window.addEventListener('click', this.close);
      } else {
        window.removeEventListener('click', this.close);
      }
    }, 0);
  }

  close(e) {
    if (!isString(e?.target?.className) || (e?.target?.className || '').includes('np-input')) {
      return;
    }

    this.setState({
      listOpen: false,
    });
  }

  toggleList = () => {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen,
    }));
  };

  handleFilterSearch = e => {
    const list = [...this.props.list];
    const value = e.target.value.trim();

    if (!value) {
      this.setState({ data: list, search: e.target.value });
    } else {
      const data = filter(list, item => (item?.name || '').toLowerCase().includes(value.toLowerCase()));
      this.setState({ data, search: e.target.value });
    }
  };

  getDropdownContent = () => {
    const {
      orientation = 'right',
      contentClassName = '',
      searchPlaceholder = 'Search for anything...',
      selectedItem = { name: '', value: '' },
      onItemClick,
    } = this.props;
    const { listOpen, data, search } = this.state;
    const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));

    if (!listOpen) {
      return null;
    }

    return (
      <div className={`np-dropdownContent open sort ${orientation} ${contentClassName}`}>
        <div className="dropdown-item np-input-icon">
          <input
            id="filterSearch"
            type="text"
            placeholder={searchPlaceholder}
            className="np-input search-input-filter"
            autoComplete="off"
            onChange={this.handleFilterSearch}
            value={search}
          />
          <SearchIcon />
        </div>
        <div className="np-dropdownContent-items">
          {!sortedData.length ? (
            <div className="no-dataMessage">
              <img src={NoSearchImg} alt="" />
              <p>No results found</p>
            </div>
          ) : null}
          {sortedData.map(item => (
            <DropdownItem
              id={item.value}
              key={item.value}
              val={item.value}
              selectedVal={selectedItem?.value}
              name={item.name}
              icon={item?.icon}
              onClick={onItemClick}
              toggle={this.toggleList}
            />
          ))}
        </div>
      </div>
    );
  };

  getSelectContent = () => {
    const {
      color = 'blue',
      textClassName = '',
      btnClassName = '',
      iconClassName = '',
      type = null,
      disabled = false,
    } = this.props;
    const { headerTitle, icon } = this.state;

    if (type === 'text') {
      return (
        <div className="np-dropdownClick">
          <h1 id={headerTitle} className={textClassName} onClick={this.toggleList}>
            {icon && <i className={iconClassName}>{icon}</i>}
            {headerTitle}
            <i className="np-down-caret-icon">
              <DownCaretIcon />
            </i>
          </h1>
          {this.getDropdownContent()}
        </div>
      );
    }

    return (
      <ul>
        <li>
          <div className="np-dropdownClick">
            <button
              id={headerTitle}
              className={classNames(`np-button light ${color} ${btnClassName}`, {
                disabled: disabled,
              })}
              onClick={this.toggleList}
              disabled={disabled}
            >
              {icon}
              {headerTitle}
              <DownCaretIcon />
            </button>
            {this.getDropdownContent()}
          </div>
        </li>
      </ul>
    );
  };

  render() {
    const { className } = this.props;

    return <div className={className}>{this.getSelectContent()}</div>;
  }
}

export default SearchSelectDropDown;

import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import Tippy from '@tippyjs/react';
import { InfoIcon } from '../../icons';
import { DropDownArrowIcon } from 'assets/images/nOpsRule';

import LoadingWrapper from 'components/ContentLoader/LoadingWrapper';
import { RowLoader } from 'components/ContentLoader/TableLoader';
import { LineChart } from 'components/eCharts/LineChart';
import { MultiLineChart } from 'components/eCharts/LineChart/MultiLineChart';
import { LargeRuleIcon } from 'assets/images/nOpsRule';
import { api_condition_success, api_condition_error } from 'containers/App/saga';
import { getErrorMessage, getTypeName } from 'utils/helper';
import {
  getRecommendationGraph,
  getInstanceRecommendations,
  getRDSRecommendationGraph,
  getRDSInstanceRecommendations,
} from 'api/search';
import { WEEK_LABEL } from 'components/Schedules/constants';

const WEEKDAY_VALUE = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6,
};

export const buildGraphData = (data, service) => {
  if (!data || isEmpty(data)) {
    return {};
  }

  if (service === 'rds') {
    delete data?.max_database_connections;

    return Object.keys(data).reduce((acc, item) => {
      const cpu_utilization = [],
        db_connections = [];

      for (let i = 0; i <= 23; i++) {
        cpu_utilization.push(data?.[item]?.[i]?.cpu_utilization.toFixed(2) || null);
        db_connections.push(data?.[item]?.[i]?.db_connections.toFixed(2) || null);
      }

      acc[item] = { cpu_utilization, db_connections };

      return acc;
    }, {});
  }

  return Object.keys(data).reduce((acc, item) => {
    const values = [];

    for (let i = 0; i <= 23; i++) {
      values.push(data?.[item]?.[i]?.toFixed(2) || null);
    }

    acc[item] = values;

    return acc;
  }, {});
};

export const RecommendationChart = props => {
  const [loader, setLoader] = useState(false);
  const [selectedWeekday, setSelectedWeekday] = useState('');
  const [weekdays, setWeekdays] = useState([]);
  const [graphData, setGraphData] = useState({});
  const [instanceRecommendations, setInstanceRecommendations] = useState({}); //eslint-disable-line
  const resource_type = props?.recommendation?.resource_type;
  const resource_arn = props?.data?._source?.arn;

  const fetchRecommendationGraph = async () => {
    let responses = [];

    if (resource_type === 'ec2') {
      responses = await Promise.all([
        getRecommendationGraph({
          instance_id: props.recommendation.id,
          weekday: selectedWeekday,
        }),
        getInstanceRecommendations({
          instance_id: props.recommendation.id,
          recommendation_type: resource_type,
        }),
      ]);
    } else {
      const instance_id = resource_type === 'rds_cluster' ? resource_arn : props.recommendation.id;
      responses = await Promise.all([
        getRDSRecommendationGraph({
          instance_id,
          weekday: selectedWeekday,
          recommendation_type: 'rds',
        }),
        getRDSInstanceRecommendations({ instance_id }),
      ]);
    }

    if (api_condition_success(responses[0].status) && api_condition_success(responses[1].status)) {
      const tempGraphData = buildGraphData(responses[0].data, props?.recommendation?.resource_type);
      setGraphData(tempGraphData);
      setInstanceRecommendations(responses[1].data);
    }

    if (api_condition_error(responses[0].status)) {
      props.showToastAction({
        type: 'error',
        message: getErrorMessage(responses[0]),
        show: true,
      });
    }

    if (api_condition_error(responses[1].status)) {
      props.showToastAction({
        type: 'error',
        message: getErrorMessage(responses[1]),
        show: true,
      });
    }

    setLoader(false);
  };

  useEffect(() => {
    if (selectedWeekday === '') {
      return;
    }

    if (resource_type === 'rds_cluster') {
      return;
    }

    setLoader(true);
    fetchRecommendationGraph();
  }, [selectedWeekday]); //eslint-disable-line

  useEffect(() => {
    if (selectedWeekday === '') {
      return;
    }

    if (resource_type !== 'rds_cluster') {
      return;
    }

    if (!resource_arn) {
      return;
    }

    setLoader(true);
    fetchRecommendationGraph();
  }, [selectedWeekday, resource_arn]); //eslint-disable-line

  useEffect(() => {
    const tempWeekdays = (Object.values(WEEK_LABEL) || []).map(day => ({
      value: WEEKDAY_VALUE[day],
      label: `${day}s`,
    }));
    setWeekdays(tempWeekdays);
    setSelectedWeekday(tempWeekdays[0].value);
  }, []); //eslint-disable-line

  const handleChangeType = e => {
    setSelectedWeekday(e.target.value);
  };

  const config = (stop_start, stop_end) => {
    return `${moment(stop_start, 'h').format('h A') || ''} - ${moment(stop_end, 'h').format('h A') || ''}`;
  };

  const local_config = (stop_start, stop_end) => {
    return `${moment.utc(stop_start, 'h').local().format('h A') || ''} - ${
      moment.utc(stop_end, 'h').local().format('h A') || ''
    }`;
  };

  const renderPatterns = useCallback(() => {
    if (isEmpty(instanceRecommendations)) {
      return null;
    }

    if (selectedWeekday === '') {
      return null;
    }

    const recomm = instanceRecommendations[selectedWeekday] || [];

    if (!recomm.length) {
      return null;
    }

    return (
      <div className="np-note np-note-recommendation green">
        <i className="note-icon green">
          <LargeRuleIcon />
        </i>
        <p>
          Usage Patterns indicate that this resource is NOT used between{' '}
          {recomm.map((rec, index) => (
            <span key={index}>
              {index > 0 ? ', ' : ''}
              <Tippy
                content={`${local_config(rec.stop_start_hour, rec.stop_end_hour)} in your local time`}
                placement="right"
                delay={300}
              >
                <b style={{ whiteSpace: 'nowrap' }}>{config(rec.stop_start_hour, rec.stop_end_hour)}</b>
              </Tippy>
              {resource_type === 'ec2' ? ` (${rec.confidence * 100}% confidence)` : null}
            </span>
          ))}
        </p>
      </div>
    );
  }, [instanceRecommendations]); //eslint-disable-line

  if (isEmpty(graphData) && !loader) {
    return null;
  }

  return (
    <div className="np-table2">
      <div className="title">
        <div className="np-titleIcon blue">
          <InfoIcon />
        </div>
        {`${getTypeName(resource_type)} ${
          !isEmpty(instanceRecommendations) ? 'Recommendations by' : ''
        } ${resource_type !== 'ec2' ? 'Database Connections and' : ''} CPU
        Utilization `}
      </div>
      <div className="np-table2Actions">
        <div className="np-pageHead-actions">
          <ul className="np-tableTabs">
            <li>
              <div className="np-select-group">
                <select value={selectedWeekday} className="np-input" onChange={handleChangeType}>
                  {weekdays.map(type => (
                    <option value={type.value} key={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>

                <div className="np-caret">
                  <DropDownArrowIcon />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <LoadingWrapper isLoading={loader} customLoader={RowLoader} customLoadingClassName="np-resource-details-loading">
        <div className="np-row">
          <div className="npxs-12">
            {renderPatterns()}
            <div className="recommendation-info-graph">
              {resource_type === 'ec2'
                ? Object.keys(graphData).map(graph => (
                    <div className="recommendation-info-graph-item" key={graph}>
                      <LineChart
                        recommendations={instanceRecommendations?.[selectedWeekday] || []}
                        xAxisLabel={graph}
                        xAxisData={[...Array(24).keys()].map(item => moment(item, 'h').format('h A'))}
                        yAxisLabel="CPU Utilization"
                        yAxisData={graphData[graph]}
                      />
                    </div>
                  ))
                : Object.keys(graphData).map(graph => (
                    <div className="recommendation-info-graph-item" key={graph}>
                      <MultiLineChart
                        legend={['CPU Utilization', 'Database Connections']}
                        recommendations={instanceRecommendations?.[selectedWeekday] || []}
                        xAxisLabel={graph}
                        xAxisData={[...Array(24).keys()].map(item => moment(item, 'h').format('h A'))}
                        yAxisLeft="Database Connections"
                        yAxisRight="CPU Utilization"
                        yAxisDataLeft={graphData?.[graph]?.db_connections || []}
                        yAxisDataRight={graphData?.[graph]?.cpu_utilization || []}
                        yAxisRightMax={100}
                        yAxisLeftMax={value => (value.max <= 10 ? Math.floor(value.max * 1.5 + 1) : value.max)}
                      />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </LoadingWrapper>
    </div>
  );
};

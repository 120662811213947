import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { getProjectName } from 'utils/helper';
import { RULE_DETAIL_PATHS, rules_pillars_array } from 'containers/nOpsRules/constants';

const collapse = {
  Security: [],
  Reliability: [],
  Cost: [],
  Performance: [],
  Operations: [],
};

const pillar = (rules_pillars_array || []).map(rule => ({
  key: rule,
  value: rule,
  data: [],
}));

export const CMDataInitialize = (
  allProjects = [],
  violationChanges,
  ruleMapping,
  projects,
  projectList = [],
  ruleList = [],
) => {
  const pillar_clone = cloneDeep(pillar);
  const collapse_clone = cloneDeep(collapse);
  const violationChanges_clone = cloneDeep(violationChanges);

  violationChanges_clone.forEach(bucketData => {
    const stringDate = bucketData.key_as_string;
    const changeDataDate = moment(stringDate, 'YYYY-MM-DDTHH:mm:ssZ').format('ddd, MMM DD, YYYY');

    const ruleData = {
      Security: [],
      Cost: [],
      Operations: [],
      Performance: [],
      Reliability: [],
    };
    const buckets = bucketData.violations_changes_type.buckets;
    buckets.forEach(bucket => {
      const newProjectList = [],
        nameList = [];
      const detail = pick(ruleMapping, bucket.key)[bucket.key];

      if (isEmpty(detail)) {
        return null;
      }

      const allData = bucket.violations_documents.violations_change_data.hits.hits || [];
      let newAllData = [];

      if (allProjects?.length) {
        allProjects.forEach(project => {
          const data = allData.filter(data => data._index.includes(`project_${project.id}`));

          if (!data.length) {
            return null;
          }

          newAllData = [...newAllData, ...data];
        });
      } else {
        newAllData = [...allData];
      }

      detail.service.forEach(service => {
        if (ruleList.length) {
          const rec = ruleList.find(a => a.rule === bucket.key && a.service === service);

          if (!rec) {
            return;
          }
        }

        const filterData = (newAllData || []).filter(violation => violation._source.type === service);

        if (!filterData.length) {
          return;
        }

        filterData.forEach(data => {
          const projectIndex = data._index;
          const project = getProjectName(projectIndex, projects);

          if (projectList?.length && !projectList.includes(+project.id)) {
            return {};
          }

          const aProject = allProjects.find(aProject => +aProject.id === +project.id) || {};

          if (aProject.name || project.id) {
            newProjectList.push(aProject.name || project.name || project.id);
          }

          const common = {
            id: data._id,
            projectId: project.id,
            accessKey: data._source.user_id || '',
          };

          if (data._source.name) {
            nameList.push({
              ...common,
              name: data._source.name,
            });
          } else if (data._source.user_name) {
            nameList.push({ ...common, name: data._source.user_name });
          } else {
            nameList.push({ ...common, name: data._source.item_id });
          }
        });

        if (!nameList?.length || !newAllData.length) {
          return;
        }

        const obj = {
          uniqData: nameList[0],
          count: filterData.length,
          project: uniq(newProjectList).join(', '),
          key: bucket.key,
          link: RULE_DETAIL_PATHS + '?' + detail[service].path,
          service,
          cloudPlatform: bucketData.cloudPlatform,
        };

        if (!detail?.pillars_name) {
          ruleData[detail.pillar].push(obj);
        } else {
          detail.pillars_name.forEach(pillarName => {
            ruleData[pillarName].push(obj);
          });
        }
      });
    });
    pillar_clone.forEach((pillarData, index) => {
      if (!ruleData[pillarData.key].length) {
        return;
      }

      pillar_clone[index].data.push({
        key: changeDataDate,
        data: ruleData[pillarData.key],
        date: stringDate,
      });
      pillar_clone[index].data = orderBy(pillar_clone[index].data, ['date'], 'desc');
      pillar_clone[index].data.forEach((x, index) => {
        collapse_clone[pillarData.key][index] = x.date;
      });
    });
  });

  return { pillar: pillar_clone, collapse: collapse_clone };
};
